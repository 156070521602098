import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useTranslation } from 'react-i18next';
import {
  OpeningHours,
  ProfessionalPhotoCreationDto,
  ProfessionalSocialMediaDto,
  SupportedCountriesIso3166,
} from '@docbay/schemas';
import { isValidPhoneNumber } from 'libphonenumber-js';

import { Gender } from 'applicaiton/constants/gender';

import { getSchedule } from 'common/helpers/getSchedule';
import { socialMediaValidation } from 'common/helpers/socialMediaValidation';

declare module 'yup' {
  interface StringSchema {
    uniqueSocialMedia(type: string, error: string): StringSchema;
  }
}

yup.addMethod(yup.string, 'uniqueSocialMedia', function (type, errorMessage) {
  return this.test('uniqueSocialMedia', errorMessage, (value, context) => {
    // @ts-ignore
    const copyValue = context.from[1].value[type].filter(
      // @ts-ignore
      (item) => {
        return item.url === value;
      },
    );
    return copyValue.length <= 1;
  });
});

export interface PrivateDoctorData {
  firstName: string;
  lastName: string;
  jobPosition: string;
  countryOfPracticeIso: SupportedCountriesIso3166;
  professionalData: {
    description: string;
    spokenLanguageIds: string[];
    phone: string;
    // education: string;
    dateOfBirth: string;
    gender: Gender;
    medicalNumber: string;
    photos: ProfessionalPhotoCreationDto[];
    socialMedia: ProfessionalSocialMediaDto[];
    clinicsRelation: {
      specializationsToAdd: string[];
      specializationsToDelete: string[];
      schedule: {
        hours: OpeningHours[];
      };
    };
  };
}

export const usePrivateDoctorForm = () => {
  const { t } = useTranslation();

  const defaultValues = {
    firstName: '',
    lastName: '',
    jobPosition: '',
    professionalData: {
      description: '',
      spokenLanguageIds: [],
      phone: '',
      // education: '',
      dateOfBirth: '',
      gender: Gender.Male,
      medicalNumber: '',
      photos: [],
      clinicsRelation: {
        specializationsToAdd: [''],
        specializationsToDelete: [],
        schedule: {
          hours: getSchedule() as OpeningHours[],
        },
      },
    },
  };

  const requiredError = t('errors.required');
  const phoneError = t('errors.phone_invalid');
  const uniqError = t('errors.uniq_error');
  const urlFiledError = t('errors.must_be_url');

  const clinicsRelationScheme = yup.object().shape({
    specializationsToAdd: yup.array().of(yup.string()),
    specializationsToDelete: yup.array().of(yup.string()),
    schedule: yup.object().shape({
      hours: yup.array().of(
        yup.object().shape({
          day: yup.string(),
          slots: yup.array().of(
            yup.object().shape({
              startTime: yup.string(),
              endTime: yup.string(),
            }),
          ),
          isEnabled: yup.boolean(),
        }),
      ),
    }),
  });

  const privateDoctorSchema = yup.object({
    firstName: yup.string().required(requiredError),
    lastName: yup.string().required(requiredError),
    jobPosition: yup.string(),
    countryOfPracticeIso: yup.string(),
    professionalData: yup.object().shape({
      description: yup.string(),
      spokenLanguageIds: yup.array().of(yup.string()),
      phone: yup.string().test({
        name: 'is-valid',
        test(value, ctx) {
          if (!value) return true;
          const phoneNumber = value?.startsWith('+')
            ? value
            : `+${String(value)}`;
          const isValidNumber = isValidPhoneNumber(phoneNumber);
          if (!isValidNumber) return ctx.createError({ message: phoneError });
          return isValidNumber;
        },
      }),
      // education: yup.string(),
      dateOfBirth: yup.string(),
      gender: yup.string(),
      medicalNumber: yup.string(),
      photos: yup.array().of(
        yup.object().shape({
          isMain: yup.boolean(),
          key: yup.string(),
          photoUrl: yup.string(),
          thumbnailUrl: yup.string(),
        }),
      ),
      socialMedia: yup.array().of(
        yup.object().shape({
          name: yup.string(),
          url: yup
            .string()
            .test('matchSocialMedia', urlFiledError, function (value, context) {
              if (!value) return true;
              const isValid = socialMediaValidation(
                String(value),
                context.parent.name,
              );
              return isValid;
            })
            .uniqueSocialMedia('socialMedia', uniqError),
          id: yup.string(),
        }),
      ),
      clinicsRelation: clinicsRelationScheme,
    }),
  });

  const {
    watch,
    getValues,
    setValue,
    register,
    handleSubmit,
    clearErrors,
    trigger,
    setError,
    formState: { errors },
  } = useForm<PrivateDoctorData>({
    resolver: yupResolver(privateDoctorSchema),
    defaultValues,
  });

  return {
    watch,
    getValues,
    setValue,
    errors,
    register,
    handleSubmit,
    clearErrors,
    trigger,
    setError,
  };
};
