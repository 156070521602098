import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AccordionDetails, AccordionSummary } from '@mui/material';
import dayjs from 'dayjs';
import { ReactComponent as ExpandMoreIcon } from 'applicaiton/assets/expand-more.svg';
import { ReactComponent as CloseIcon } from 'applicaiton/assets/close.svg';
import { Checkbox, RadioButton } from 'common/components';
import { AccordionSectionProps, AccordionState } from './modules';
import { useTimeView } from '../../hooks/useTimeView';
import {
  AccordionStyled,
  RadioButtonStyled,
  Wrapper,
  SpecializationsSection,
  PillsList,
} from './styles';
import { CheckboxList, CheckboxStyled } from '../FilterByAvailability/styles';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import AccordionDatePicker from '../AccordionDatePicker';
import { setAgendaFiltersSessionStorage } from 'applicaiton/sessionStorage/scheduler';
import { getSpecializationsByClinic } from 'applicaiton/store/reducers/Clinics/ActionCreators';
import { useLocalizeKey } from 'common/hooks/useLocalizeKey';

const AccordionSection: FC<AccordionSectionProps> = ({
  selectedDays,
  setSelectedDays,
  timeView,
  setTimeView,
  handleSelectSpecializations,
  selectedSpecializations,
  showNearest,
  setShowNearest,
  clinicId,
}) => {
  const { t, i18n } = useTranslation();
  const timeViewList = useTimeView();
  const dispatch = useAppDispatch();
  const { localizeNameKey } = useLocalizeKey();
  const { specializations } = useAppSelector((state) => state.clinics);
  const [accordionsState, setAccordionsState] = useState({
    specialization: false,
    dayPicker: false,
    timeView: false,
  });

  useEffect(() => {
    if (clinicId) {
      dispatch(getSpecializationsByClinic(clinicId));
    }
  }, [clinicId]);

  const checkSpecializationSelected = (value: string) => {
    return selectedSpecializations.some((item) => item === value);
  };

  const specializationsSelected = useMemo(() => {
    return specializations.filter((item) => {
      const findId = selectedSpecializations.find((id) => id === item.id);
      return !!findId;
    });
  }, [selectedSpecializations]);

  const selectedTimeView = useMemo(() => {
    const currentTimeView = timeViewList.find(
      (item) => item.value === timeView,
    )?.label;
    return currentTimeView?.replace('-', '');
  }, [timeView, i18n.language]);

  const handleOpenAccordion = useCallback(
    (id: AccordionState) => {
      if (!id) return;
      setAccordionsState({ ...accordionsState, [id]: !accordionsState[id] });
    },
    [accordionsState],
  );

  const handleSetNearest = useCallback(
    (checked: boolean) => {
      if (checked) {
        const startDate = dayjs();
        const currentDayOfMonth = dayjs().get('date');
        const endDate = dayjs().set('date', currentDayOfMonth + 6);
        setSelectedDays([startDate, endDate]);
      } else {
        setSelectedDays([]);
      }
      setShowNearest(checked);
      setAgendaFiltersSessionStorage({ showNearest: checked });
    },
    [showNearest],
  );

  const handleSetTimeView = (value: string) => {
    setTimeView(value);
    setAgendaFiltersSessionStorage({ timeView: value });
  };

  useEffect(() => {
    setAgendaFiltersSessionStorage({
      selectedDays,
    });
  }, [selectedDays]);

  return (
    <Wrapper>
      <AccordionStyled
        id={'specialization'}
        onClick={(e) =>
          handleOpenAccordion(
            ((e.target as Element).id as AccordionState) || '',
          )
        }
        expanded={accordionsState.specialization}
      >
        <AccordionSummary aria-controls="panel1a-content" id={'specialization'}>
          <div id={'specialization'}>
            <h2 id={'specialization'}>{t('scheduler.specialization')}</h2>{' '}
            <ExpandMoreIcon id={'specialization'} />
          </div>
          {selectedSpecializations.length ? (
            <PillsList className="pillsList">
              {specializationsSelected.map((item, index) => {
                if (index < 3) {
                  return (
                    <div key={`pillsList ${item.id}`} id={item.id}>
                      {item[localizeNameKey]}
                      <CloseIcon
                        onClick={() =>
                          handleSelectSpecializations(false, item.id)
                        }
                      />
                    </div>
                  );
                }
              })}
              {specializationsSelected.length > 3 && (
                <div>+ {specializationsSelected.length - 3}</div>
              )}
            </PillsList>
          ) : (
            ''
          )}
        </AccordionSummary>
        <AccordionDetails>
          <SpecializationsSection>
            <CheckboxList>
              {specializations?.map((item) => (
                <CheckboxStyled
                  key={`specialization ${item.id}`}
                  isActive={checkSpecializationSelected(item.id)}
                >
                  <Checkbox
                    id={`specialization ${item.id}`}
                    checked={checkSpecializationSelected(item.id)}
                    onChange={(checked) =>
                      handleSelectSpecializations(checked, item.id)
                    }
                    label={item[localizeNameKey]}
                  />
                </CheckboxStyled>
              ))}
            </CheckboxList>
          </SpecializationsSection>
        </AccordionDetails>
      </AccordionStyled>
      <AccordionDatePicker
        selectedDays={selectedDays}
        setSelectedDays={setSelectedDays}
        isExpanded={accordionsState.dayPicker}
        handleOpenAccordion={handleOpenAccordion}
        showNearest={showNearest}
        handleSetNearest={handleSetNearest}
      />
      <AccordionStyled
        id="timeView"
        onClick={(e) =>
          handleOpenAccordion(
            ((e.target as Element).id as AccordionState) || '',
          )
        }
        expanded={accordionsState.timeView}
      >
        <AccordionSummary aria-controls="panel1a-content" id="timeView">
          <div id="timeView">
            <h2 id="timeView">{t('scheduler.time_with_free_slots')}</h2>
            <ExpandMoreIcon id="timeView" />
          </div>
          {timeView ? (
            <PillsList className="pillsList">
              <div>{selectedTimeView}</div>
            </PillsList>
          ) : (
            ''
          )}
        </AccordionSummary>
        <AccordionDetails>
          <section>
            {timeViewList.map((item) => (
              <RadioButtonStyled
                key={item.value}
                isActive={timeView === item.value}
              >
                <RadioButton
                  id={item.value}
                  checked={timeView === item.value}
                  onChange={() => handleSetTimeView(item.value)}
                  label={item.label}
                  subLabel={item.subLabel}
                />
              </RadioButtonStyled>
            ))}
          </section>
        </AccordionDetails>
      </AccordionStyled>
    </Wrapper>
  );
};

export default AccordionSection;
