import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as AddIcon } from 'applicaiton/assets/add.svg';

import { Loader, PrimaryButton } from 'common/components';

import { PatientsTable, Header, InfoSection } from './components';
import AddPatientModal from './components/AddPatientModal';
import SuccessModal from './components/SuccessModal';

import { LoaderContainer, UsersListStyled, Container } from './styles';
import { useAppSelector } from 'common/hooks/redux';

const MyPatients: FC = () => {
  const { t } = useTranslation();
  const { isLoading, total } = useAppSelector((state) => state.patients);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const handleOpenModal = () => {
    setShowAddModal(true);
  };

  const handleCloseModal = () => {
    setShowAddModal(false);
  };

  const handleOpenSuccessModal = () => {
    setShowSuccessModal(true);
  };

  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false);
  };

  return (
    <UsersListStyled>
      {isLoading && (
        <LoaderContainer open={isLoading}>
          <>
            <Loader />
          </>
        </LoaderContainer>
      )}
      <Header />
      <Container>
        <InfoSection patientAvailable={total} setCurrentPage={setCurrentPage} />
        <div>
          <PrimaryButton onClick={handleOpenModal}>
            <AddIcon />
            {t('my_patients.add_new_patient')}
          </PrimaryButton>
        </div>
      </Container>

      <PatientsTable
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        handleShowModal={handleOpenModal}
      />

      <AddPatientModal
        isOpen={showAddModal}
        onClose={handleCloseModal}
        handleOpenSuccessModal={handleOpenSuccessModal}
      />
      {showSuccessModal && (
        <SuccessModal
          open={showSuccessModal}
          onClose={handleCloseSuccessModal}
        />
      )}
    </UsersListStyled>
  );
};

export default MyPatients;
