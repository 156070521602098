import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PersonIcon } from 'applicaiton/assets/person.svg';
import { ReactComponent as PlusIcon } from 'applicaiton/assets/plus.svg';
import { setIsClinicOwnerProfile } from 'applicaiton/store/reducers/Professionals/CreateProfessionalProfileSlice';
import { SecondaryButton } from 'common/components';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { ProfileHeaderProps } from './modules';
import { Wrapper, Name, Image, ButtonWrapper } from './styles';
import {
  setActiveForm,
  setShowLimitSubscriptionsModal,
} from 'applicaiton/store/reducers/ClinicOwner/ClinicOwnerSlice';
import { ProfileForm } from 'applicaiton/constants/clincOwnerProfile';
import {
  checkUsersEmailExisting,
  usersRefreshToken,
} from 'applicaiton/store/reducers/Users/ActionCreators';
import RestoreModal from '../RestoreModal';
import { UserResponseFindByEmailDto } from '@docbay/schemas/dist/user/dto/user-response-find-by-email.dto';
import { restoreClinicOwnerProfessional } from 'applicaiton/store/reducers/Professionals/ActionCreators';
import { professionalProfileActions } from 'applicaiton/store/reducers/Professionals/ProfessionalProfileSlice';
import {
  getProfilesFromSessionStorage,
  Profile,
} from 'applicaiton/sessionStorage/auth';
import {
  deleteCOAccount,
  getSubscriptions,
} from 'applicaiton/store/reducers/ClinicOwner/ActionCreators';
import DeleteProfileModal from 'features/feature-clinic-owner-profile/components/DeleteProfileModal/index';
import { logout } from 'common/auth/authService';
import { usePushNotifications } from 'features/feature-chats/hooks/usePushNotifications';
import { useClinicOwner } from 'common/hooks/useClinicOwner';

const ProfileHeader: FC<ProfileHeaderProps> = ({ currentClinicOwner }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [showRestorePopup, setShowRestorePopup] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const { unregisterPushNotifications } = usePushNotifications();

  const clinicOwnerProfessionalProfileId = currentClinicOwner?.professional?.id;

  const { canAddNewUser } = useAppSelector((state) => state.clinicOwnerSlice);
  const { isPrivateDoctor } = useClinicOwner();

  useEffect(() => {
    dispatch(getSubscriptions());
  }, []);

  const buttonTitle = clinicOwnerProfessionalProfileId
    ? t('clinic_owner.edit_public_profile')
    : t('clinic_owner.add_public_profile');

  const navigateProfessionalProfile = async () => {
    if (!canAddNewUser && !clinicOwnerProfessionalProfileId) {
      await dispatch(setShowLimitSubscriptionsModal(true));
      return;
    }
    if (clinicOwnerProfessionalProfileId) {
      dispatch(setIsClinicOwnerProfile(true));
      dispatch(setActiveForm(ProfileForm.editProfessionalProfile));
    } else {
      const response = await dispatch(
        checkUsersEmailExisting(currentClinicOwner?.user?.email || ''),
      );
      const payload = response.payload as UserResponseFindByEmailDto;
      const isHasDeletedProfile = payload?.hasDeletedProfessional;

      if (isHasDeletedProfile) {
        setShowRestorePopup(true);
      } else {
        dispatch(setIsClinicOwnerProfile(true));
        dispatch(setActiveForm(ProfileForm.createProfessionalProfile));
      }
    }
  };

  const handleRestoreProfile = async () => {
    const response = await dispatch(
      restoreClinicOwnerProfessional({
        email: currentClinicOwner?.user?.email || '',
      }),
    );

    if (response.meta.requestStatus === 'fulfilled') {
      setShowRestorePopup(false);

      const restoredUser = response.payload;

      const profile: Profile[] | null = getProfilesFromSessionStorage();

      if (profile) {
        await dispatch(
          usersRefreshToken({
            refreshToken: profile[0].refreshToken,
            email: profile[0].email,
          }),
        );
      }

      await dispatch(
        professionalProfileActions.setEditedProfessional(restoredUser),
      );
      await dispatch(setIsClinicOwnerProfile(true));
      await dispatch(setActiveForm(ProfileForm.editProfessionalProfile));
    }
  };

  const handleDeleteOwner = () => {
    setShowDeletePopup(false);
    dispatch(deleteCOAccount(currentClinicOwner?.id!)).then((data) => {
      if (data.meta.requestStatus === 'fulfilled') {
        logout();
        unregisterPushNotifications();
      }
    });
  };

  return (
    <Wrapper>
      <div>
        <Image>
          {currentClinicOwner?.photo ? (
            <img
              src={currentClinicOwner?.photo?.thumbnailUrl}
              alt={currentClinicOwner?.photo?.thumbnailUrl}
            />
          ) : (
            <PersonIcon />
          )}
        </Image>
        <Name>
          {currentClinicOwner?.firstName} {currentClinicOwner?.lastName}
        </Name>
      </div>
      <ButtonWrapper>
        <SecondaryButton onClick={() => setShowDeletePopup(true)}>
          {t('delete')}
        </SecondaryButton>
        {!isPrivateDoctor && (
          <SecondaryButton
            styleType={'color'}
            onClick={navigateProfessionalProfile}
          >
            {!clinicOwnerProfessionalProfileId && <PlusIcon />}
            {buttonTitle}
          </SecondaryButton>
        )}
      </ButtonWrapper>
      {showRestorePopup && (
        <RestoreModal
          onClose={() => setShowRestorePopup(false)}
          onRestore={handleRestoreProfile}
        />
      )}
      {showDeletePopup && (
        <DeleteProfileModal
          onClose={() => setShowDeletePopup(false)}
          onSubmit={handleDeleteOwner}
        />
      )}
    </Wrapper>
  );
};

export default ProfileHeader;
