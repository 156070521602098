import React, { useMemo } from 'react';
import SelectedPatientInfo from '../SelectedPatientInfo';
import { ColorSquare, Content, InfoItem } from './styles';
import { ReactComponent as ClinicIcon } from 'applicaiton/assets/clinic.svg';
import { ReactComponent as ProfessionalIcon } from 'applicaiton/assets/professional.svg';
import { ReactComponent as CalendarIcon } from 'applicaiton/assets/calendar.svg';
import { ReactComponent as ClockIcon } from 'applicaiton/assets/clock.svg';
import { ReactComponent as CommentIcon } from 'applicaiton/assets/comment.svg';
import { useAppSelector } from 'common/hooks/redux';
import dayjs from 'dayjs';
import { TimeZone } from 'applicaiton/constants/timeZone';
import { useTranslation } from 'react-i18next';

const tz = TimeZone.Lisbon;

const EventInformation = () => {
  const { i18n } = useTranslation();
  const { currentAppointment } = useAppSelector(
    (state) => state.appointmentsSlice,
  );

  const formattedDate = useMemo(() => {
    if (!currentAppointment) return;
    return dayjs
      .tz(currentAppointment!.startDate, tz)
      .locale(i18n.language)
      .format('dddd, DD/MM/YYYY');
  }, [currentAppointment]);

  const getTimeString = (date?: Date | null) => {
    const dateInTz = dayjs(date).tz(tz);
    return dateInTz.format('HH:mm');
  };

  const timeFromTo = useMemo(() => {
    if (!currentAppointment) return;
    const timeFrom = getTimeString(currentAppointment!.startDate);
    const timeTo = getTimeString(currentAppointment!.endDate);
    return `${timeFrom} - ${timeTo}`;
  }, [currentAppointment]);

  if (!currentAppointment) return <></>;

  return (
    <Content>
      <SelectedPatientInfo />
      <InfoItem>
        <ClinicIcon />
        {currentAppointment?.clinic!.name}
      </InfoItem>
      <InfoItem>
        <ProfessionalIcon />
        {`${currentAppointment?.professional!.firstName} ${
          currentAppointment?.professional!.lastName
        }`}
      </InfoItem>
      <InfoItem>
        <ColorSquare
          colorCode={currentAppointment?.appointmentType!.colorCode}
        />
        {currentAppointment?.appointmentType!.title!}
      </InfoItem>
      <InfoItem>
        <CalendarIcon />
        {formattedDate}
      </InfoItem>
      <InfoItem>
        <ClockIcon />
        {timeFromTo}
      </InfoItem>
      {!!currentAppointment?.comment && (
        <InfoItem>
          <CommentIcon />
          {currentAppointment.comment}
        </InfoItem>
      )}
    </Content>
  );
};

export default EventInformation;
