import React, { useEffect, useMemo, useState } from 'react';
import { NoActiveChatWrapper, Wrapper } from './styles';
import { ActiveChatHeader } from '../ActiveChatHeader';
import { MessagesList } from '../MessagesList';
import { MessageInput } from '../MessageInput';
import { useConversation } from '../../hooks/useConversation';
import { getCurrentProfileCognitoUuid } from 'applicaiton/sessionStorage/auth';
import { MessageBoxIcon } from 'applicaiton/assets';
import { useTranslation } from 'react-i18next';
import { Message, Paginator } from '@twilio/conversations';
import { useAppSelector } from 'common/hooks/redux';
import { ChatsMode } from 'applicaiton/constants/chats';
import { useDynamicChats } from '../../hooks/useDynamicChats';
import { useDocumentDownload } from 'common/hooks/useDownloadDocument';

export const ActiveChat = () => {
  const currentProfileCognitoUuid = getCurrentProfileCognitoUuid();
  const { t } = useTranslation();
  const [messages, setMessages] = useState<Message[]>([]);
  const [messagesPaginator, setMessagesPaginator] = useState<
    Paginator<Message> | undefined
  >(undefined);
  const {
    initConversation,
    selectedChat,
    conversationIsReady,
    sendMessage,
    getMessages,
    addListener,
    removeListener,
    conversation,
    updateChat,
    handleDeleteDocumentMessage,
  } = useConversation();
  const { filteredDynamicChats } = useDynamicChats();
  const { mode } = useAppSelector((state) => state.chats);
  const downloadDocument = useDocumentDownload();

  useEffect(() => {
    if (selectedChat?.twilioConversationSID) {
      setMessages([]);
      initConversation(selectedChat.twilioConversationSID);
    }
  }, [selectedChat?.twilioConversationSID]);

  useEffect(() => {
    const attributes = conversation?.attributes as any;

    if (!attributes?.emergency) return;

    const updateData = {
      ...selectedChat,
      emergency: false,
    };

    updateChat(selectedChat?.twilioConversationSID!, updateData);
  }, [conversation, selectedChat]);

  const messageAddedListener = (message: Message) => {
    setMessages((prevMessages) => [...prevMessages, message]);
  };

  const messageRemovedListener = (message: Message) => {
    setMessages((prevMessages) =>
      prevMessages.filter((prevMessage) => prevMessage.sid !== message.sid),
    );
  };

  const messageUpdatedListener = ({ message }: { message: Message }) => {
    setMessages((prevState) => {
      return prevState.map((existingMessage) =>
        existingMessage.sid === message.sid ? message : existingMessage,
      );
    });
  };

  const fetchMessages = async () => {
    const fetchedMessages = await getMessages();
    setMessagesPaginator(fetchedMessages);
    if (fetchedMessages?.items) {
      setMessages(fetchedMessages.items);
    }
  };

  useEffect(() => {
    if (conversation) {
      setMessages([]);
      fetchMessages();
      addListener('messageAdded', messageAddedListener);
      addListener('messageRemoved', messageRemovedListener);
      addListener('messageUpdated', messageUpdatedListener);
    }
    return () => {
      removeListener('messageAdded', messageAddedListener);
      removeListener('messageRemoved', messageRemovedListener);
      removeListener('messageUpdated', messageUpdatedListener);
    };
  }, [conversation]);

  const handleSendMessage = (message: string) => {
    sendMessage(message);
  };

  const topic =
    selectedChat?.clinicProfessionalTopic?.name || t('chats.no_topic') || '';
  const isArchivedMode = mode === ChatsMode.ArchivedChats;

  const messagesToRender = useMemo(() => {
    const filteredMessages = messages
      .filter((item) => {
        const attributes = item.attributes as any;
        return !attributes.teleconsultationRequestApproved;
      })
      .sort((a, b) => (a.dateCreated! > b.dateCreated! ? -1 : 1));
    return filteredMessages.map((item) => {
      const attributes = item.attributes as any;
      return {
        index: item.index,
        id: item.sid,
        isOwnMessage: item.author === currentProfileCognitoUuid,
        text: item.body || '',
        date: item.dateUpdated || item.dateCreated || new Date(),
        teleconsultation: attributes?.teleconsultation || false,
        emergency: attributes.emergency || false,
        teleconsultationRequest: attributes?.teleconsultationRequest || false,
        teleconsultationObject: attributes?.teleconsultationObject,
        ...(attributes.document && { document: attributes.documentObject }),
        ...(attributes.topicClosed && { topicClosed: attributes.topicClosed }),
      };
    });
  }, [messages]);

  const handleDeleteMessage = (messageId: string) => {
    const messageToDelete = messages.find(
      (message) => message.sid === messageId,
    );

    if (!messageToDelete) return;

    const attributes = messageToDelete.attributes as any;
    if (attributes?.document) {
      handleDeleteDocumentMessage(messageToDelete.sid);
    } else {
      messageToDelete.remove();
    }
  };

  const handleDownloadDocument = (messageId: string) => {
    downloadDocument(messageId);
  };

  return (
    <Wrapper>
      {selectedChat ? (
        <>
          <ActiveChatHeader />
          <MessagesList
            topic={topic}
            messages={messagesToRender}
            onDeleteMessage={handleDeleteMessage}
            onDownloadDocument={handleDownloadDocument}
            messagesPaginator={messagesPaginator}
            setMessages={setMessages}
            setMessagesPaginator={setMessagesPaginator}
          />
          <MessageInput
            onSendMessage={handleSendMessage}
            isDisabled={!conversationIsReady}
          />
        </>
      ) : (
        <NoActiveChatWrapper>
          <MessageBoxIcon />
          <p className={'text'}>
            {isArchivedMode
              ? filteredDynamicChats.length
                ? t('chats.no_chat_messages.archived_conversation')
                : t('chats.no_chat_messages.empty_archived_conversation')
              : t('chats.no_chat_messages.start_conversation')}
          </p>
        </NoActiveChatWrapper>
      )}
    </Wrapper>
  );
};
