import { useTranslation } from 'react-i18next';
import { PathNames } from 'applicaiton/routes/constants';
import {
  getUserPermissions,
  getUserRole,
} from 'applicaiton/sessionStorage/auth';
import { UserRoles } from 'applicaiton/constants/userRoles';
import { UserPermissions } from 'applicaiton/constants/userPermissions';
import { userHasPermission } from 'common/helpers/userHasPermission';
import { useClinicOwner } from 'common/hooks/useClinicOwner';
import { userRoleExactMatch } from 'common/helpers/userRoleExactMatch';

export const useSettingsList = () => {
  const { t } = useTranslation();
  const userRole = getUserRole();
  const isAdmin = userRoleExactMatch(UserRoles.admin);
  const isSecretary = userRole === UserRoles.secretary;
  const isClinicOwner = userRole?.includes(UserRoles.clinicOwner);
  const isProfessional = userRole?.includes(UserRoles.professional);
  const permissions = getUserPermissions();
  const { isPrivateDoctor } = useClinicOwner();
  const isShowAppointmentType = permissions?.includes(
    UserPermissions.clinicAppointmentTypesConfiguration,
  );
  const canUserManageAppointmentTypes = userHasPermission(
    UserPermissions.clinicAppointmentTypesConfiguration,
  );

  const list = [
    {
      path: PathNames.myClinics,
      name: t('clinicsConfiguration.aside_menu.my_clinics'),
    },
    {
      path: PathNames.myUsers,
      name: t('clinicsConfiguration.aside_menu.my_users'),
    },
    {
      path: PathNames.typesAppointments,
      name: t('clinicsConfiguration.aside_menu.types_appointments'),
    },
    {
      path: PathNames.internetAppointments,
      name: t('clinicsConfiguration.aside_menu.internet_appointments'),
    },
    {
      path: PathNames.patientAppointments,
      name: t('clinicsConfiguration.aside_menu.patient_appointments'),
    },
  ];

  if (isClinicOwner && isPrivateDoctor) {
    return [
      {
        path: PathNames.myClinics,
        name: t('clinicsConfiguration.aside_menu.my_clinics'),
      },
      {
        path: PathNames.typesAppointments,
        name: t('clinicsConfiguration.aside_menu.types_appointments'),
      },
      {
        path: PathNames.notificationsSettings,
        name: t('clinicsConfiguration.aside_menu.notifications_settings'),
      },
      {
        path: PathNames.clinicBilling,
        name: t('clinicsConfiguration.aside_menu.clinic_billing'),
      },
      {
        path: PathNames.myPatients,
        name: t('clinicsConfiguration.aside_menu.my_patients'),
      },
    ];
  }

  if (isClinicOwner) {
    return [
      {
        path: PathNames.myClinics,
        name: t('clinicsConfiguration.aside_menu.my_clinics'),
      },
      {
        path: PathNames.typesAppointments,
        name: t('clinicsConfiguration.aside_menu.types_appointments'),
      },
      {
        path: PathNames.userPermissions,
        name: t('clinicsConfiguration.aside_menu.user_permissions'),
      },
      {
        path: PathNames.notificationsSettings,
        name: t('clinicsConfiguration.aside_menu.notifications_settings'),
      },
      {
        path: PathNames.clinicBilling,
        name: t('clinicsConfiguration.aside_menu.clinic_billing'),
      },
      {
        path: PathNames.myPatients,
        name: t('clinicsConfiguration.aside_menu.my_patients'),
      },
    ];
  }

  if (isProfessional) {
    return [
      {
        path: PathNames.myClinics,
        name: t('clinicsConfiguration.aside_menu.my_clinics'),
      },
      ...(canUserManageAppointmentTypes
        ? [
            {
              path: PathNames.typesAppointments,
              name: t('clinicsConfiguration.aside_menu.types_appointments'),
            },
          ]
        : []),
    ];
  }

  if (isAdmin || isSecretary) {
    return [
      {
        path: PathNames.myClinics,
        name: t('clinicsConfiguration.aside_menu.my_clinics'),
      },
      ...(isShowAppointmentType
        ? [
            {
              path: PathNames.typesAppointments,
              name: t('clinicsConfiguration.aside_menu.types_appointments'),
            },
          ]
        : []),
    ];
  }

  return list;
};
