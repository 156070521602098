import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ButtonGroup from '../ButtonGroup';
import { Checkbox, PrimaryButton, SecondaryButton } from 'common/components';
import { StyledArticle } from './styles';
import {
  currentClinicOwnerRegistration,
  setClinicOwnerRegistrationSessionStorage,
} from 'applicaiton/sessionStorage/clinicRegistration';

interface ContractualAgreementProps {
  setStep: (value: number) => void;
}

const ContractualAgreement: FC<ContractualAgreementProps> = ({ setStep }) => {
  const { t } = useTranslation();
  const defaultState =
    !!currentClinicOwnerRegistration().contractualAgreement.isAgree;
  const [isTermsCheked, setIsTermsCheked] = useState(defaultState);

  const handleChangeTerms = () => {
    setIsTermsCheked((prev) => !prev);
    setClinicOwnerRegistrationSessionStorage('contractualAgreement', {
      isAgree: !isTermsCheked,
    });
  };

  return (
    <StyledArticle>
      <section>
        {/*this is temporary text, I will remove it*/}
        <article>
          <h2>
            {`Terms and Conditions for Payments by Direct Debit under the SEPA
            Core Direct Debit Scheme`}
          </h2>
          <h3>
            {`Payments which the customer makes to payees by SEPA core direct
            debit through their account with the Bank shall be subject to the
            following terms and conditions.`}
          </h3>
          <div>
            {`1 General 1.1 Definition A direct debit is a payment transaction
            initiated by the payee and debited to the customers account where
            the amount of the payment is specified by the payee. 1.2 Charges and
            changes therein`}
          </div>
          <div>
            {`1.2.1 Charges for consumers The charges for direct debits shall be
            set out in the “List of Prices and Services” (Preis- und
            Leistungsverzeichnis). Any changes in the charges for direct debits
            shall be offered to the customer in text form no later than two
            months before their proposed date of entry into force. If the
            customer has agreed an electronic communication channel with the
            Bank within the framework of the business relationship, the changes
            may also be offered through this channel. The customer may indicate
            either approval or disapproval of the changes before their proposed
            data of entry into force. The changes shall be deemed to have been
            approved by the customer, unless the customer has indicated
            disapproval before their proposed date of entry into force. The Bank
            shall expressly draw the customer's attention to this consequent
            approval in its offer. If the customer is offered changes in the
            charges, the customer may also terminate the business relationship
            free of charge with immediate effect before the proposed date of
            entry into force of the changes. The Bank shall expressly draw the
            customer's attention to this right of termination in its offer.
            Changes in charges for the payment services framework contract
            (current account agreement) shall be governed by No. 12, paragraph 5
            of the General Business Conditions (Allgemeine
            Geschäftsbedingungen).`}
          </div>
          <div>
            {`1.2.2 Charges for customers who are not consumers Charges. Charges
            for payments by customers and any changes in these shall continue to
            be governed by No. 12, paragraphs 2 to 6 of the General Business
            Conditions (Allgemeine Geschäftsbedingungen). Charges for payments
            by customers and any changes in these shall continue to be governed
            No. 12, paragraphs 2 to 6 of the General Business Conditions
            (Allgemeine Geschäftsbedingungen).`}
          </div>
          <div>
            {`1.2.2 Charges for customers who are not consumers Charges. Charges
            for payments by customers and any changes in these shall continue to
            be governed by No. 12, paragraphs 2 to 6 of the General Business
            Conditions (Allgemeine Geschäftsbedingungen). Charges for payments
            by customers and any changes in these shall continue to be governed
            No. 12, paragraphs 2 to 6 of the General Business Conditions
            (Allgemeine Geschäftsbedingungen).`}
          </div>
        </article>
      </section>
      <div>
        <Checkbox
          id={'TermsConditions'}
          checked={isTermsCheked}
          onChange={handleChangeTerms}
          label={t('clinicRegistration.agree_terms_conditions') || ''}
        />
        <ButtonGroup>
          <PrimaryButton
            onClick={() => {
              setStep(2);
            }}
            disabled={!isTermsCheked}
          >
            {t('clinicRegistration.continue')}
          </PrimaryButton>
          <SecondaryButton
            onClick={() => {
              setStep(0);
            }}
          >
            {t('clinicRegistration.back')}
          </SecondaryButton>
        </ButtonGroup>
      </div>
    </StyledArticle>
  );
};

export default ContractualAgreement;
