import React, { FC, useEffect } from 'react';
import { WatchStyled } from './styles';
import { useTimer } from 'features/feature-teleconsultation/hooks/useTimer';

const Watch: FC = () => {
  const { startTimer, seconds, resetTimer } = useTimer();

  const sec = seconds % 60;
  const min = Math.floor(seconds / 60);

  useEffect(() => {
    startTimer();

    return () => {
      resetTimer();
    };
  }, []);

  return (
    <WatchStyled className="timer">
      <h1>
        {String(min).length === 1 && 0}
        {min}:{String(sec).length === 1 && 0}
        {sec}
      </h1>
    </WatchStyled>
  );
};

export default Watch;
