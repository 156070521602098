import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import {
  AppointmentUpdateResponseDto,
  CommonEntitiesListResponse,
  PatientBriefCreationResponseDto,
  PatientResponseDto,
  ProfessionalResponseDto,
} from '@docbay/schemas';

import { setIsAddNewEvent } from 'applicaiton/store/reducers/Scheduler/SchedulerSlice';
import {
  setConfused,
  setShowConfusingAgendaErrorModal,
} from 'applicaiton/store/reducers/ConfusingAgenda/ConfusingAgendaSlice';
import {
  createFamilyMember,
  createPatient,
} from 'applicaiton/store/reducers/Patients/ActionCreators';
import {
  setCurrentFamilyMemberSearch,
  setCurrentPatient,
  setIsHiddenShowProfile,
} from 'applicaiton/store/reducers/Appointments/AppoinmentsSlice';
import {
  createAppointment,
  editAppointment,
  fetchAppointmentById,
} from 'applicaiton/store/reducers/Appointments/ActionCreators';
import { setCurrentAppointment } from 'applicaiton/store/reducers/Appointments/PatientAppoinmentsSlice';
import { fetchConfusingAgenda } from 'applicaiton/store/reducers/ConfusingAgenda/ActionCreators';
import { UserRoles } from 'applicaiton/constants/userRoles';
import { getUserId, getUserRole } from 'applicaiton/sessionStorage/auth';
import { PathNames } from 'applicaiton/routes';
import { ViewBy } from 'applicaiton/constants/scheduler';
import { ReserveSlotsMode } from 'applicaiton/constants/reserveSlots';
import {
  fetchProfessionalById,
  fetchProfessionals,
} from 'applicaiton/store/reducers/Professionals/ActionCreators';
import { UserPermissions } from 'applicaiton/constants/userPermissions';
import { setHasUnsavedChanges } from 'applicaiton/store/reducers/DetectChangesSaved/DetectChangesSavedSlice';

import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { AppointmentTypeOption } from 'common/components/DropdownAppointmentTypes/models';
import { Loader } from 'common/components';
import {
  addMinutes,
  getTimeWithRoundedMinutes,
} from 'common/helpers/dateTimeHelper';
import { dateWithoutTimezone } from 'common/helpers/dateWithoutTimezone';
import { userHasPermission } from 'common/helpers/userHasPermission';
import { useUserTimeZone } from 'common/hooks/useUserTimeZone';

import { useCreateAppointment } from 'features/feature-agenda-reserve-slots-modal/hooks/useCreateAppointment';
import { useCreatePatient } from 'features/feature-agenda-reserve-slots-modal/hooks/useCreatePatient';
import { useCreateFamilyMember } from 'features/feature-agenda-reserve-slots-modal/hooks/useCreateFamilyMember';
import {
  AppointmentClinicForm,
  PatientAlreadyExistModal,
  AppointmentActions,
  VerifyEditingAppointmentModal,
  ErrorConfusingModal,
  LeavePageModal,
} from 'features/feature-agenda-reserve-slots-modal/components';

import Patient from '../Patient';

import { AppointmentFormProps, ProfessionalOption } from './models';
import { FormStyled } from './styles';
import { AppointmentStatus } from 'common/types/appointmentStatus';

const AppointmentForm: FC<AppointmentFormProps> = ({
  setIsContinueSettings,
  onClose,
}) => {
  const blockSaveRef = useRef(false);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const userRole = getUserRole();
  const userId = getUserId();
  const { tz } = useUserTimeZone();
  const {
    isLoading: isLoadingAppointment,
    currentPatient,
    currentAppointment,
    appointmentId,
    startDate,
    professionalId,
    mode,
    waitingStartDate,
    currentFamilyMemberSearch,
  } = useAppSelector((state) => state.appointmentsSlice);
  const {
    isLoading: isLoadingConfusingAgenda,
    confusingAgendas,
    showConfusingAgendaErrorModal,
  } = useAppSelector((state) => state.confusingAgendasSlice);
  const { appointmentDocuments, isLoading: documentLoading } = useAppSelector(
    (state) => state.appointmentDocumentsSlice,
  );
  const {
    viewBy,
    byAvailability,
    byProfessionals,
    // professionals
  } = useAppSelector((state) => state.scheduler);
  const { isLoading: isLoadingProfessionals } = useAppSelector(
    (state) => state.professionals,
  );
  const { isLoading: isLoadingPatients } = useAppSelector(
    (state) => state.patients,
  );
  const { isLoading: isLoadingProfessional, editedProfessional } =
    useAppSelector((state) => state.professionalProfile);

  const [appointmentStartDate, setAppointmentStartDate] = useState<Date | null>(
    null,
  );
  const [appointmentEndDate, setAppointmentEndDate] = useState<Date | null>(
    null,
  );
  const [appointmentType, setAppointmentType] =
    useState<AppointmentTypeOption | null>(null);
  const [chosenProfessionals, setChosenProfessionals] =
    useState<ProfessionalOption | null>(null);
  const [currentClinicId, setCurrentClinicId] = useState<string>('');
  const [showPatientAlreadyExistModal, setShowPatientAlreadyExistModal] =
    useState(false);
  const [addToWaitingList, setAddToWaitingList] = useState(false);
  const [isCreateAppointmentForFM, setIsCreateAppointmentForFM] =
    useState(false);
  const [showCreatePatientForm, setShowCreatePatientForm] = useState(false);
  const [hideCreateFamilyMemberButton, setHideCreateFamilyMemberButton] =
    useState(false);
  const [showVerifyModal, setShowVerifyModal] = useState(false);
  const [clinicOwnerIdBySelectedClinic, setClinicOwnerIdBySelectedClinic] =
    useState<string | null>(null);
  const [showLeaveModal, setShowLeaveModal] = useState(false);

  const isLoading =
    isLoadingAppointment ||
    isLoadingConfusingAgenda ||
    isLoadingProfessionals ||
    isLoadingPatients ||
    isLoadingProfessional;

  const isUserRoleProfessional = userRole === UserRoles.professional;
  const isMyAgendaPage = location.pathname === PathNames.clinicOwnerSchedule;
  const isEditMode = mode === ReserveSlotsMode.EditAppointment;
  const isBookAgainMode = mode === ReserveSlotsMode.BookAgain;

  const canCreateAppointments = userHasPermission(
    UserPermissions.createAppointment,
    Number(clinicOwnerIdBySelectedClinic),
    isMyAgendaPage,
  );

  const currentViewData = useMemo(() => {
    return viewBy === ViewBy.byProfessionals ? byProfessionals : byAvailability;
  }, [viewBy, byAvailability, byProfessionals]);

  const {
    setValue,
    errors,
    isValid: isCreateAppointmentValid,
    handleSubmit,
    watch,
    clearErrors,
    trigger,
  } = useCreateAppointment();

  const {
    watchPatient,
    getValuesPatient,
    setValuePatient,
    errorsPatient,
    registerPatient,
    handleSubmitPatient,
    clearErrorsPatient,
    isDirtyPatient,
    resetPatient,
  } = useCreatePatient();

  const {
    watchFM,
    getValuesFM,
    setValueFM,
    errorsFM,
    registerFM,
    handleSubmitFM,
    clearErrorsFM,
    resetFm,
  } = useCreateFamilyMember();

  const hasConfusingAgendas = useMemo(() => {
    const confusingEvents = [
      ...(confusingAgendas?.absences || []),
      ...(confusingAgendas?.appointments?.filter(
        (appointment) => appointment.id !== currentAppointment?.id,
      ) || []),
    ];
    return !!confusingEvents.length;
  }, [confusingAgendas]);

  const currentSpecializationIds = useMemo(() => {
    const specializationIds = chosenProfessionals?.specializations
      ?.map((item) => item.id)
      .join();
    return specializationIds || '';
  }, [chosenProfessionals]);

  useEffect(() => {
    return () => {
      dispatch(setHasUnsavedChanges(false));
    };
  }, []);

  useEffect(() => {
    if (appointmentId) {
      dispatch(fetchAppointmentById(String(appointmentId)));
    }
  }, [appointmentId]);

  useEffect(() => {
    if ((isUserRoleProfessional || isMyAgendaPage) && professionalId) {
      dispatch(fetchProfessionalById(String(professionalId)));
    }
  }, [professionalId]);

  useEffect(() => {
    if (editedProfessional) {
      setChosenProfessionals({
        label: editedProfessional.firstName + ' ' + editedProfessional.lastName,
        value: editedProfessional.id,
        image: editedProfessional.mainPhoto?.thumbnailUrl,
        specializations: editedProfessional.specializations || [],
      });
    }
  }, [editedProfessional]);

  const getProfessionalsList = async (clinicIds?: string) => {
    const response = await dispatch(
      fetchProfessionals({
        ...(clinicIds ? { clinicIds } : {}),
        onlyActive: true,
      }),
    );
    if (response.meta.requestStatus === 'fulfilled') {
      const professionalsList =
        response.payload as CommonEntitiesListResponse<ProfessionalResponseDto>;

      const currentProfessional = professionalsList.entities.find(
        (professional) =>
          professional.id === currentAppointment?.professional?.id ||
          professional.id === professionalId,
      );
      if (currentProfessional) {
        setChosenProfessionals({
          label:
            currentProfessional.firstName + ' ' + currentProfessional.lastName,
          value: currentProfessional.id,
          image: currentProfessional.mainPhoto?.thumbnailUrl,
          specializations: currentProfessional.specializations || [],
        });
      }
    }
  };

  useEffect(() => {
    if (currentAppointment) {
      getProfessionalsList(currentAppointment?.clinic?.id ?? '');
      const appointmentTypeOption = {
        label: currentAppointment.appointmentType.title,
        value: currentAppointment.appointmentType.id,
        colorCode: currentAppointment.appointmentType.colorCode,
        duration: currentAppointment.appointmentType.durationInMinutes,
        specializationIds: currentSpecializationIds,
      };
      let startDateAppointment = isBookAgainMode
        ? (dayjs
            .tz(getTimeWithRoundedMinutes(dayjs().toDate()), tz)
            .format() as any)
        : currentAppointment.startDate;
      startDateAppointment = waitingStartDate ?? startDateAppointment;
      setAppointmentType(appointmentTypeOption);
      setValue('appointmentTypeId', currentAppointment.appointmentType.id);
      setValue('patientId', currentAppointment.patient?.id ?? '');
      setValue('status', currentAppointment.status ?? '');
      setValue('statusComment', currentAppointment.statusComment ?? '');
      setAppointmentStartDate(startDateAppointment);
      if (isBookAgainMode || waitingStartDate) {
        const calcEndDate = addMinutes(
          startDateAppointment,
          currentAppointment?.appointmentType?.durationInMinutes || 0,
        );
        setAppointmentEndDate(dayjs.tz(calcEndDate).format() as any);
      } else {
        setAppointmentEndDate(currentAppointment.endDate);
      }
      setCurrentClinicId(currentAppointment?.clinic?.id ?? '');
      currentAppointment.comment &&
        setValue('comment', currentAppointment.comment);
      setAddToWaitingList(!!currentAppointment?.addedToWaitingListDate);
    }
  }, [currentAppointment]);

  useEffect(() => {
    if (appointmentStartDate && chosenProfessionals) {
      if (currentClinicId || currentViewData?.clinicId) {
        dispatch(
          fetchConfusingAgenda({
            data: {
              startDate: dayjs(appointmentStartDate).toDate(),
              endDate: dayjs(appointmentEndDate).toDate(),
              professionalId: isUserRoleProfessional
                ? String(userId)
                : String(chosenProfessionals?.value)!,
              ...(isMyAgendaPage
                ? {}
                : { clinicId: currentClinicId || currentViewData?.clinicId }),
            },
            isOnSave: false,
          }),
        );
      }
    }
  }, [
    currentClinicId,
    appointmentStartDate,
    chosenProfessionals,
    currentViewData,
    appointmentEndDate,
  ]);

  useEffect(() => {
    if (currentViewData.clinicId && !isEditMode) {
      getProfessionalsList(!isMyAgendaPage ? currentViewData.clinicId : '');
      if (!isUserRoleProfessional && !isMyAgendaPage) {
        setCurrentClinicId(currentViewData.clinicId);
      }
    }
  }, [currentViewData]);

  const setDefaultAppointmentTime = () => {
    const isCurrentTime =
      dayjs().format('YYYY MM DD HH mm') ===
      dayjs(appointmentStartDate || startDate).format('YYYY MM DD HH mm');
    const currentTimeInTimezone = dayjs.tz(new Date(), tz);
    const currentDate = getTimeWithRoundedMinutes(
      dayjs().tz().set('hour', currentTimeInTimezone.get('hour')).toDate(),
    );
    const date = dayjs(currentDate).tz().isAfter(appointmentStartDate)
      ? currentDate
      : appointmentStartDate;

    setAppointmentStartDate(
      dayjs(date)
        .tz()
        .add(isCurrentTime ? 5 : 0, 'minute')
        .format() as any,
    );
  };

  useEffect(() => {
    if (startDate && !currentAppointment) {
      const startDateAppointment = getTimeWithRoundedMinutes(startDate);
      setAppointmentStartDate(
        dayjs.tz(dateWithoutTimezone(startDateAppointment), tz).format() as any,
      );
      setAppointmentEndDate(
        dayjs.tz(dateWithoutTimezone(startDateAppointment), tz).format() as any,
      );
    }
  }, [startDate]);

  useEffect(() => {
    if (waitingStartDate) {
      const startDateAppointment = getTimeWithRoundedMinutes(waitingStartDate);

      setAppointmentStartDate(
        dayjs.tz(dateWithoutTimezone(startDateAppointment), tz).format() as any,
      );
    }
  }, [waitingStartDate]);

  const saveAppointment = async (patientId?: string | null) => {
    const statusComment =
      watch('status') === AppointmentStatus.JUSTIFIED_ABSENCE
        ? watch('statusComment')
        : '';

    await dispatch(
      createAppointment({
        startDate: dayjs(appointmentStartDate).toDate(),
        endDate: dayjs(appointmentEndDate).toDate(),
        professionalId: String(chosenProfessionals?.value)!,
        appointmentTypeId: String(appointmentType?.value),
        status: watch('status'),
        patientId: patientId || String(currentPatient?.id)!,
        clinicId: currentClinicId,
        comment: watch('comment'),
        statusComment,
        ...(appointmentDocuments.length && { documents: appointmentDocuments }),
        addToWaitingList: addToWaitingList ? 'true' : 'false',
        ...(!currentFamilyMemberSearch?.isPatient
          ? { bookedForMemberId: currentFamilyMemberSearch?.id }
          : {}),
      }),
    ).then((data) => {
      if (data.meta.requestStatus === 'fulfilled') {
        dispatch(setCurrentPatient(null));
        dispatch(setCurrentFamilyMemberSearch(null));
        dispatch(setIsAddNewEvent(true));
        dispatch(setShowConfusingAgendaErrorModal(false));
        if (hasConfusingAgendas) {
          setIsContinueSettings(true);
        }
      }
    });
  };

  const onSave = async ({ patientId }: { patientId?: string | null }) => {
    if (!currentClinicId) return;
    const data = await dispatch(
      fetchConfusingAgenda({
        data: {
          startDate: dayjs(appointmentStartDate).toDate(),
          endDate: dayjs(appointmentEndDate).toDate(),
          professionalId: isUserRoleProfessional
            ? String(userId)
            : String(chosenProfessionals?.value)!,
          ...(isMyAgendaPage
            ? {}
            : { clinicId: currentClinicId || currentViewData?.clinicId }),
        },
        isOnSave: true,
      }),
    );
    const currentAppointment = data?.payload?.appointments?.length
      ? data.payload.appointments[0]
      : null;
    dispatch(setCurrentAppointment(currentAppointment));

    if (
      data.payload?.appointments?.length ===
      confusingAgendas?.appointments?.length
    ) {
      await saveAppointment(patientId);
    }
  };

  const handleCreatePatient = async () => {
    const res = getValuesPatient();

    await trigger('appointmentTypeId');

    const patientResponse = await dispatch(
      createPatient({
        firstName: res.firstName as string,
        lastName: res.lastName as string,
        gender: res.gender,
        phone: `+${res.phone}` as string,
        dateOfBirth: dayjs(res.dateOfBirth).utc(true).format(),
        ...(res.email.length ? { email: res.email } : {}),
        pronunciation: res.gender,
        ...(res.fin ? { nif: res.fin } : {}),
        ...(res.ssn ? { socialSecurityNumber: res.ssn } : {}),
      }),
    );
    if (patientResponse.meta.requestStatus === 'fulfilled') {
      const response =
        patientResponse.payload as PatientBriefCreationResponseDto;
      dispatch(setCurrentPatient(response.patient));
      setValue('patientId', response.patient.id);
      if (response.existed) {
        setShowPatientAlreadyExistModal(true);
        setShowCreatePatientForm(false);
        resetPatient();
        dispatch(setIsHiddenShowProfile(response.isCurrentUserHasAccess));
        return dispatch(setCurrentPatient(response.patient));
      }
      await trigger().then((isValid) => {
        if (isValid) {
          onSave({ patientId: response.patient.id });
        }
      });
    }
  };

  const handleCreateFamilyMember = async () => {
    const res = getValuesPatient();

    const patientResponse = await dispatch(
      createPatient({
        firstName: res.firstName as string,
        lastName: res.lastName as string,
        gender: res.gender,
        phone: `+${res.phone}` as string,
        dateOfBirth: dayjs(res.dateOfBirth).utc(true).format(),
        clinicIds: [currentClinicId],
        ...(res.email.length ? { email: res.email } : {}),
        pronunciation: res.gender,
        ...(res.fin ? { nif: res.fin } : {}),
        ...(res.ssn ? { socialSecurityNumber: res.ssn } : {}),
      }),
    );
    if (patientResponse.meta.requestStatus === 'fulfilled') {
      const patientResponsePayload = patientResponse.payload as any;
      setValue('patientId', patientResponsePayload.patient.id, {
        shouldValidate: !!errors.patientId?.message,
      });

      const response =
        patientResponse.payload as PatientBriefCreationResponseDto;
      const {
        firstName,
        lastName,
        gender,
        relationship,
        dateOfBirth,
        ssn,
        fin,
      } = getValuesFM();
      dispatch(
        createFamilyMember({
          patientId: response.patient!.id!,
          data: {
            firstName: String(firstName),
            lastName: String(lastName),
            relationship: String(relationship),
            dateOfBirth: dayjs(dateOfBirth).utc(true).format(),
            pronunciation: gender,
            ...(fin ? { nif: String(fin) } : {}),
            ...(ssn ? { socialSecurityNumber: String(ssn) } : {}),
          }!,
        }),
      ).then((data) => {
        if (data.meta.requestStatus === 'fulfilled') {
          const fmResponse = data.payload as PatientResponseDto;
          const statusComment =
            watch('status') === AppointmentStatus.JUSTIFIED_ABSENCE
              ? watch('statusComment')
              : '';

          trigger().then((isValid) => {
            if (isValid) {
              dispatch(
                createAppointment({
                  startDate: dayjs(appointmentStartDate).toDate(),
                  endDate: dayjs(appointmentEndDate).toDate(),
                  professionalId: String(chosenProfessionals?.value)!,
                  appointmentTypeId: String(appointmentType?.value),
                  status: watch('status'),
                  patientId:
                    response.patient!.id! || String(currentPatient?.id)!,
                  clinicId: currentClinicId,
                  comment: watch('comment'),
                  statusComment,
                  ...(appointmentDocuments.length && {
                    documents: appointmentDocuments,
                  }),
                  addToWaitingList: addToWaitingList ? 'true' : 'false',
                  ...(isCreateAppointmentForFM
                    ? { bookedForMemberId: fmResponse?.id }
                    : {}),
                }),
              ).then((data) => {
                if (data.meta.requestStatus === 'fulfilled') {
                  dispatch(setCurrentPatient(null));
                  dispatch(setCurrentFamilyMemberSearch(null));
                  dispatch(setIsAddNewEvent(true));
                  dispatch(setShowConfusingAgendaErrorModal(false));
                  if (hasConfusingAgendas) {
                    setIsContinueSettings(true);
                  }
                }
              });
            }
          });
        }
      });
    }
  };

  const onSaveWithBlock = ({ patientId }: { patientId?: string | null }) => {
    if (blockSaveRef.current) {
      return;
    }
    blockSaveRef.current = true;
    onSave({ patientId }).finally(() => {
      blockSaveRef.current = false;
    });
  };

  const continueBooking = () => {
    dispatch(setShowConfusingAgendaErrorModal(false));
  };

  const onSubmit = useMemo(() => {
    if (isEditMode) {
      return handleSubmit(() => setShowVerifyModal(true));
    } else {
      if (canCreateAppointments) {
        if (showCreatePatientForm && !hideCreateFamilyMemberButton) {
          return handleSubmitPatient(handleCreatePatient);
        }
        if (hideCreateFamilyMemberButton) {
          return handleSubmitFM(handleCreateFamilyMember);
        }
        return handleSubmit(onSaveWithBlock);
      }
      return () => {};
    }
  }, [
    canCreateAppointments,
    showCreatePatientForm,
    hideCreateFamilyMemberButton,
    isEditMode,
    handleSubmit,
    handleSubmitFM,
    handleSubmitPatient,
    onSaveWithBlock,
  ]);

  const handleSaveAfterEdit = () => {
    const comment = watch('comment');
    const statusComment =
      watch('status') === AppointmentStatus.JUSTIFIED_ABSENCE
        ? watch('statusComment')
        : '';
    const editedData = {
      clinicId: currentClinicId,
      appointmentTypeId: String(appointmentType?.value),
      status: watch('status'),
      statusComment,
      startDate: dayjs(appointmentStartDate).toDate(),
      endDate: dayjs(appointmentEndDate).toDate(),
      ...(comment !== currentAppointment?.comment ? { comment } : {}),
      addToWaitingList: addToWaitingList ? 'true' : 'false',
    };

    dispatch(
      editAppointment({
        id: String(currentAppointment?.id),
        data: editedData,
      }),
    ).then((data) => {
      dispatch(setIsAddNewEvent(true));
      setShowVerifyModal(false);
      if (hasConfusingAgendas) {
        const confusingAgendaData =
          data.payload as AppointmentUpdateResponseDto;

        dispatch(setConfused(confusingAgendaData.confusingAgenda!));
        setIsContinueSettings(true);
      }
    });
  };

  const hasUnsavedChanges = useMemo(() => {
    if (!currentAppointment) return false;
    const comment = watch('comment');
    const hasChanges =
      currentClinicId !== currentAppointment.clinic!.id ||
      appointmentType?.value !== currentAppointment.appointmentType.id ||
      !dayjs(currentAppointment.startDate).isSame(appointmentStartDate) ||
      !dayjs(currentAppointment.endDate).isSame(appointmentEndDate) ||
      comment !== currentAppointment.comment ||
      watch('status') !== currentAppointment.status ||
      watch('statusComment') !== currentAppointment.statusComment ||
      addToWaitingList !== !!currentAppointment.addedToWaitingListDate;

    dispatch(setHasUnsavedChanges(hasChanges));

    return hasChanges;
  }, [
    watch('comment'),
    watch('status'),
    watch('statusComment'),
    appointmentStartDate,
    appointmentType,
    appointmentEndDate,
    addToWaitingList,
    currentFamilyMemberSearch,
    currentAppointment,
  ]);

  return (
    <FormStyled>
      {isLoading && <Loader />}
      <Patient
        setValue={setValue}
        errors={errors}
        watchPatient={watchPatient}
        getValuesPatient={getValuesPatient}
        setValuePatient={setValuePatient}
        errorsPatient={errorsPatient}
        registerPatient={registerPatient}
        handleSubmitPatient={handleSubmitPatient}
        clearErrorsPatient={clearErrorsPatient}
        isDirtyPatient={isDirtyPatient}
        resetPatient={resetPatient}
        setValueFM={setValueFM}
        errorsFM={errorsFM}
        getValuesFM={getValuesFM}
        registerFM={registerFM}
        clearErrorsFM={clearErrorsFM}
        watchFM={watchFM}
        resetFm={resetFm}
        isCreateAppointmentForFM={isCreateAppointmentForFM}
        setIsCreateAppointmentForFM={setIsCreateAppointmentForFM}
        showCreatePatientForm={showCreatePatientForm}
        setShowCreatePatientForm={setShowCreatePatientForm}
        hideCreateFamilyMemberButton={hideCreateFamilyMemberButton}
        setHideCreateFamilyMemberButton={setHideCreateFamilyMemberButton}
        isEditMode={isEditMode}
      />
      <AppointmentClinicForm
        watch={watch}
        setValue={setValue}
        clearErrors={clearErrors}
        errors={errors}
        appointmentStartDate={appointmentStartDate}
        setAppointmentStartDate={setAppointmentStartDate}
        appointmentEndDate={appointmentEndDate}
        setAppointmentEndDate={setAppointmentEndDate}
        appointmentType={appointmentType}
        setAppointmentType={setAppointmentType}
        chosenProfessionals={chosenProfessionals}
        setChosenProfessionals={setChosenProfessionals}
        currentClinicId={currentClinicId}
        setCurrentClinicId={setCurrentClinicId}
        hasConfusingAgendas={hasConfusingAgendas}
        documentLoading={documentLoading}
        isCreateAppointmentValid={isCreateAppointmentValid}
        onSubmit={onSubmit}
        clinicOwnerIdBySelectedClinic={clinicOwnerIdBySelectedClinic}
        setClinicOwnerIdBySelectedClinic={setClinicOwnerIdBySelectedClinic}
        hasUnsavedChanges={hasUnsavedChanges}
        onClose={onClose}
        setShowLeaveModal={setShowLeaveModal}
        isDirtyPatient={isDirtyPatient}
        setDefaultAppointmentTime={setDefaultAppointmentTime}
      />
      <AppointmentActions />

      {showPatientAlreadyExistModal && (
        <PatientAlreadyExistModal
          opened={showPatientAlreadyExistModal}
          onClose={() => setShowPatientAlreadyExistModal(false)}
        />
      )}
      {showVerifyModal && (
        <VerifyEditingAppointmentModal
          isOpen={showVerifyModal}
          patientName={`${currentAppointment?.patient?.firstName} ${currentAppointment?.patient?.lastName}`}
          startDate={dayjs(appointmentStartDate).toDate()}
          oldDate={dayjs(currentAppointment?.startDate).toDate()}
          onClose={() => setShowVerifyModal(false)}
          onSubmit={handleSaveAfterEdit}
          isLoading={isLoadingAppointment}
        />
      )}
      {showConfusingAgendaErrorModal && (
        <ErrorConfusingModal
          onClose={() => setShowConfusingAgendaErrorModal(false)}
          continueBooking={continueBooking}
          closeAppointmentModal={() => {}}
        />
      )}
      {showLeaveModal && (
        <LeavePageModal
          showModal={showLeaveModal}
          onClose={() => {
            setShowLeaveModal(false);
          }}
          onSubmit={() => {
            onClose();
          }}
        />
      )}
    </FormStyled>
  );
};

export default AppointmentForm;
