import React, { FC, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { PathNames } from 'applicaiton/routes';
import { UserPermissions } from 'applicaiton/constants/userPermissions';
import { getUserId } from 'applicaiton/sessionStorage/auth';
import {
  setIsAppointmentDeleted,
  setIsEndedAppointment,
  setSlotsDetails,
} from 'applicaiton/store/reducers/Appointments/AppoinmentsSlice';
import { ReserveSlotsMode } from 'applicaiton/constants/reserveSlots';
import {
  deleteAppointment,
  endAppointment,
  updateAppointmentStatus,
} from 'applicaiton/store/reducers/Appointments/ActionCreators';
import {
  CalendarIcon,
  ClinicIcon,
  ClockIcon,
  CommentIcon,
  ProfessionalIcon,
  InConsultationIcon,
  JustifiedAbsenceIcon,
  SeenIcon,
  UnjustifiedAbsenceIcon,
  UpcomingIcon,
  WaitingRoomIcon,
} from 'applicaiton/assets';
import { setIsAddNewEvent } from 'applicaiton/store/reducers/Scheduler/SchedulerSlice';

import { Input, PrimaryButton, SecondaryButton } from 'common/components';
import { userHasPermission } from 'common/helpers/userHasPermission';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { AppointmentStatus } from 'common/types/appointmentStatus';
import { useUserTimeZone } from 'common/hooks/useUserTimeZone';

import { useAppointmentStatus } from 'features/feature-agenda-reserve-slots-modal/hooks/useAppointmentStatus';
import DeleteModal from '../DeleteModal';
import SuccessDeletedModal from '../SuccessDeletedModal';
import ConfirmEndAppointment from '../ConfirmEndAppointment';
import SuccessAppointmentEnded from '../SuccessAppointmentEnded';

import { AppointmentClinicDetailsProps } from './models';
import {
  Wrapper,
  ButtonWrapper,
  BookAgain,
  RightButtons,
  StyledDeleteIcon,
  StyledEditIcon,
  InfoItem,
  ColorSquare,
  Content,
  DividerStyled,
  AppointmentStatusStyled,
  RadioButtonWrapper,
  StatusStyled,
} from './styles';

const getAppointmentStatusImage = (status: AppointmentStatus) => {
  switch (status) {
    case AppointmentStatus.UPCOMING:
      return <UpcomingIcon />;
    case AppointmentStatus.IN_CONSULTATION:
      return <InConsultationIcon />;
    case AppointmentStatus.WAITING_ROOM:
      return <WaitingRoomIcon />;
    case AppointmentStatus.JUSTIFIED_ABSENCE:
      return <JustifiedAbsenceIcon />;
    case AppointmentStatus.UNJUSTIFIED_ABSENCE:
      return <UnjustifiedAbsenceIcon />;
    case AppointmentStatus.SEEN:
      return <SeenIcon />;
    default:
      return '';
  }
};

const AppointmentClinicDetails: FC<AppointmentClinicDetailsProps> = ({
  onCloseModal,
}) => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const userId = getUserId();
  const appointmentStatuses = useAppointmentStatus();
  const { tz } = useUserTimeZone();

  const {
    isLoading: isLoadingAppointment,
    isShowDeletedWindow,
    currentAppointment,
    appointmentId,
    isEndedAppointment,
  } = useAppSelector((state) => state.appointmentsSlice);
  const { isLoading: isLoadingAppointmentDocument } = useAppSelector(
    (state) => state.appointmentDocumentsSlice,
  );

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openConfirmEndAppointmentDialog, setOpenConfirmEndAppointmentDialog] =
    useState(false);
  const [currentStatus, setCurrentStatus] = useState<AppointmentStatus | null>(
    null,
  );
  const [currentStatusComment, setCurrentStatusComment] = useState<string>('');

  useEffect(() => {
    if (currentAppointment) {
      setCurrentStatus(currentAppointment.status);
      setCurrentStatusComment(currentAppointment.statusComment || '');
    }
  }, [currentAppointment?.statusComment, currentAppointment?.status]);

  const isMyAgendaPage = location.pathname === PathNames.clinicOwnerSchedule;
  const isLoading = isLoadingAppointment || isLoadingAppointmentDocument;

  const clinicOwnerId = useMemo(() => {
    const ownerId = currentAppointment?.clinic?.owner?.id;
    if (ownerId && ownerId !== userId) {
      return Number(currentAppointment?.clinic?.owner?.id);
    }
    return undefined;
  }, [currentAppointment?.clinic?.owner?.id])
    ? Number(currentAppointment?.clinic?.owner?.id)
    : undefined;

  const isCanCancelAgenda = userHasPermission(
    UserPermissions.cancelAgenda,
    clinicOwnerId,
    isMyAgendaPage,
  );
  const isCanReschedule = userHasPermission(
    UserPermissions.rescheduleAppointment,
    clinicOwnerId,
    isMyAgendaPage,
  );

  const isPastAppointment = useMemo(() => {
    return !dayjs().isBefore(currentAppointment?.endDate);
  }, [currentAppointment]);

  const isAppointmentNotStarted = useMemo(() => {
    return dayjs().isBefore(currentAppointment?.startDate);
  }, [currentAppointment]);

  const cancelAppointment = () => {
    dispatch(deleteAppointment(String(appointmentId)));
  };

  const handleBookAgain = () => {
    dispatch(
      setSlotsDetails({
        appointmentId,
        mode: ReserveSlotsMode.BookAgain,
        startDate: dayjs().toDate(),
      }),
    );
  };

  const handleEditAppointment = () => {
    dispatch(
      setSlotsDetails({
        appointmentId,
        mode: ReserveSlotsMode.EditAppointment,
      }),
    );
  };

  const formattedDate = useMemo(() => {
    if (!currentAppointment) return;
    return dayjs
      .tz(currentAppointment!.startDate, tz)
      .locale(i18n.language)
      .format('dddd, DD/MM/YYYY');
  }, [currentAppointment]);

  const getTimeString = (date?: Date | null) => {
    const dateInTz = dayjs(date).tz();
    return dateInTz.format('HH:mm');
  };

  const timeFromTo = useMemo(() => {
    if (!currentAppointment) return;
    const timeFrom = getTimeString(currentAppointment!.startDate);
    const timeTo = getTimeString(currentAppointment!.endDate);
    return `${timeFrom} - ${timeTo}`;
  }, [currentAppointment]);

  const handleConfirmEndAppointment = () => {
    dispatch(endAppointment(appointmentId!));
  };

  const handleCloseConfirmEndAppointment = () => {
    dispatch(setIsEndedAppointment(false));
    setOpenConfirmEndAppointmentDialog(false);
    onCloseModal();
  };

  const handleDeleted = () => {
    dispatch(setIsAppointmentDeleted(true));
    onCloseModal();
  };

  const cancelEditing = () => {
    setCurrentStatus(null);
    setCurrentStatusComment('');
  };

  const onSaveStatus = async () => {
    if (currentStatus && currentAppointment) {
      const statusComment =
        currentStatus === AppointmentStatus.JUSTIFIED_ABSENCE
          ? currentStatusComment
          : '';

      const response = await dispatch(
        updateAppointmentStatus({
          id: String(currentAppointment.id),
          data: {
            status: currentStatus,
            statusComment,
          },
        }),
      );
      if (response.meta.requestStatus === 'fulfilled') {
        dispatch(setIsAddNewEvent(true));
      }
    }
  };

  const showEditButtons = useMemo(() => {
    if (!currentStatus) return false;
    const isStatusChanges = currentAppointment?.status !== currentStatus;
    const isStatusCommentChanges =
      currentAppointment?.statusComment !== currentStatusComment;

    return isStatusChanges || isStatusCommentChanges;
  }, [currentStatus, currentStatusComment, currentAppointment]);

  return (
    <Wrapper>
      <Content>
        <InfoItem>
          <ClinicIcon />
          {currentAppointment?.clinic!.name}
        </InfoItem>
        <InfoItem>
          <ProfessionalIcon />
          {`${currentAppointment?.professional!.firstName} ${
            currentAppointment?.professional!.lastName
          }`}
        </InfoItem>
        <InfoItem>
          <ColorSquare
            colorCode={currentAppointment?.appointmentType!.colorCode}
          />
          {currentAppointment?.appointmentType!.title!}
        </InfoItem>
        <InfoItem>
          <CalendarIcon />
          {formattedDate}
        </InfoItem>
        <InfoItem>
          <ClockIcon />
          {timeFromTo}
        </InfoItem>
        {!!currentAppointment?.comment && (
          <InfoItem>
            <CommentIcon />
            {currentAppointment.comment}
          </InfoItem>
        )}
        <DividerStyled />
        <AppointmentStatusStyled>
          <p>{t('appointment.appointment_status')}</p>
          {appointmentStatuses.map((status) => (
            <RadioButtonWrapper key={status.value}>
              <StatusStyled
                key={status.value}
                isActive={
                  (currentStatus || currentAppointment?.status) === status.value
                }
                onClick={() => setCurrentStatus(status.value)}
              >
                {getAppointmentStatusImage(status.value)}
                {status.label}
              </StatusStyled>
              {(currentStatus || currentAppointment?.status) === status.value &&
                status.value === AppointmentStatus.JUSTIFIED_ABSENCE && (
                  <Input
                    id="statusComments"
                    label={t('appointment.status_comment_label') || ''}
                    type="text"
                    value={currentStatusComment}
                    placeholder={
                      t('appointment.status_comment_placeholder') || ''
                    }
                    onChange={(value) => {
                      setCurrentStatusComment(value.target.value);
                    }}
                  />
                )}
            </RadioButtonWrapper>
          ))}
        </AppointmentStatusStyled>
      </Content>
      <ButtonWrapper>
        {!showEditButtons && !isAppointmentNotStarted && isCanReschedule && (
          <BookAgain>
            {isPastAppointment && currentAppointment?.isBillable && (
              <SecondaryButton
                onClick={() => setOpenConfirmEndAppointmentDialog(true)}
              >
                {t('appointment.end_appointment')}
              </SecondaryButton>
            )}
            <PrimaryButton onClick={handleBookAgain}>
              {t('appointment.book_again')}
            </PrimaryButton>
          </BookAgain>
        )}
        {!showEditButtons && isAppointmentNotStarted && (
          <RightButtons>
            {isCanCancelAgenda && (
              <SecondaryButton
                onClick={() => setOpenConfirmDialog(true)}
                disabled={isLoading}
              >
                <StyledDeleteIcon />
                {t('cancel')}
              </SecondaryButton>
            )}
            {isCanReschedule && (
              <SecondaryButton
                onClick={handleEditAppointment}
                disabled={isLoading}
              >
                <StyledEditIcon />
                {t('edit')}
              </SecondaryButton>
            )}
          </RightButtons>
        )}
        {showEditButtons && (
          <BookAgain>
            {isCanCancelAgenda && (
              <SecondaryButton onClick={cancelEditing} disabled={isLoading}>
                {t('cancel')}
              </SecondaryButton>
            )}
            {isCanReschedule && (
              <PrimaryButton onClick={onSaveStatus}>{t('save')}</PrimaryButton>
            )}
          </BookAgain>
        )}
      </ButtonWrapper>
      {openConfirmDialog && (
        <DeleteModal
          isOpen={openConfirmDialog}
          patientName={`${currentAppointment?.patient!.firstName} ${
            currentAppointment?.patient!.lastName
          }`}
          startDate={currentAppointment?.startDate!}
          onClose={() => setOpenConfirmDialog(false)}
          onSubmit={cancelAppointment}
        />
      )}
      {isShowDeletedWindow && (
        <SuccessDeletedModal
          onClose={handleDeleted}
          patientName={`${currentAppointment?.patient!.firstName} ${
            currentAppointment?.patient!.lastName
          }`}
        />
      )}
      {openConfirmEndAppointmentDialog && (
        <ConfirmEndAppointment
          isOpen={openConfirmEndAppointmentDialog}
          onClose={() => setOpenConfirmEndAppointmentDialog(false)}
          onSubmit={handleConfirmEndAppointment}
        />
      )}
      {isEndedAppointment && (
        <SuccessAppointmentEnded onClose={handleCloseConfirmEndAppointment} />
      )}
    </Wrapper>
  );
};

export default AppointmentClinicDetails;
