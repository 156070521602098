import React, { useEffect, useMemo } from 'react';

import { ReactComponent as TeleconsultationCameraIcon } from 'applicaiton/assets/chat-camera.svg';

import {
  ButtonsSection,
  ChatHeaderWrapper,
  MenuButtonStyled,
  TeleconsultationButton,
} from './styles';
import { DotsIcon } from 'applicaiton/assets';
import { useConversation } from '../../hooks/useConversation';
import { ActiveChatMenu } from '../ActiveChatMenu';
import { useChatParticipants } from '../../hooks/useChatParticipants';
import { User } from '@twilio/conversations';
import { useTranslation } from 'react-i18next';
import { useTeleconsultationRequest } from '../../hooks/useTeleconsultationRequest';

export const ActiveChatHeader = () => {
  const [menuAnchor, setMenuAnchor] = React.useState<null | HTMLElement>(null);
  const { selectedChat } = useConversation();
  const { startTeleconsultation } = useTeleconsultationRequest();
  const { t } = useTranslation();
  const [patientIsActive, setPatientIsActive] = React.useState<boolean>(false);
  const { oppositeUser } = useChatParticipants(
    selectedChat?.twilioConversationSID || '',
    selectedChat,
  );

  useEffect(() => {
    if (!oppositeUser) {
      return;
    }
    setPatientIsActive(!!oppositeUser.isOnline);
    oppositeUser.on('updated', ({ user }: { user: User }) => {
      setPatientIsActive(!!user.isOnline);
    });
    return () => {
      oppositeUser.removeAllListeners('updated');
    };
  }, [oppositeUser]);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(event.currentTarget);
  };
  const handleClose = () => {
    setMenuAnchor(null);
  };
  const patientName = selectedChat?.patient
    ? `${selectedChat.patient.firstName} ${selectedChat.patient.lastName}`
    : '';

  const patientStatus = useMemo(() => {
    return patientIsActive
      ? t('chats.active_chat.patient_online')
      : t('chats.active_chat.patient_offline');
  }, [patientIsActive]);

  const onStartChatTeleconsultation = () => {
    if (selectedChat?.twilioConversationSID) {
      startTeleconsultation(selectedChat?.twilioConversationSID);
    }
  };

  return (
    <>
      <ChatHeaderWrapper>
        <div>
          <h1 className={'patient-name'}>{patientName}</h1>
          <p className={'patient-status'}>{patientStatus}</p>
        </div>
        <ButtonsSection>
          <TeleconsultationButton
            type="button"
            onClick={onStartChatTeleconsultation}
          >
            <TeleconsultationCameraIcon />
          </TeleconsultationButton>
          <MenuButtonStyled onClick={handleOpenMenu}>
            <DotsIcon />
          </MenuButtonStyled>
        </ButtonsSection>
      </ChatHeaderWrapper>
      <ActiveChatMenu anchorEl={menuAnchor} onClose={handleClose} />
    </>
  );
};
