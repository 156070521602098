import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CalendarIcon } from 'applicaiton/assets/calendar.svg';

import {
  StyledTr,
  ActionsStyled,
  DateWrapper,
  ShowMore,
  DateCol,
} from './styles';

import { Button } from '@mui/material';
import dayjs from 'dayjs';
import {
  AppointmentWaitingListResponseDto,
  DateRangeDto,
  PatientResponseDto,
} from '@docbay/schemas';
import AvailableEarlySlotsModal from '../AvailableEarlySlotsModal';
import ReserveSlotsModal from 'features/feature-reserve-slots-modal';
import { useAppDispatch } from 'common/hooks/redux';
import {
  setCurrentAppointment,
  setCurrentPatient,
  setSlotsDetails,
} from 'applicaiton/store/reducers/Appointments/AppoinmentsSlice';
import { ReserveSlotsMode } from 'applicaiton/constants/reserveSlots';

interface Props {
  appointment: AppointmentWaitingListResponseDto;
}

const WaitingAppointmentItem: FC<Props> = ({ appointment }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const [showMore, setShowMore] = useState(false);
  const [showAvailableSlots, setShowAvailableSlots] = useState(false);

  const firstAvailableDate = appointment?.availableSlots?.length
    ? appointment?.availableSlots[0]
    : undefined;

  const handleEditAppointment = async () => {
    await setShowAvailableSlots(false);

    await dispatch(
      setCurrentPatient(appointment.patient as PatientResponseDto),
    );
    await dispatch(
      setSlotsDetails({
        appointmentId: appointment.id,
        startDate: dayjs(appointment?.startDate)
          .locale(i18n.language)
          .format('YYYY-MM-DD') as any,
        professionalId: appointment.professional?.id,
        mode: ReserveSlotsMode.EditAppointment,
      }),
    );
    await dispatch(setCurrentAppointment(appointment));
  };

  return (
    <StyledTr>
      <td>
        <span className={'colText'}>
          {appointment.patient?.firstName} {appointment.patient?.lastName}
        </span>
        <span>{appointment.patient?.phone}</span>
      </td>
      <td className={'professionalCol'}>
        <span>
          {appointment.professional?.firstName}{' '}
          {appointment.professional?.lastName}
        </span>
      </td>
      <td>
        <span>{appointment.appointmentType.title}</span>
      </td>
      <td>
        <span className={'colText date'}>
          {dayjs(appointment.startDate).format('DD MMMM, YYYY')}
        </span>
        <span>
          {t('at')} {dayjs(appointment.startDate).format('HH:mm')}
        </span>
      </td>
      <td>
        {!showMore && firstAvailableDate && (
          <DateWrapper>
            <span className={'colText date'}>
              {dayjs(firstAvailableDate.startDate).format('DD MMMM, YYYY')}
            </span>
            <span>{dayjs(firstAvailableDate.startDate).format('HH:mm')}</span>
          </DateWrapper>
        )}
        {showMore && !!appointment?.availableSlots?.length && (
          <DateCol>
            {appointment?.availableSlots?.map(
              (item: DateRangeDto, ind: number) => {
                return (
                  <DateWrapper key={`${item.startDate}_${ind}`}>
                    <span className={'colText date'}>
                      {dayjs(item.startDate).format('DD MMMM, YYYY')}
                    </span>
                    <span>{dayjs(item.startDate).format('HH:mm')}</span>
                  </DateWrapper>
                );
              },
            )}
          </DateCol>
        )}

        {!!appointment?.availableSlots?.length && (
          <ShowMore
            onClick={() => {
              setShowMore((prev) => !prev);
            }}
          >
            {!showMore
              ? `${t('show_more')} (${appointment?.availableSlots?.length - 1})`
              : t('show_less')}
          </ShowMore>
        )}
      </td>
      <td>
        <ActionsStyled>
          <Button onClick={() => setShowAvailableSlots(true)}>
            <CalendarIcon />
            {t('edit')}
          </Button>
        </ActionsStyled>
      </td>
      {showAvailableSlots && (
        <AvailableEarlySlotsModal
          data={appointment}
          onClose={() => setShowAvailableSlots(false)}
          onEdit={handleEditAppointment}
        />
      )}
      <ReserveSlotsModal onCloseModal={() => {}} />
    </StyledTr>
  );
};
export default WaitingAppointmentItem;
