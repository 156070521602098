enum AsideMenu {
  isMenuOpened = 'isMenuOpened',
}

export const setMenuState = (open: boolean) => {
  sessionStorage.setItem(AsideMenu.isMenuOpened, JSON.stringify(open));
};

export const getMenuState = () => {
  const isMenuOpened = sessionStorage.getItem(AsideMenu.isMenuOpened);
  return isMenuOpened ? !!JSON.parse(isMenuOpened) : false;
};
