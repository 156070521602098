import React, { FC } from 'react';
import { Wrapper } from './styles';
import { SideMenu, UserPermissions } from 'features';

const UserPermissionsPage: FC = () => {
  return (
    <Wrapper>
      <SideMenu />
      <UserPermissions />
    </Wrapper>
  );
};

export default UserPermissionsPage;
