import styled from 'styled-components';

export const Wrapper = styled.div`
  ol {
    list-style: decimal;
  }

  ul {
    list-style: unset;
  }

  blockquote {
    margin: 0;
    margin-left: 20px;
    font-size: 15px;
    color: rgb(101, 103, 107);
    border-left-color: rgb(206, 208, 212);
    border-left-width: 4px;
    border-left-style: solid;
    padding-left: 16px;
  }

  em {
    font-style: italic;
  }

  strong {
    font-weight: bold;
  }
`;
