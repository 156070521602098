import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';

export interface AppointmentFieldsT {
  appointmentTypeId: string | null;
  patientId: string | null;
}

export const useCreateAppointment = ({
  requiredError,
}: {
  requiredError: string;
}) => {
  const formValues = {
    appointmentTypeId: null,
    patientId: null,
  };

  const appointmentSchema = yup
    .object({
      appointmentTypeId: yup.string().required(requiredError!).nullable(),
      patientId: yup.string().required(requiredError!).nullable(),
    })
    .required();

  const {
    watch,
    getValues,
    setValue,
    register,
    handleSubmit,
    clearErrors,
    setError,
    trigger,
    formState: { errors, isValid },
  } = useForm<AppointmentFieldsT>({
    resolver: yupResolver(appointmentSchema),
    defaultValues: formValues,
  });

  return {
    watch,
    getValues,
    setValue,
    errors,
    register,
    handleSubmit,
    clearErrors,
    setError,
    trigger,
    isValid,
  };
};
