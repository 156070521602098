import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AddDocument,
  StyledPlusIcon,
  Loader,
  UploadingProgress,
  UploadingText,
} from './styles';
import {
  deleteDocuments,
  postDocuments,
} from 'applicaiton/store/reducers/AppointmentDocuments/ActionCreators';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { AppointmentDocumentDto } from '@docbay/schemas';
import { setAppointmentDocuments } from 'applicaiton/store/reducers/AppointmentDocuments/AppoinmentsDocumentsSlice';
import AppointmentDocumentsItem from '../AppointmentDocumentsItem';
import { ReactComponent as LoadIcon } from 'applicaiton/assets/loader.svg';
import { ReactComponent as AttentionIcon } from 'applicaiton/assets/attention.svg';
import { Props } from './modules';

export const S3BucketTypes = {
  TMP: 'TMP',
  DEFAULT: 'DEFAULT',
};

const AppointmentDocuments = ({ isEditMode }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [documentList, setDocumentList] = useState<
    AppointmentDocumentDto[] | undefined
  >([]);

  const { appointmentDocuments, error, isLoading } = useAppSelector(
    (state) => state.appointmentDocumentsSlice,
  );

  const { currentAppointment } = useAppSelector(
    (state) => state.appointmentsSlice,
  );

  useEffect(() => {
    setDocumentList(
      isEditMode ? currentAppointment?.documents : appointmentDocuments,
    );
  }, [appointmentDocuments, currentAppointment]);

  const handleDocsUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    const formData = new FormData();

    if (files?.length) {
      for (let i = 0; i < files.length; i++) {
        formData.append('documents', files![i]);
      }

      dispatch(postDocuments(formData));
    }
  };

  const handleDeleteDocument = (key: string) => {
    const filteredDocuments = appointmentDocuments.filter(
      (item) => item.key !== key,
    );

    dispatch(setAppointmentDocuments(filteredDocuments));
    dispatch(
      deleteDocuments({
        ...(isEditMode && { relatedEntityId: currentAppointment?.id }),
        key: key,
        bucketType: isEditMode ? S3BucketTypes.DEFAULT : S3BucketTypes.TMP,
      }),
    );
  };

  return (
    <AddDocument isLoading={isLoading}>
      {!!documentList?.length && (
        <>
          {documentList?.map((item) => (
            <AppointmentDocumentsItem
              key={item.key}
              item={item}
              onDelete={handleDeleteDocument}
              editMode={isEditMode}
              currentAppointmentId={currentAppointment?.id}
            />
          ))}
        </>
      )}
      {!!isLoading && (
        <UploadingProgress>
          <Loader>
            <LoadIcon />
          </Loader>
          <UploadingText>
            <p>{t('editProfileSettings.uploadingPhotos')}</p>
            <p>{t('editProfileSettings.wait')}</p>
          </UploadingText>
        </UploadingProgress>
      )}
      {!!error && (
        <UploadingProgress>
          <Loader isError={!!error}>
            <AttentionIcon />
          </Loader>
          <UploadingText isError={!!error}>
            <p>{t('editProfileSettings.errorTitle')}</p>
            <p>{t('editProfileSettings.errorMessage')}</p>
          </UploadingText>
        </UploadingProgress>
      )}
      <div className={isLoading ? 'disabled' : ''}>
        <label htmlFor="photo" />
        <input
          id="photo"
          type="file"
          accept=".jpg, .jpeg, .png, .pdf, .doc, .docx, .odt"
          onChange={handleDocsUpload}
          multiple
          disabled={isLoading}
        />
        <StyledPlusIcon /> {t('appointment.add_documents')}
      </div>
    </AddDocument>
  );
};

export default AppointmentDocuments;
