import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppointmentDocumentDto } from '@docbay/schemas';

import {
  deleteDocuments,
  postDocuments,
  postDocumentsToAppointment,
} from 'applicaiton/store/reducers/AppointmentDocuments/ActionCreators';
import {
  setAppointmentDocuments,
  setDeletedDocument,
} from 'applicaiton/store/reducers/AppointmentDocuments/AppoinmentsDocumentsSlice';
import { ReactComponent as LoadIcon } from 'applicaiton/assets/loader.svg';
import { ReactComponent as AttentionIcon } from 'applicaiton/assets/attention.svg';
import { fetchAppointmentById } from 'applicaiton/store/reducers/Appointments/ActionCreators';

import { useAppDispatch, useAppSelector } from 'common/hooks/redux';

import AppointmentDocumentsItem from '../AppointmentDocumentsItem';
import ConfirmDeleteDocument from '../ConfirmDeleteDocument';
import SuccessDocumentDeletedModal from '../SuccessDocumentDeletedModal';

import { Props } from './modules';
import {
  AddDocument,
  StyledPlusIcon,
  Loader,
  UploadingProgress,
  UploadingText,
  DividerStyled,
  FileFormatsStyled,
} from './styles';

export const S3BucketTypes = {
  TMP: 'TMP',
  DEFAULT: 'DEFAULT',
};

const AppointmentDocuments = ({ isCreateMode }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [documentList, setDocumentList] = useState<
    AppointmentDocumentDto[] | undefined
  >([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showSuccessDeleteModal, setShowSuccessDeleteModal] = useState(false);

  const { appointmentDocuments, error, isLoading, deletedDocument } =
    useAppSelector((state) => state.appointmentDocumentsSlice);

  const { currentAppointment } = useAppSelector(
    (state) => state.appointmentsSlice,
  );

  useEffect(() => {
    setDocumentList(
      !isCreateMode ? currentAppointment?.documents : appointmentDocuments,
    );
  }, [appointmentDocuments, currentAppointment, isCreateMode]);

  const handleDocsUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    const formData = new FormData();

    if (files?.length) {
      for (let i = 0; i < files.length; i++) {
        formData.append('documents', files![i]);
      }

      if (isCreateMode) {
        await dispatch(postDocuments(formData));
      } else {
        dispatch(
          postDocumentsToAppointment({
            appointmentId: currentAppointment?.id || '',
            data: formData,
          }),
        ).then(() => {
          e.target.value = '';
          dispatch(fetchAppointmentById(String(currentAppointment?.id)));
        });
      }
    }
  };

  const handleDeleteDocument = (key?: string) => {
    const filteredDocuments = appointmentDocuments.filter(
      (item) => item.key !== key,
    );

    if (isCreateMode) {
      dispatch(setAppointmentDocuments(filteredDocuments));
      dispatch(
        deleteDocuments({
          key: key || '',
          bucketType: S3BucketTypes.TMP,
        }),
      );
    } else {
      dispatch(
        deleteDocuments({
          relatedEntityId: currentAppointment?.id,
          key: deletedDocument?.key || '',
          bucketType: S3BucketTypes.DEFAULT,
        }),
      ).then(() => {
        setShowDeleteModal(false);
        dispatch(fetchAppointmentById(String(currentAppointment?.id)));
        setShowSuccessDeleteModal(true);
      });
    }
  };

  const handleCancelDelete = () => {
    setShowDeleteModal(false);
    dispatch(setDeletedDocument(null));
  };

  const handleCloseSuccessDelete = () => {
    setShowSuccessDeleteModal(false);
    dispatch(setDeletedDocument(null));
  };

  const onDeleteDocument = isCreateMode
    ? handleDeleteDocument
    : () => setShowDeleteModal(true);

  return (
    <AddDocument isLoading={isLoading}>
      <div className={isLoading ? 'disabled' : ''}>
        <label htmlFor="photo" />
        <input
          id="photo"
          type="file"
          accept=".jpg, .jpeg, .png, .pdf, .doc, .docx, .odt"
          onChange={handleDocsUpload}
          multiple
          disabled={isLoading}
        />
        <StyledPlusIcon /> {t('appointment.add_documents')}
      </div>
      <FileFormatsStyled>SVG, PNG, JPG or GIF (max. 40 MB)</FileFormatsStyled>
      <DividerStyled />
      {!!error && (
        <UploadingProgress>
          <Loader isError={!!error}>
            <AttentionIcon />
          </Loader>
          <UploadingText isError={!!error}>
            <p>{t('editProfileSettings.errorTitle')}</p>
            <p>{t('editProfileSettings.errorMessage')}</p>
          </UploadingText>
        </UploadingProgress>
      )}
      {!!isLoading && (
        <UploadingProgress>
          <Loader>
            <LoadIcon />
          </Loader>
          <UploadingText>
            <p>{t('editProfileSettings.uploadingPhotos')}</p>
            <p>{t('editProfileSettings.wait')}</p>
          </UploadingText>
        </UploadingProgress>
      )}
      {!!documentList?.length && (
        <>
          {documentList?.map((item) => (
            <AppointmentDocumentsItem
              key={item.key}
              item={item}
              onDelete={onDeleteDocument}
              editMode={!isCreateMode}
              currentAppointmentId={currentAppointment?.id}
            />
          ))}
        </>
      )}
      {showDeleteModal && (
        <ConfirmDeleteDocument
          isOpen={showDeleteModal}
          documentName={
            `${deletedDocument?.originalName}.${deletedDocument?.extension}` ||
            ''
          }
          onClose={handleCancelDelete}
          onSubmit={handleDeleteDocument}
        />
      )}
      {showSuccessDeleteModal && (
        <SuccessDocumentDeletedModal
          documentName={
            `${deletedDocument?.originalName}.${deletedDocument?.extension}` ||
            ''
          }
          onClose={handleCloseSuccessDelete}
        />
      )}
    </AddDocument>
  );
};

export default AppointmentDocuments;
