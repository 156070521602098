import React from 'react';
import SelectedPatientInfo from 'features/feature-reserve-slots-modal/components/SelectedPatientInfo';
import { StyledDivider, InfoItem } from './styles';
import { ReactComponent as CommentIcon } from 'applicaiton/assets/comment.svg';
import { useAppointment } from 'common/hooks/useAppointment';

const Information = () => {
  const { currentAppointment } = useAppointment();
  return (
    <>
      <SelectedPatientInfo isTeleconsultation />
      <StyledDivider />
      {!!currentAppointment?.comment && (
        <InfoItem>
          <CommentIcon />
          {currentAppointment.comment}
        </InfoItem>
      )}
    </>
  );
};

export default Information;
