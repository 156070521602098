import React, { FC, useEffect, useMemo, useState } from 'react';
import { Modal } from '@mui/material';
import {
  Head,
  StyledModal,
  Title,
  StyledCloseIcon,
  Tabs,
  Tab,
  StyledPlusIcon,
} from './styles';
import { useTranslation } from 'react-i18next';
import CreateAbsenceTab from './components/CreateAbsence';
import SuccessModal from './components/SuccessModal';
import WarningModal from './components/WarningModal';
import SuccessWithConfuseModal from './components/SuccessWithConfuseModal';
import CreateAppointment from './components/CreateAppointment';
import SuccessAppointmentCreated from './components/SuccessAppointmentCreated';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { UserPermissions } from 'applicaiton/constants/userPermissions';
import EventInfo from './components/EventInfo';
import {
  resetCurrentAppointment,
  resetSlotsDetails,
  setCurrentFamilyMemberSearch,
  setCurrentPatient,
  setIsAppointmentDeleted,
  setIsCreatedAppointment,
  setMode,
} from 'applicaiton/store/reducers/Appointments/AppoinmentsSlice';
import SuccessAppointmentEditing from './components/SuccessAppointmentEditing';
import { userHasPermission } from 'common/helpers/userHasPermission';
import { ReserveSlotsMode } from 'applicaiton/constants/reserveSlots';
import { resetAbsence } from 'applicaiton/store/reducers/Absence/AbsenceSlice';
import { setHasUnsavedChanges } from 'applicaiton/store/reducers/DetectChangesSaved/DetectChangesSavedSlice';
import {
  resetDocumentsErrors,
  setAppointmentDocuments,
} from 'applicaiton/store/reducers/AppointmentDocuments/AppoinmentsDocumentsSlice';
import {
  setConfused,
  setCreatedAbsence,
} from 'applicaiton/store/reducers/ConfusingAgenda/ConfusingAgendaSlice';
import OpenAgenda from './components/OpenAgenda';
import {
  resetOpenAgenda,
  setEditedOpenAgenda,
} from '../../applicaiton/store/reducers/Agenda/AgendaSlice';
import { useSearchParams } from 'react-router-dom';

interface Props {
  onCloseModal: () => void;
}

const ReserveSlotsModal: FC<Props> = ({ onCloseModal }) => {
  const isCanCreateAbsence = userHasPermission(UserPermissions.createAbsence);
  const isCanCreateAppointment = userHasPermission(
    UserPermissions.createAppointment,
  );
  const isCanManageWorkingHours = userHasPermission(
    UserPermissions.professionalOpeningHours,
  );
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isConfused, setIsConfused] = useState<boolean>(false);
  const [isContinueSettings, setIsContinueSettings] = useState<boolean>(false);
  const { isCreatedAppointment, isEdited, mode } = useAppSelector(
    (state) => state.appointmentsSlice,
  );
  const { confusingAgendas } = useAppSelector(
    (state) => state.confusingAgendasSlice,
  );
  const isPreviewAppointment = mode === ReserveSlotsMode.PreviewAppointment;
  const isCreateAppointment = mode === ReserveSlotsMode.CreateAppointment;
  const isEditAppointment = mode === ReserveSlotsMode.EditAppointment;
  const isCreateAbsence = mode === ReserveSlotsMode.CreateAbsence;
  const isEditAbsence = mode === ReserveSlotsMode.EditAbsence;
  const isBookAgain = mode === ReserveSlotsMode.BookAgain;
  const isOpenAgenda = mode === ReserveSlotsMode.OpenAgenda;

  const isEditMode = isEditAppointment || isEditAbsence;
  const appointmentID = searchParams.get('appointment');

  const handleSave = (success: boolean) => {
    success ? setIsSuccess(true) : setIsContinueSettings(true);
  };

  const hasConfusingAgendas =
    !!confusingAgendas?.absences.length ||
    !!confusingAgendas?.appointments.length;

  const handleCloseModal = () => {
    onCloseModal && onCloseModal();
    dispatch(resetSlotsDetails());
    dispatch(resetAbsence());
    dispatch(resetCurrentAppointment());
    dispatch(setIsCreatedAppointment(false));
    dispatch(setAppointmentDocuments([]));
    dispatch(resetDocumentsErrors());
    hasConfusingAgendas && dispatch(setConfused(null));
    dispatch(setCurrentPatient(null));
    dispatch(setCurrentFamilyMemberSearch(null));
    dispatch(resetOpenAgenda());
    dispatch(setEditedOpenAgenda(null));
    appointmentID && setSearchParams('');
  };

  const handleCloseSuccessModal = () => {
    setIsSuccess(false);
    handleCloseModal();
  };

  const handleContinueSettings = () => {
    setIsContinueSettings(true);
    setIsConfused(false);
    handleCloseModal();
  };

  const handleSuccessConfuseModal = () => {
    setIsContinueSettings(false);
    handleCloseModal();
    dispatch(setCreatedAbsence(null));
  };

  const title = useMemo(() => {
    switch (mode) {
      case ReserveSlotsMode.BookAgain:
        return t('appointment.book_again');
      case ReserveSlotsMode.EditAppointment:
        return t('appointment.edit_appointment');
      case ReserveSlotsMode.EditAbsence:
        return t('appointment.edit_absence_hours');
    }
    return t('absenceModal.reserve_slot');
  }, [mode]);

  const showCreateModal = !isSuccess && !isConfused && !isContinueSettings;

  const handleDeleted = () => {
    dispatch(setIsAppointmentDeleted(true));
    handleCloseModal();
  };

  const showTabs = isCreateAbsence || isCreateAppointment || isOpenAgenda;
  const handleChangeTab = (mode: ReserveSlotsMode) => {
    dispatch(setMode(mode));
    dispatch(setConfused(null));
  };

  useEffect(() => {
    return () => {
      dispatch(setHasUnsavedChanges(false));
      handleCloseModal();
    };
  }, []);

  const modalTitle = useMemo(() => {
    if (isCreateAppointment || isEditAppointment || isBookAgain) {
      return t('absenceModal.appointmentCreatedText');
    } else {
      return t('absenceModal.createdText');
    }
  }, [mode]);

  return (
    <>
      <Modal open={!!mode}>
        <>
          {showCreateModal && (
            <StyledModal>
              <Head isEdit={isEditMode}>
                <Title>
                  {title}
                  <StyledCloseIcon onClick={handleCloseModal} />
                </Title>
                {showTabs && (
                  <Tabs>
                    {isCanCreateAppointment && (
                      <Tab
                        isActive={isCreateAppointment}
                        onClick={() =>
                          handleChangeTab(ReserveSlotsMode.CreateAppointment)
                        }
                      >
                        <StyledPlusIcon /> {t('absenceModal.book_appointment')}
                      </Tab>
                    )}
                    {isCanCreateAbsence && (
                      <Tab
                        isActive={isCreateAbsence}
                        onClick={() =>
                          handleChangeTab(ReserveSlotsMode.CreateAbsence)
                        }
                      >
                        <StyledPlusIcon /> {t('absenceModal.create_absence')}
                      </Tab>
                    )}
                    {isCanManageWorkingHours && (
                      <Tab
                        isActive={isOpenAgenda}
                        onClick={() =>
                          handleChangeTab(ReserveSlotsMode.OpenAgenda)
                        }
                      >
                        {t('absenceModal.open_agenda')}
                      </Tab>
                    )}
                  </Tabs>
                )}
              </Head>
              {isPreviewAppointment && (
                <EventInfo
                  onCloseModal={handleCloseModal}
                  handleDeleted={handleDeleted}
                />
              )}
              {(isCreateAppointment || isEditAppointment || isBookAgain) && (
                <CreateAppointment
                  onClose={handleCloseModal}
                  setIsContinueSettings={setIsContinueSettings}
                />
              )}
              {(isCreateAbsence || isEditAbsence) && (
                <CreateAbsenceTab
                  onSaveWithConfuse={() => setIsContinueSettings(true)}
                  onClose={handleCloseModal}
                  onSave={handleSave}
                />
              )}
              {isOpenAgenda && <OpenAgenda onClose={handleCloseModal} />}
            </StyledModal>
          )}
          {!hasConfusingAgendas && isSuccess && (
            <SuccessModal onClose={handleCloseSuccessModal} />
          )}
          {isConfused && <WarningModal onClose={handleContinueSettings} />}
          {isContinueSettings && (
            <SuccessWithConfuseModal
              modalTitle={modalTitle}
              isAppointmentType={
                isEditAppointment || isCreateAppointment || isBookAgain
              }
              onCloseModal={handleSuccessConfuseModal}
            />
          )}
        </>
      </Modal>
      {isCreatedAppointment && !isConfused && !isContinueSettings && (
        <SuccessAppointmentCreated onClose={handleCloseModal} />
      )}
      {isEdited && !isConfused && !isContinueSettings && (
        <SuccessAppointmentEditing onClose={handleCloseModal} />
      )}
    </>
  );
};

export default ReserveSlotsModal;
