import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from 'common/hooks/redux';
import { fetchPatientAppointmentsBrief } from 'applicaiton/store/reducers/Appointments/ActionCreators';
import PatientEdit from 'features/feature-patient-edit';
import ReserveSlotsModal from 'features/feature-reserve-slots-modal';
import { AppointmentsTable, ProfileInfo } from './components';
import { Wrapper, Tabs, Tab } from './styles';
import SubscriptionsTable from 'features/feature-patient-profile/components/SubscriptionsTable';
import FamilyMembersTable from 'features/feature-patient-profile/components/FamilyMembersTable';
import { PatientNotFoundModal } from './components/PatientNotFoundModal';
import { usePatientProfile } from './hooks/usePatientProfile';
import { PathNames } from 'applicaiton/routes';

const PatientProfile: FC = () => {
  const { t } = useTranslation();
  const { id, tab = 'appointments' } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [activeTab, setActiveTab] = useState(tab);

  const handleCloseModal = async () => {
    await dispatch(fetchPatientAppointmentsBrief({ patientId: String(id) }));
  };
  const { patientIsNotFound, clearError } = usePatientProfile();

  const handleCloseErrorModal = () => {
    clearError();
    navigate(PathNames.myPatients);
  };

  return (
    <Wrapper>
      <h1>{t('patient_profile')}</h1>
      <ProfileInfo />
      <Tabs>
        <Tab
          isActive={activeTab === 'appointments'}
          onClick={() => setActiveTab('appointments')}
        >
          {t('appointments')}
        </Tab>
        <Tab
          isActive={activeTab === 'subscriptions'}
          onClick={() => setActiveTab('subscriptions')}
        >
          {t('professional_profile.subscriptions.subscription_title')}
        </Tab>
        <Tab
          isActive={activeTab === 'familyMembers'}
          onClick={() => setActiveTab('familyMembers')}
        >
          {t('family_members.family_members')}
        </Tab>
        {/* TODO: the next part will be needed later */}
        {/*<Tab isActive={activeTab === 2} onClick={() => setActiveTab(2)}>*/}
        {/*  {t('appointment.documents')}*/}
        {/*</Tab>*/}
      </Tabs>
      {activeTab === 'appointments' && <AppointmentsTable />}
      {activeTab === 'subscriptions' && <SubscriptionsTable />}
      {activeTab === 'familyMembers' && <FamilyMembersTable />}
      <ReserveSlotsModal onCloseModal={handleCloseModal} />
      <PatientEdit />
      <PatientNotFoundModal
        isOpen={patientIsNotFound}
        onClose={handleCloseErrorModal}
      />
    </Wrapper>
  );
};

export default PatientProfile;
