import React, { useMemo } from 'react';
import {
  AppointmentBriefResponseDto,
  AppointmentResponseDto,
} from '@docbay/schemas';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { ReactComponent as WarningIcon } from 'applicaiton/assets/warning.svg';
import { UserPermissions } from 'applicaiton/constants/userPermissions';
import { PathNames } from 'applicaiton/routes';

import { userHasPermission } from 'common/helpers/userHasPermission';

import { useUserTimeZone } from 'common/hooks/useUserTimeZone';

import {
  Wrapper,
  DateWrapper,
  Date,
  Name,
  Type,
  IconsWrapper,
  StyledCalendarIcon,
  StyledDeleteIcon,
} from './styles';

require('dayjs/locale/pt');
require('dayjs/locale/fr');

interface Props {
  data: AppointmentBriefResponseDto;
  onDelete: (
    value: AppointmentResponseDto | AppointmentBriefResponseDto | null,
  ) => void;
  moveToAppointment: (id: string) => void;
}

const AppointmentsItem = ({ data, onDelete, moveToAppointment }: Props) => {
  const { t } = useTranslation();
  const { tz } = useUserTimeZone();
  const location = useLocation();
  const isMyAgendaPage = location.pathname === PathNames.clinicOwnerSchedule;
  const isCanCancelAgenda = userHasPermission(
    UserPermissions.cancelAgenda,
    Number(data.clinic?.owner?.id),
    isMyAgendaPage,
  );
  const isSame = useMemo(() => {
    const diff = dayjs(dayjs(data?.startDate).format('YYYY-MM-DD')).diff(
      dayjs(dayjs(data?.endDate).format('YYYY-MM-DD')),
    );

    return diff === 0;
  }, [data]);

  const endDateFormatted = isSame
    ? dayjs.tz(data?.endDate, tz).format('HH:mm')
    : dayjs.tz(data?.endDate, tz).format('DD MMMM HH:mm');

  return (
    <Wrapper>
      <WarningIcon />
      <div>
        <DateWrapper>
          <Date>
            <span>{dayjs.tz(data?.startDate, tz).format('DD MMMM')}</span>{' '}
            {t('from')}{' '}
            <span>{dayjs.tz(data?.startDate, tz).format('HH:mm')}</span>{' '}
            {t('to')} <span>{endDateFormatted}</span>
          </Date>
          <IconsWrapper>
            <StyledCalendarIcon onClick={() => moveToAppointment(data.id)} />
            {isCanCancelAgenda && (
              <StyledDeleteIcon onClick={() => onDelete(data)} />
            )}
          </IconsWrapper>
        </DateWrapper>
        <Name>
          {data.patient?.firstName} {data.patient?.lastName}
        </Name>
        <Type>{data.appointmentType.title}</Type>
      </div>
    </Wrapper>
  );
};

export default AppointmentsItem;
