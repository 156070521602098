import React, { useEffect, useMemo, useState } from 'react';
import {
  AbsenceResponseDto,
  CommonEntitiesListResponse,
  ProfessionalResponseDto,
} from '@docbay/schemas';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useWatch } from 'react-hook-form';
import {
  Checkbox,
  Dropdown,
  Input,
  Loader,
  PrimaryButton,
  SecondaryButton,
} from 'common/components';
import {
  Content,
  InputWrapper,
  StyledDivider,
  ButtonsWrapper,
  WarningWrapper,
  WarningTitle,
  WarningText,
  WarningInfo,
  DropdownClinicsSection,
  ButtonSection,
} from './styles';
import { ReactComponent as WarningIcon } from 'applicaiton/assets/warning.svg';
import { ReactComponent as DeleteIcon } from 'applicaiton/assets/delete.svg';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { fetchConfusingAgenda } from 'applicaiton/store/reducers/ConfusingAgenda/ActionCreators';
import {
  createAbsence,
  getAbsenceById,
  updateAbsence,
} from 'applicaiton/store/reducers/Absence/ActionCreators';
import { userHasPermission } from 'common/helpers/userHasPermission';
import { UserPermissions } from 'applicaiton/constants/userPermissions';
import {
  refreshAgenda,
  setIsAddNewEvent,
} from 'applicaiton/store/reducers/Scheduler/SchedulerSlice';
import LeavePageModal from 'features/feature-edit-profile-settings/component/LeavePageModal';
import { useCreateAbsence } from 'features/feature-reserve-slots-modal/hooks/useCreateAbsence';
import { CreateAbsence } from 'features/feature-reserve-slots-modal/types/absence';
import { ReserveSlotsMode } from 'applicaiton/constants/reserveSlots';
import compareObjectsChanges from 'common/helpers/compareObjectsChanges';
import { setShowDeleteModal } from 'applicaiton/store/reducers/Absence/AbsenceSlice';
import {
  fetchProfessionalById,
  fetchProfessionals,
} from 'applicaiton/store/reducers/Professionals/ActionCreators';
import { getUserRole } from 'applicaiton/sessionStorage/auth';
import { useLocation } from 'react-router-dom';
import { PathNames } from 'applicaiton/routes';
import { UserRoles } from 'applicaiton/constants/userRoles';
import { ViewBy } from 'applicaiton/constants/scheduler';
import { Option } from 'common/components/DropdownProfessionals/models';
import { dateWithoutTimezone } from 'common/helpers/dateWithoutTimezone';
import { TimeZone } from 'applicaiton/constants/timeZone';
import { convertTimeZone } from 'common/helpers/convertTimeZone';
import { getDateInDefaultTimezone } from 'common/helpers/dateTimeHelper';
import DateSection from './DateSection';

const tz = TimeZone.Lisbon;

interface Props {
  onClose: () => void;
  onSave: (success: boolean, absenceData?: AbsenceResponseDto) => void;
  onSaveWithConfuse: (absenceData?: AbsenceResponseDto) => void;
}

const weekInMinutes = 10080;

const CreateAbsenceTab = ({ onClose, onSave, onSaveWithConfuse }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const userRole = getUserRole();
  const location = useLocation();
  const isMyAgendaPage = location.pathname === PathNames.clinicOwnerSchedule;
  const isUserRoleProfessional = userRole === UserRoles.professional;
  const { startDate, professionalId, absenceId, mode } = useAppSelector(
    (state) => state.appointmentsSlice,
  );
  const { confusingAgendas } = useAppSelector(
    (state) => state.confusingAgendas,
  );
  const { absence, isLoading } = useAppSelector((state) => state.absence);
  const { editedProfessional } = useAppSelector(
    (state) => state.professionalProfile,
  );
  const { viewBy, byAvailability, byProfessionals } = useAppSelector(
    (state) => state.scheduler,
  );
  const { isLoading: isLoadingProfessionals, professionals } = useAppSelector(
    (state) => state.professionals,
  );

  const currentViewData = useMemo(() => {
    return viewBy === ViewBy.byProfessionals ? byProfessionals : byAvailability;
  }, [viewBy, byAvailability, byProfessionals]);

  const {
    setValue,
    errors,
    handleSubmit,
    getValues,
    watch,
    register,
    setError,
    control,
  } = useCreateAbsence();
  const formData = useWatch({ control });
  const [clinicOwnerIdBySelectedClinic, setClinicOwnerIdBySelectedClinic] =
    useState<string | null>(null);
  const [isEveryWeek, setIsEveryWeek] = useState<boolean>(false);
  const [isDisabledCheckbox, setIsDisabledCheckbox] = useState<boolean>(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedProfessional, setSelectedProfessional] = useState<Option>();

  const canCreateAbsence = userHasPermission(
    UserPermissions.createAbsence,
    Number(clinicOwnerIdBySelectedClinic),
  );
  const isCanCancelAgenda = userHasPermission(
    UserPermissions.cancelAgenda,
    Number(absence?.clinic?.owner?.id),
    isMyAgendaPage,
  );

  const isEditMode = mode === ReserveSlotsMode.EditAbsence;

  const getConfusingAgenda = async () => {
    const { startDate, endDate, clinicId } = getValues();
    await dispatch(
      fetchConfusingAgenda({
        data: {
          startDate: dayjs(startDate).toDate(),
          endDate: dayjs(endDate).toDate(),
          professionalId: professionalId || '',
          ...(isMyAgendaPage ? {} : { clinicId: clinicId }),
        },
        isOnSave: false,
      }),
    );
  };

  useEffect(() => {
    const { startDate, endDate, clinicId } = getValues();
    if (startDate && endDate && clinicId) {
      const isValidDate =
        dayjs(startDate).isValid() && dayjs(endDate).isValid();
      if (isValidDate) {
        getConfusingAgenda();

        const diff = dayjs(endDate).diff(dayjs(startDate), 'm');
        setIsDisabledCheckbox(diff >= weekInMinutes);

        if (diff >= weekInMinutes) {
          setIsEveryWeek(false);
        }
      }
    }
  }, [watch('startDate'), watch('endDate'), watch('clinicId')]);

  useEffect(() => {
    if (professionalId) {
      dispatch(fetchProfessionalById(String(professionalId)));
    }
    if (currentViewData.clinicId) {
      setValue('clinicId', currentViewData.clinicId);
      getProfessionalsList(currentViewData.clinicId);
    }
    if (absenceId) {
      dispatch(getAbsenceById(absenceId));
    }
    if (startDate) {
      const isAfterStartDate = dayjs(convertTimeZone(new Date(), tz)).isAfter(
        dateWithoutTimezone(startDate),
      );
      const currentDate = isAfterStartDate
        ? dayjs.tz(dayjs(), tz)
        : dayjs(startDate);
      const currentDateMinute = currentDate.get('minute');
      const startDateAbsence = currentDate
        .set('minute', Math.ceil(currentDateMinute / 30) * 30)
        .toDate();
      const endDateAbsence = dayjs(startDateAbsence)
        .set('minute', dayjs(startDateAbsence).get('minute') + 10)
        .toDate();

      const currentStartDate = dayjs(
        getDateInDefaultTimezone(new Date(startDateAbsence || '')),
      ).toString();
      const currentEndDate = dayjs(
        getDateInDefaultTimezone(new Date(endDateAbsence || '')),
      ).toString();

      setValue(
        'startDate',
        isAfterStartDate
          ? currentStartDate
          : (dayjs
              .tz(dateWithoutTimezone(startDateAbsence), tz)
              .format() as any),
      );
      setValue(
        'endDate',
        isAfterStartDate
          ? currentEndDate
          : (dayjs.tz(dateWithoutTimezone(endDateAbsence), tz).format() as any),
      );
    }
  }, []);

  const getProfessionalsList = async (clinicIds: string) => {
    const response = await dispatch(
      fetchProfessionals({ clinicIds, onlyActive: true }),
    );
    if (response.meta.requestStatus === 'fulfilled') {
      const professionalsList =
        response.payload as CommonEntitiesListResponse<ProfessionalResponseDto>;

      const currentProfessional = professionalsList.entities.find(
        (professional) =>
          professional.id === absence?.professional?.id ||
          professional.id === professionalId,
      );
      if (currentProfessional) {
        setSelectedProfessional({
          label:
            currentProfessional.firstName + ' ' + currentProfessional.lastName,
          value: currentProfessional.id,
          image: currentProfessional.mainPhoto?.thumbnailUrl,
          specializations: currentProfessional.specializations || [],
        });
      }
    }
  };

  const professionalsOptions = useMemo(() => {
    return professionals.map((item) => ({
      value: String(item.id),
      label: item.firstName + ' ' + item.lastName,
      image: item.mainPhoto?.thumbnailUrl,
      specializations: item.specializations || [],
    }));
  }, [professionals]);

  useEffect(() => {
    if (absence) {
      if (watch('startDate')) return;
      setValue('clinicId', absence.clinic?.id || '');
      setValue('startDate', dayjs(absence.startDate).toString());
      setValue('endDate', dayjs(absence.endDate).toString());
      setValue('comment', absence.comment || '');
      setValue('reason', absence.reason || '');
      setIsEveryWeek(!!absence.isOriginalRepeatEveryWeek);
    }
  }, [absence]);

  const handleChangeRepeat = () => {
    setIsEveryWeek((prev) => !prev);
  };

  const clinicsOptions = useMemo(() => {
    if (!editedProfessional?.clinicsRelations?.length) return [];
    return editedProfessional?.clinicsRelations
      .filter((item) => item.clinic?.status === 'Active')
      .map((item) => ({
        label: String(item?.clinic?.name),
        value: String(item?.clinic?.id),
      }));
  }, [editedProfessional]);

  const handleSelectClinic = (clinicId: string) => {
    const selectedClinic = editedProfessional?.clinicsRelations?.find(
      (relation) => relation.clinic?.id === clinicId,
    );

    setClinicOwnerIdBySelectedClinic(selectedClinic?.clinic?.owner?.id || null);

    setValue('clinicId', clinicId, { shouldValidate: true });
  };

  const handleSave = async (values: CreateAbsence) => {
    const { startDate, endDate, clinicId, comment, reason } = values;
    if (isEditMode) {
      const data = {
        id: String(absenceId),
        startDate: dayjs(startDate).toDate(),
        endDate: dayjs(endDate).toDate(),
        clinicId,
        comment,
        reason,
        ...(!isDisabledCheckbox
          ? { isOriginalRepeatEveryWeek: isEveryWeek }
          : {}),
      };
      const response = await dispatch(updateAbsence(data));
      if (response.meta.requestStatus === 'fulfilled') {
        dispatch(setIsAddNewEvent(true));
        dispatch(refreshAgenda());
        onClose();
      }
    } else {
      const data = {
        startDate: dayjs(startDate).toDate(),
        endDate: dayjs(endDate).toDate(),
        professionalId: selectedProfessional?.value
          ? String(selectedProfessional?.value)
          : professionalId || '',
        clinicId,
        ...(comment.length ? { comment } : {}),
        ...(reason.length ? { reason } : {}),
        ...(isEveryWeek && !isDisabledCheckbox
          ? { isOriginalRepeatEveryWeek: isEveryWeek }
          : {}),
      };
      await dispatch(createAbsence(data)).then((data) => {
        const { absences, appointments } = data.payload.confusingAgenda;
        dispatch(setIsAddNewEvent(true));
        dispatch(refreshAgenda());

        const isBookedDuringCreation =
          (absences.length || appointments.length) &&
          !confusingAgendas?.absences.length &&
          !confusingAgendas?.appointments.length;

        if (isBookedDuringCreation) {
          onSave(false);
        } else {
          if (absences.length || appointments.length) {
            onSaveWithConfuse();
          } else {
            onSave(true);
          }
        }
      });
    }
  };

  const isStateChanged = useMemo(() => {
    if (dayjs(watch('startDate')).isAfter(dayjs(watch('endDate')))) {
      return false;
    }

    if (!isEditMode) return true;
    const defaultState = {
      clinicId: absence?.clinic?.id,
      comment: absence?.comment || '',
      endDate: dayjs(absence?.endDate).toString(),
      reason: absence?.reason || '',
      startDate: dayjs(absence?.startDate).toString(),
      isOriginalRepeatEveryWeek: absence?.isOriginalRepeatEveryWeek,
    };

    const hasChanges = compareObjectsChanges(
      { ...formData, isOriginalRepeatEveryWeek: isEveryWeek },
      defaultState,
    );

    return hasChanges;
  }, [isEditMode, formData, isEveryWeek, watch('startDate'), watch('endDate')]);

  const handleDelete = () => {
    dispatch(setShowDeleteModal(true));
  };
  const handleClose = () => {
    if (isStateChanged) {
      setShowModal(true);
    } else {
      onClose();
    }
  };

  const hasConfusingAgenda = useMemo(() => {
    const confusingEvents = [
      ...(confusingAgendas?.absences?.filter(
        (absence) => absence.id !== absenceId,
      ) || []),
      ...(confusingAgendas?.appointments || []),
    ];
    return !!confusingEvents.length;
  }, [confusingAgendas]);

  const hasError = !!Object.values(errors).length;

  return (
    <div
      className={`${isLoading || isLoadingProfessionals ? 'no-scroll' : ''}`}
    >
      {(isLoading || isLoadingProfessionals) && <Loader />}
      <LeavePageModal
        showModal={showModal}
        onClose={() => setShowModal(false)}
        onSubmit={onClose}
      />
      <form onSubmit={handleSubmit(handleSave)}>
        <Content>
          {!isUserRoleProfessional && !isMyAgendaPage && (
            <div>
              <Dropdown
                id={'professionals'}
                label={'Professionals'}
                placeholder={'Select Professional'}
                value={(selectedProfessional?.value as string) || ''}
                onChange={(value) => {
                  setSelectedProfessional(value as Option);
                }}
                options={professionalsOptions}
                disabled={isEditMode}
              />
            </div>
          )}
          {(isUserRoleProfessional || isMyAgendaPage) && (
            <DropdownClinicsSection>
              <Dropdown
                id={'clinics'}
                label={t('clinic') || ''}
                options={clinicsOptions}
                placeholder={t('all_clinics') || ''}
                value={watch('clinicId') || ''}
                onChange={(value) =>
                  !Array.isArray(value) &&
                  handleSelectClinic(String(value.value))
                }
                errorMessage={errors.clinicId?.message}
              />
            </DropdownClinicsSection>
          )}
          <DateSection
            watch={watch}
            setValue={setValue}
            setError={setError}
            errors={errors}
          />
          <div>
            <Checkbox
              id={'repeatEveryWeek'}
              checked={isEveryWeek}
              onChange={handleChangeRepeat}
              label={t('repeat_every_week') || ''}
              disabled={isDisabledCheckbox}
            />
          </div>
          {hasConfusingAgenda && (
            <WarningWrapper>
              <div>
                <WarningIcon />
              </div>
              <WarningInfo>
                <WarningTitle>{t('absenceModal.reserved')}</WarningTitle>
                <WarningText>{t('absenceModal.try_another')}</WarningText>
              </WarningInfo>
            </WarningWrapper>
          )}
          <StyledDivider />
          <div>
            <InputWrapper>
              <Input
                id="reason"
                label={t('reason') || ''}
                type="text"
                placeholder={t('reason_absence_placeholder') || ''}
                register={register}
              />
            </InputWrapper>
          </div>
          <div>
            <InputWrapper>
              <Input
                id="comment"
                label={t('comments') || ''}
                type="text"
                placeholder={t('comments_placeholder') || ''}
                register={register}
              />
            </InputWrapper>
          </div>
        </Content>
        <ButtonSection>
          <ButtonsWrapper>
            <SecondaryButton type="button" onClick={handleClose}>
              {t('cancel')}
            </SecondaryButton>
            {canCreateAbsence && (
              <PrimaryButton
                type="submit"
                disabled={hasError || !isStateChanged}
              >
                {t('save')}
              </PrimaryButton>
            )}
          </ButtonsWrapper>
          {isEditMode && isCanCancelAgenda && (
            <SecondaryButton type="button" onClick={handleDelete}>
              <DeleteIcon />
              {t('delete')}
            </SecondaryButton>
          )}
        </ButtonSection>
      </form>
    </div>
  );
};

export default CreateAbsenceTab;
