import styled from 'styled-components';

export const MenuItemStyled = styled.div`
  > a {
    text-decoration: none;
  }
  button {
    text-decoration: none;
    width: 100%;
    background: transparent;
    border-radius: 4px;
    border: none;
    text-align: start;
    cursor: pointer;
    padding: 0;
    margin: 0;
  }

  .menu_item {
    height: 44px;
    width: 100%;
    align-items: center;
    display: flex;
    border-radius: 4px;
    padding: 10px 12px;
    color: ${({ theme }) => theme.color.white};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.08px;

    :hover {
      background: ${({ theme }) => theme.color.navigatorHover};
      color: ${({ theme }) => theme.color.white};
    }

    > svg {
      position: absolute;
      margin-left: -2px;
      width: 24px;
      height: 24px;

      path {
        fill: ${({ theme }) => theme.color.white};
      }
    }
    &.stroke-icon {
      path {
        fill: unset;
        stroke: ${({ theme }) => theme.color.white};
      }
    }

    > p {
      margin-left: 31px;
    }
  }

  .active {
    .menu_item {
      border-radius: 4px;
      background: ${({ theme }) => theme.color.green100};
      color: ${({ theme }) => theme.color.menu};
      > svg {
        path {
          fill: ${({ theme }) => theme.color.menu};
        }
      }
      &.stroke-icon {
        path {
          fill: unset;
          stroke: ${({ theme }) => theme.color.menu};
        }
      }
    }
  }
`;

export const NotificationIcon = styled.div`
  position: absolute;

  > svg:last-child {
    position: absolute;
    top: 1px;
    right: 2px;
    width: 7px;
    height: 7px;
  }
`;
