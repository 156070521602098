import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TableStyled, Wrapper, ClinicWrapper } from './styles';
import { DropdownClinics, Loader, Pagination } from 'common/components';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { Option as ClinicOption } from 'common/components/DropdownClinics/models';
import WaitingAppointmentItem from '../WaitingAppointmentItem';
import { fetchAppointmentsWaitingList } from 'applicaiton/store/reducers/Appointments/ActionCreators';

const WaitingAppointmentsTable: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { waitingList, isLoading, totalPages } = useAppSelector(
    (state) => state.appointmentsSlice,
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [clinics, setClinics] = useState<ClinicOption[]>([]);
  const [clinicIds, setClinicIds] = useState<string[]>([]);

  const { isEdited } = useAppSelector((state) => state.appointmentsSlice);

  const getAppointmentsWaitingList = () => {
    dispatch(
      fetchAppointmentsWaitingList({
        page: 1,
        limit: 10,
        ...(clinics.length ? { clinicIds } : {}),
      }),
    );
  };

  useEffect(() => {
    getAppointmentsWaitingList();
  }, [clinics]);

  useEffect(() => {
    isEdited && getAppointmentsWaitingList();
  }, [isEdited]);

  const handleChangeClinic = (
    values: string | ClinicOption | ClinicOption[],
  ) => {
    if (Array.isArray(values) && values.length) {
      const clinicIds = values.map((item: ClinicOption) => {
        return String(item.value);
      });

      setClinicIds(clinicIds);
    } else {
      setClinicIds([]);
    }

    Array.isArray(values) && setClinics(values);
  };

  return (
    <Wrapper>
      {isLoading && <Loader />}

      <ClinicWrapper>
        <DropdownClinics
          value={clinics}
          onChange={(values) => {
            handleChangeClinic(values);
          }}
          isMulti={true}
        />
      </ClinicWrapper>

      <TableStyled>
        <thead>
          <tr>
            <th>
              <span>{t('waiting_list.table.patient')}</span>
            </th>
            <th>
              <span>{t('waiting_list.table.professional')}</span>
            </th>
            <th>
              <span>{t('waiting_list.table.appointment_type')}</span>
            </th>
            <th>
              <span>{t('waiting_list.table.scheduled')}</span>
            </th>
            <th>
              <span>{t('waiting_list.table.available_slots')}</span>
            </th>
            <th>
              <span>{t('waiting_list.table.actions')}</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {!!waitingList?.length &&
            waitingList?.map((appointment) => (
              <WaitingAppointmentItem
                key={`${appointment.id}`}
                appointment={appointment}
              />
            ))}
          {!waitingList?.length && (
            <tr>
              <td colSpan={6} className={'noResults'}>
                {t('no_results')}
              </td>
            </tr>
          )}
        </tbody>
      </TableStyled>
      {waitingList?.length && totalPages > 1 ? (
        <Pagination
          pages={totalPages}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          itemCount={waitingList.length}
        />
      ) : (
        ''
      )}
    </Wrapper>
  );
};

export default WaitingAppointmentsTable;
