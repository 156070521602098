import styled from 'styled-components';
import { commonColors } from '../../../../applicaiton/theme';

export const HoursSection = styled.div`
  display: flex;
  border-radius: 8px;
  border: 1px solid ${commonColors.grey300};
  padding: 20px;
  gap: 16px;
  background: ${commonColors.grey50};
  margin-top: 24px;
`;
