import styled from 'styled-components';

interface ItemStyledProps {
  isStrokeSvg?: boolean;
}

export const Wrapper = styled.article`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  border-radius: 12px;
  background: ${({ theme }) => theme.color.white};

  > button {
    height: 36px;
    width: fit-content;
    svg {
      path {
        fill: ${({ theme }) => theme.color.blue800};
      }
    }
  }
`;

export const ItemsList = styled.section`
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 12px;
  gap: 12px 8px;
  border: 1px solid ${({ theme }) => theme.color.grey300};
  box-shadow: 1px 1px 0px 0px rgba(11, 37, 80, 0.17);
`;

export const ItemStyled = styled.div<ItemStyledProps>`
  display: flex;
  align-items: center;
  min-width: 400px;
  max-width: 400px;

  span {
    color: ${({ theme }) => theme.color.grey500};
    font-size: 16px;
    font-weight: 500;
    line-height: 22.4px;
    letter-spacing: 0.08px;
    margin: 0 6px 0 8px;
  }

  p {
    color: ${({ theme }) => theme.color.grey700};
    font-size: 16px;
    font-weight: 500;
    line-height: 22.4px;
    letter-spacing: 0.08px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  svg {
    min-width: 20px;
    height: 20px;
    path {
      fill: ${({ theme, isStrokeSvg }) =>
        !isStrokeSvg ? theme.color.grey600 : ''};
      stroke: ${({ theme, isStrokeSvg }) =>
        isStrokeSvg ? theme.color.grey600 : ''};
    }
  }
`;
