import styled from 'styled-components';

interface Props {
  fullWidth?: boolean;
}

export const Row = styled.div<Props>`
  display: grid;
  grid-template-columns: ${({ fullWidth }) => (fullWidth ? '1fr' : '1fr 1fr')};
  gap: 2rem;
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 32px;
    margin-top: 64px;
  }
`;

export const RadioButtonSection = styled.section`
  display: grid;
  width: 100%;
  gap: 12px;
  margin-bottom: 24px;

  > p {
    color: ${({ theme }) => theme.color.grey600};
    font-size: 16px;
    font-weight: 500;
    line-height: 22.4px;
  }

  > span {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.07px;
    color: ${({ theme }) => theme.color.error2};
  }

  > div {
    display: flex;
    align-items: center;

    div:last-child {
      margin-left: 16px;
    }
  }
`;
