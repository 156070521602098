import styled from 'styled-components';
import { ReactComponent as PlusIcon } from 'applicaiton/assets/plus.svg';

interface ErrorProps {
  isError?: boolean;
}

interface LoadingProps {
  isLoading?: boolean;
}

export const AddDocument = styled.div<LoadingProps>`
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  > div {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: ${({ theme }) => theme.color.blue800};
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.08px;

    > label {
      position: absolute;
      opacity: 0;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      border-radius: 8px;
      cursor: ${({ isLoading }) => (isLoading ? 'default' : 'pointer')};
    }
    > input {
      display: none;
    }

    span {
      color: ${({ theme }) => theme.color.grey500};
      font-size: 12px;
      font-weight: 400;
      line-height: 12px;
    }

    > svg {
      margin-right: 4px;
      path {
        fill: ${({ theme }) => theme.color.blue800};
      }
    }
  }

  .disabled {
    opacity: 0.5;
    &:hover {
      cursor: default;
    }
  }
`;

export const StyledPlusIcon = styled(PlusIcon)`
  display: flex;
  align-items: center;
  margin-right: 4px;
`;

export const UploadingProgress = styled.div`
  padding: 12px;
  margin-bottom: 20px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.color.grey300};
  background: ${({ theme }) => theme.color.white};
  display: flex;
  align-items: center;
`;

export const UploadingText = styled.div<ErrorProps>`
  margin-left: 12px;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  color: ${({ theme, isError }) =>
    isError ? theme.color.error1 : theme.color.grey900};

  & p:last-child {
    font-weight: 400;
    color: ${({ theme, isError }) =>
      isError ? theme.color.error1 : theme.color.grey700};
  }
`;

export const Loader = styled.div<ErrorProps>`
  display: flex;
  width: 44px;
  height: 44px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: ${({ theme, isError }) =>
    isError ? theme.color.error3 : theme.color.blue100};

  svg {
    animation: ${({ isError }) =>
      isError ? 'none' : 'rotation 3s infinite linear'};
    color: ${({ theme, isError }) =>
      isError ? theme.color.error1 : theme.color.grey700};

    @keyframes rotation {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(359deg);
      }
    }
  }
`;
