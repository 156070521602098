import styled from 'styled-components';

interface StyledProps {
  isActive: boolean;
}

export const Wrapper = styled.aside`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  background: ${({ theme }) => theme.color.grey50};
  > section {
    display: grid;
    justify-content: center;
    grid-template-rows: max-content max-content max-content;
    gap: 12px;
    margin-bottom: 32px;
  }
  .MuiDateCalendar-root {
    width: 277px;
  }
`;

export const FiltersCount = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  > p {
    color: ${({ theme }) => theme.color.grey700};
    font-size: 14px;
    font-weight: 500;
    line-height: 19.6px;
    letter-spacing: 0.25px;
  }
  > button {
    height: fit-content;
    width: fit-content;
    border: 0;
    box-shadow: none;
    padding: 0;
    :hover {
      background-color: transparent;
    }
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  width: 281px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.color.grey300};
  box-shadow: 1px 1px 2px 0px rgba(54, 54, 54, 0.1);
`;

export const ButtonStyled = styled.button<StyledProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 10px;
  border: none;
  background: ${({ theme, isActive }) =>
    isActive ? theme.color.blue100 : theme.color.white};
  cursor: pointer;

  color: ${({ theme }) => theme.color.grey900};
  font-size: 14px;
  font-weight: ${({ isActive }) => (isActive ? '600' : '500')};
  line-height: 22.4px;

  &:hover {
    background: ${({ theme }) => theme.color.grey100};
  }

  &:first-child {
    min-width: fit-content;
    border-right: 1px solid ${({ theme }) => theme.color.grey300};
    border-radius: 8px 0 0 8px;
  }
  &:last-child {
    min-width: fit-content;
    flex: 1;
    border-radius: 0 8px 8px 0;
  }
`;

export const ProfessionalSection = styled.div`
  display: flex;
  flex-direction: column;
  > p {
    color: ${({ theme }) => theme.color.grey700};
    font-size: 14px;
    font-weight: 700;
    line-height: 19.6px;
    letter-spacing: 0.25px;
  }
`;

export const CheckboxList = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 272px;
  overflow-y: auto;
  margin-top: 4px;
`;

export const CheckboxStyled = styled.div<StyledProps>`
  > div {
    input + label:before {
      width: 16px;
      height: 16px;
    }
    input + label:after {
      width: 18px;
      height: 18px;
      top: -1px;
    }
    &:first-child {
      margin-top: 8px;
    }
    > label {
      color: ${({ theme }) => theme.color.grey800};
      font-family: Mulish;
      font-size: 14px;
      font-style: normal;
      font-weight: ${({ isActive }) => (isActive ? '700' : '400')};
      letter-spacing: 0.07px;
    }
  }
`;
