import styled from 'styled-components';

interface Props {
  isError?: boolean;
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: ${({ theme }) => theme.color.white};
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.color.grey300};
`;

export const DataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 32px;
`;

export const DataSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  > div:first-of-type {
    border-right: 1px solid ${({ theme }) => theme.color.grey300};
  }

  h3 {
    font-family: Mulish;
    font-weight: 700;
    font-size: 18px;
    line-height: 25.2px;
  }
`;

export const Information = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 32px;
  gap: 24px;
`;

export const Metrics = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 32px;
  gap: 24px;
`;

export const MetricsWrapper = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  gap: 20px;

  > div {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
`;

export const MetricItem = styled.div<Props>`
  display: flex;
  align-items: flex-end;
  gap: 8px;

  > span {
    font-family: Mulish;
    font-weight: 500;
    font-size: 16px;
    line-height: 44px;
    color: ${({ theme }) => theme.color.grey500};
  }

  input {
    width: 152px;
  }

  &.blood_pressure {
    display: flex;
    gap: 6px;
    flex-direction: column;
    align-items: flex-start;

    input {
      width: 64px;
    }

    label {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 140%;
      letter-spacing: 0.005em;
      color: ${({ theme, isError }) =>
        isError ? theme.color.error2 : theme.color.grey600};
    }

    > div {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
`;

export const EditorsSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  > div:first-of-type {
    padding-right: 20px;
  }

  > div:last-of-type {
    padding-left: 20px;
  }
`;

export const EditorWrapper = styled.div`
  h3 {
    font-family: Mulish;
    font-weight: 700;
    font-size: 18px;
    line-height: 25.2px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-top: 16px;
  padding-top: 32px;
  border-top: 1px solid #d0d5dd;

  button {
    width: 150px;

    &:last-child {
      margin-left: 20px;
    }
  }
`;
