import React, { FC } from 'react';
import { FooterStyled } from './styles';
import { Link } from 'react-router-dom';
import { Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';

const Footer: FC = () => {
  const { t } = useTranslation();

  return (
    <FooterStyled>
      <p>{t('clinicRegistration.footer.copyright')}</p>
      <div>
        <Link to={'#'}>{t('clinicRegistration.footer.privacy')}</Link>
        <Divider orientation="vertical" />
        <Link to={'#'}>{t('clinicRegistration.footer.termsAndConditons')}</Link>
      </div>
    </FooterStyled>
  );
};

export default Footer;
