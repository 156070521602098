import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs, { Dayjs } from 'dayjs';

import { TimeZone } from 'applicaiton/constants/timeZone';

import { DatePicker, TimePicker } from 'common/components';
import { convertTimeZone } from 'common/helpers/convertTimeZone';
import { getDateInDefaultTimezone } from 'common/helpers/dateTimeHelper';

import { DateSectionProps } from './models';
import {
  DatePickersSection,
  TimePickerWrapper,
  TimePickersSection,
} from './styles';

const tz = TimeZone.Lisbon;

const DateSection: FC<DateSectionProps> = ({
  watch,
  setValue,
  setError,
  errors,
}) => {
  const { t } = useTranslation();

  const getUpdatedDate = (date: Dayjs, val: string | null) => {
    return date
      .year(dayjs(val).get('year'))
      .month(dayjs(val).get('month'))
      .date(dayjs(val).get('date'));
  };

  const handleStartDate = (val: string | null, isDatePicker?: boolean) => {
    const isValidDate = dayjs(val).isValid();
    if (isValidDate) {
      const startDate = dayjs(watch('startDate'));
      const currentStartDate = dayjs(dayjs()).isAfter(dayjs(val))
        ? dayjs().tz(tz)
        : dayjs(val).tz(tz);

      const newDate = getUpdatedDate(startDate, val);
      const currentDate = convertTimeZone(startDate.toDate(), tz);
      const newEndDate = dayjs
        .tz(val, tz)
        .hour(dayjs(currentDate).get('hour'))
        .minute(dayjs(currentDate).get('minute'));
      const isStartDateValid = currentStartDate.isValid();

      if (isDatePicker) {
        setValue('startDate', isStartDateValid ? newEndDate.toString() : '', {
          shouldValidate: !!errors.startDate?.message,
        });
      } else {
        const startDate = dayjs(getDateInDefaultTimezone(new Date(val || '')));
        setValue('startDate', dayjs(startDate).toString(), {
          shouldValidate: !!errors.endDate?.message,
        });
      }

      // SET END DATE IF DATE AFTER END DATE
      if (dayjs(dayjs(val)).isAfter(watch('endDate'))) {
        setValue('endDate', newDate.toString());
      }
    }
  };

  const handleEndDate = (val: string | null, isDatePicker?: boolean) => {
    const isValidDate = dayjs(val).isValid();
    if (isValidDate) {
      const startDate = watch('startDate');
      const isEndDateAfterStartDate = dayjs(dayjs(val)).isAfter(
        dayjs(startDate),
      );
      const isEndDateValid = dayjs(val).isValid();

      if (isEndDateValid) {
        if (isDatePicker) {
          const endDate = dayjs(watch('endDate'));
          const currentEndDate = convertTimeZone(endDate.toDate(), tz);

          const newEndDate = dayjs
            .tz(val, tz)
            .hour(dayjs(currentEndDate).get('hour'))
            .minute(dayjs(currentEndDate).get('minute'));

          setValue('endDate', newEndDate.toString(), {
            shouldValidate:
              isEndDateAfterStartDate && !!errors.endDate?.message,
          });
        } else {
          const endDate = dayjs(getDateInDefaultTimezone(new Date(val || '')));

          setValue('endDate', dayjs(endDate).toString(), {
            shouldValidate:
              isEndDateAfterStartDate && !!errors.endDate?.message,
          });
        }

        if (!isEndDateAfterStartDate)
          setError('endDate', {
            message: t('errors.end_date_must_be_after_start_date')!,
          });
      } else {
        setValue('endDate', '', {
          shouldValidate: !!errors.endDate?.message,
        });
      }
    }
  };

  const minAbsenceEndTime = useMemo(() => {
    const startDateInTz = getDateInDefaultTimezone(
      dayjs(watch('startDate')).tz(tz).toDate(),
    );

    const endDate = dayjs(watch('endDate')).tz(tz);
    const newMin = startDateInTz.add(10, 'minute');

    const dayDiff = endDate.dayOfYear() > startDateInTz.dayOfYear();
    const yearDiff = endDate.year() > startDateInTz.year();
    const currentHour = dayDiff || yearDiff ? 0 : dayjs(newMin).get('hours');
    const currentMinute =
      dayDiff || yearDiff ? 0 : dayjs(newMin).get('minutes');
    const newEndDate = endDate.hour(currentHour).minute(currentMinute);

    return newEndDate;
  }, [watch('startDate'), watch('endDate')]);

  const minAbsenceStartTime = useMemo(() => {
    if (
      dayjs(dayjs(watch('startDate')).tz(tz)).format('DD') ===
      dayjs().format('DD')
    ) {
      return dayjs.tz(dayjs().toDate(), tz);
    } else {
      return null;
    }
  }, [watch('startDate')]);

  const getDayjsFromStringForTimePicker = (date: string) => {
    const dayInTz = dayjs.tz(date, tz);
    return dayInTz.isUTC() ? dayInTz : dayjs(date).tz(tz);
  };

  return (
    <div>
      <DatePickersSection>
        <DatePicker
          id={'dateStart'}
          label={t('date_start') || ''}
          format={'YYYY-MM-DDTHH:mm'}
          value={dayjs(
            convertTimeZone(new Date(watch('startDate')), tz),
          ).format('YYYY-MM-DDTHH:mm')}
          onChange={(value) => {
            handleStartDate(value, true);
          }}
          maxDate={new Date(
            new Date().setFullYear(new Date().getFullYear() + 3),
          ).getTime()}
          minDate={Date.now()}
          errorMessage={errors.startDate?.message}
        />
        <DatePicker
          id={'endDate'}
          label={t('date_end') || ''}
          format={'YYYY-MM-DDTHH:mm'}
          value={dayjs(convertTimeZone(new Date(watch('endDate')), tz))
            .set('hour', 12)
            .format('YYYY-MM-DDTHH:mm')}
          onChange={(value) => {
            handleEndDate(value, true);
          }}
          maxDate={new Date(
            new Date().setFullYear(new Date().getFullYear() + 3),
          ).getTime()}
          minDate={dayjs(watch('startDate')).valueOf()}
          errorMessage={errors.endDate?.message}
        />
      </DatePickersSection>
      <TimePickersSection>
        <TimePickerWrapper>
          <label>{t('time_start')}:</label>
          <TimePicker
            key="startDate"
            defaultValue={dayjs(watch('startDate'))}
            value={getDayjsFromStringForTimePicker(watch('startDate'))}
            onChange={(value) => {
              handleStartDate(value);
            }}
            minTime={minAbsenceStartTime}
            timeStepsMinutes={10}
            disableIgnoringDatePartForTimeValidation={false}
            isError={false}
          />
        </TimePickerWrapper>
        <TimePickerWrapper>
          <label>{t('time_end')}:</label>
          <TimePicker
            key="endDate"
            defaultValue={dayjs(watch('endDate'))}
            value={getDayjsFromStringForTimePicker(watch('endDate'))}
            onChange={(value) => {
              handleEndDate(value);
            }}
            timeStepsMinutes={10}
            disableIgnoringDatePartForTimeValidation={false}
            minTime={minAbsenceEndTime}
            isError={false}
          />
        </TimePickerWrapper>
      </TimePickersSection>
    </div>
  );
};

export default DateSection;
