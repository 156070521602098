import { DocumentActionDto } from '@docbay/schemas';
import { AppointmentDocumentsApi } from 'integration/api/documents';
import { ChatsAPI } from 'integration/api/chats';

export const useDocumentDownload = () => {
  const downloadDocument = async (params: DocumentActionDto | string) => {
    try {
      const response =
        typeof params !== 'string'
          ? await AppointmentDocumentsApi.downloadDocument(
              params as DocumentActionDto,
            )
          : null;
      const responseChat =
        typeof params === 'string'
          ? await ChatsAPI.downloadDocument(params as string)
          : null;

      const currentResponse = response ? response : responseChat;

      const contentDisposition =
        currentResponse?.headers['content-disposition'];
      let fileName = 'downloaded_file';
      if (contentDisposition) {
        const matches = /filename="([^"]+)"/.exec(contentDisposition);
        if (matches && matches[1]) {
          fileName = matches[1];
        }
      }

      const downloadUrl = window.URL.createObjectURL(
        new Blob([currentResponse?.data]),
      );
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (e) {
      console.log('Download document error: ', e);
    }
  };

  return downloadDocument;
};
