import { useTranslation } from 'react-i18next';
import { PathNames } from 'applicaiton/routes/constants';

export const useUsersList = () => {
  const { t } = useTranslation();

  const list = [
    {
      path: PathNames.professionals,
      name: t('clinicsConfiguration.aside_menu.doctors'),
    },
    {
      path: PathNames.myUsers,
      name: t('clinicsConfiguration.aside_menu.admin_staff'),
    },
  ];

  return list;
};
