import * as yup from 'yup';
import { useForm, useFormState } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { nifValidate } from 'common/helpers/nifValidate';

export enum Gender {
  Male = 'Male',
  Female = 'Female',
}

export interface PatientFieldsT {
  gender: Gender;
  firstName: string | null;
  lastName: string | null;
  relationship: string;
  dateOfBirth: string | null;
  fin: string;
  ssn: string;
}

export const useCreateFamilyMember = ({
  requiredError,
  numberError,
  lengthFinError,
  lengthSsnError,
}: {
  requiredError: string;
  emailError: string;
  numberError: string;
  lengthFinError: string;
  lengthSsnError: string;
}) => {
  const formValues = {
    gender: Gender.Male,
    firstName: null,
    lastName: null,
    relationship: '',
    dateOfBirth: null,
    fin: '',
    ssn: '',
  };

  const patientSchema = yup
    .object({
      gender: yup.string(),
      firstName: yup.string().required(requiredError!).nullable(),
      lastName: yup.string().required(requiredError!).nullable(),
      relationship: yup.string().required(requiredError!),
      dateOfBirth: yup.string().required(requiredError!).nullable(),
      fin: yup.string().test({
        name: 'nif',
        test(value, ctx) {
          if (!value || value?.length === 0) return true;

          const isNum = nifValidate(value);

          if (value?.length !== 9) {
            return ctx.createError({ message: lengthFinError });
          } else if (!isNum) {
            return ctx.createError({ message: numberError });
          }
          return isNum;
        },
      }),
      ssn: yup.string().test({
        name: 'socialSecurityNumber',
        test(value, ctx) {
          if (!value || value?.length === 0) return true;

          const isNum = /^\d+$/.test(value!);

          if (!isNum) {
            return ctx.createError({ message: numberError });
          } else if (value?.length !== 11) {
            return ctx.createError({ message: lengthSsnError });
          }

          return isNum;
        },
      }),
    })
    .required();

  const {
    watch: watchFM,
    getValues: getValuesFM,
    setValue: setValueFM,
    register: registerFM,
    handleSubmit: handleSubmitFM,
    clearErrors: clearErrorsFM,
    setError: setErrorFM,
    control,
    formState: { errors: errorsFM },
    reset: resetFm,
  } = useForm<PatientFieldsT>({
    resolver: yupResolver(patientSchema),
    defaultValues: formValues,
  });

  const { isDirty } = useFormState({
    control,
  });

  return {
    watchFM,
    getValuesFM,
    setValueFM,
    errorsFM,
    registerFM,
    handleSubmitFM,
    clearErrorsFM,
    setErrorFM,
    isDirtyFM: isDirty,
    resetFm,
  };
};
