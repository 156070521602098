import React, { useEffect, useMemo, useState } from 'react';
import {
  ButtonWrapper,
  Head,
  StyledCloseIcon,
  StyledModal,
  Title,
  RightButtons,
  StyledEditIcon,
  StyledDeleteIcon,
  BookAgain,
  TeleconsultationButton,
} from './styles';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import { SecondaryButton, PrimaryButton } from 'common/components';
import { userHasPermission } from 'common/helpers/userHasPermission';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import {
  setIsEndedAppointment,
  setSlotsDetails,
} from 'applicaiton/store/reducers/Appointments/AppoinmentsSlice';
import { UserPermissions } from 'applicaiton/constants/userPermissions';
import {
  deleteAppointment,
  endAppointment,
  fetchAppointmentById,
} from 'applicaiton/store/reducers/Appointments/ActionCreators';
import { PathNames } from 'applicaiton/routes';
import { MeetingPlace } from 'applicaiton/store/reducers/AppointmentTypes/AppointmentTypesSlice';
import EventInformation from '../EventInformation';
import DeleteModal from '../DeleteModal';
import SuccessDeletedModal from '../SuccessDeletedModal';
import { Tab, Tabs } from './styles';
import { ReserveSlotsMode } from 'applicaiton/constants/reserveSlots';
import EventEditDocuments from '../EventEditDocuments';
import { getUserId, getUserRole } from 'applicaiton/sessionStorage/auth';
import { UserRoles } from 'applicaiton/constants/userRoles';
import ConfirmEndAppointment from '../ConfirmEndAppointment';
import SuccessAppointmentEnded from '../SuccessAppointmentEnded';

const EventInfo = ({
  onCloseModal,
  handleDeleted,
}: {
  onCloseModal: () => void;
  handleDeleted: () => void;
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const userRole = getUserRole();
  const userId = getUserId();
  const isClinicOwnerRole = userRole?.includes(UserRoles.clinicOwner);
  const isMyAgendaPage = location.pathname === PathNames.clinicOwnerSchedule;
  const [activeTab, setActiveTab] = useState(0);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openConfirmEndAppointmentDialog, setOpenConfirmEndAppointmentDialog] =
    useState(false);

  const {
    isShowDeletedWindow,
    currentAppointment,
    appointmentId,
    isEndedAppointment,
  } = useAppSelector((state) => state.appointmentsSlice);
  const { isLoading } = useAppSelector(
    (state) => state.appointmentDocumentsSlice,
  );
  const clinicOwnerId = useMemo(() => {
    const ownerId = currentAppointment?.clinic?.owner?.id;
    if (ownerId && ownerId !== userId) {
      return Number(currentAppointment?.clinic?.owner?.id);
    }
    return undefined;
  }, [currentAppointment?.clinic?.owner?.id])
    ? Number(currentAppointment?.clinic?.owner?.id)
    : undefined;
  const isCanCancelAgenda = userHasPermission(
    UserPermissions.cancelAgenda,
    clinicOwnerId,
    isMyAgendaPage,
  );
  const isCanReschedule = userHasPermission(
    UserPermissions.rescheduleAppointment,
    clinicOwnerId,
    isMyAgendaPage,
  );

  const isPastAppointment = useMemo(() => {
    return !dayjs().isBefore(currentAppointment?.startDate);
  }, [currentAppointment]);

  const cancelAppointment = () => {
    dispatch(deleteAppointment(String(appointmentId)));
  };

  useEffect(() => {
    if (appointmentId) {
      dispatch(fetchAppointmentById(String(appointmentId)));
    }
  }, [appointmentId]);

  const handleBookAgain = () => {
    dispatch(
      setSlotsDetails({
        appointmentId,
        mode: ReserveSlotsMode.BookAgain,
        startDate: dayjs().toDate(),
      }),
    );
  };

  const handleEditAppointment = () => {
    dispatch(
      setSlotsDetails({
        appointmentId,
        mode: ReserveSlotsMode.EditAppointment,
      }),
    );
  };

  const getCurrentTab = () => {
    switch (activeTab) {
      case 0:
        return <EventInformation />;
      case 1:
        return <EventEditDocuments />;
    }
  };

  const showTeleconsultationButton = useMemo(() => {
    if (!currentAppointment || (!isMyAgendaPage && isClinicOwnerRole))
      return false;
    const tenMinutesInSeconds = 600;
    const isTeleconsultation =
      currentAppointment.appointmentType.meetingPlace ===
      MeetingPlace.Teleconsultation;
    const eventStartInSeconds = dayjs(currentAppointment.startDate).diff(
      dayjs(),
      'second',
    );
    const eventEndInSeconds = dayjs(currentAppointment.endDate).diff(
      dayjs(),
      'second',
    );

    const isTimeToShow =
      eventStartInSeconds < tenMinutesInSeconds && eventEndInSeconds > 0;

    return isTeleconsultation && isTimeToShow;
  }, [currentAppointment]);

  const handleConfirmEndAppointment = () => {
    dispatch(endAppointment(appointmentId!));
  };

  const handleCloseConfirmEndAppointment = () => {
    dispatch(setIsEndedAppointment(false));
    setOpenConfirmEndAppointmentDialog(false);
    onCloseModal();
  };

  return (
    <>
      <StyledModal>
        <Head>
          <Title>
            <label>{t('absenceModal.appointment_details')}</label>
            <StyledCloseIcon
              onClick={(e: Event) => {
                e.stopPropagation();
                onCloseModal();
              }}
            />
          </Title>
          <Tabs>
            <Tab isActive={activeTab === 0} onClick={() => setActiveTab(0)}>
              {t('appointment.information')}
            </Tab>
            <Tab isActive={activeTab === 1} onClick={() => setActiveTab(1)}>
              {t('appointment.documents')}
            </Tab>
          </Tabs>
        </Head>
        {getCurrentTab()}
        <ButtonWrapper>
          {showTeleconsultationButton && (
            <TeleconsultationButton>
              <Link
                to={`${PathNames.teleconsultation}/${currentAppointment?.id}`}
                target="_blank"
              >
                <PrimaryButton>
                  {t('appointment.join_teleconsultation')}
                </PrimaryButton>
              </Link>
            </TeleconsultationButton>
          )}
          {isPastAppointment ? (
            isCanReschedule && (
              <BookAgain>
                {currentAppointment?.isBillable && (
                  <SecondaryButton
                    onClick={() => setOpenConfirmEndAppointmentDialog(true)}
                  >
                    {t('appointment.end_appointment')}
                  </SecondaryButton>
                )}
                <PrimaryButton onClick={handleBookAgain}>
                  {t('appointment.book_again')}
                </PrimaryButton>
              </BookAgain>
            )
          ) : (
            <RightButtons>
              {isCanCancelAgenda && (
                <SecondaryButton
                  onClick={() => setOpenConfirmDialog(true)}
                  disabled={isLoading}
                >
                  <StyledDeleteIcon />
                  {t('cancel')}
                </SecondaryButton>
              )}
              {isCanReschedule && (
                <SecondaryButton
                  onClick={handleEditAppointment}
                  disabled={isLoading}
                >
                  <StyledEditIcon />
                  {t('edit')}
                </SecondaryButton>
              )}
            </RightButtons>
          )}
        </ButtonWrapper>
      </StyledModal>
      <DeleteModal
        isOpen={openConfirmDialog}
        patientName={`${currentAppointment?.patient!.firstName} ${
          currentAppointment?.patient!.lastName
        }`}
        startDate={currentAppointment?.startDate!}
        onClose={() => setOpenConfirmDialog(false)}
        onSubmit={cancelAppointment}
      />
      {isShowDeletedWindow && (
        <SuccessDeletedModal
          onClose={handleDeleted}
          patientName={`${currentAppointment?.patient!.firstName} ${
            currentAppointment?.patient!.lastName
          }`}
        />
      )}
      <ConfirmEndAppointment
        isOpen={openConfirmEndAppointmentDialog}
        onClose={() => setOpenConfirmEndAppointmentDialog(false)}
        onSubmit={handleConfirmEndAppointment}
      />
      {isEndedAppointment && (
        <SuccessAppointmentEnded onClose={handleCloseConfirmEndAppointment} />
      )}
    </>
  );
};

export default EventInfo;
