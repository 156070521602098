import { Dayjs } from 'dayjs';

export const getDateValidation = (date: Dayjs, dateToCompare: Dayjs) => {
  const isBefore =
    date.dayOfYear() - dateToCompare.dayOfYear() < 7 &&
    date.dayOfYear() - dateToCompare.dayOfYear() > 0;
  const isAfter =
    dateToCompare.dayOfYear() - date.dayOfYear() < 7 &&
    dateToCompare.dayOfYear() - date.dayOfYear() > 0;

  return {
    isBefore,
    isAfter,
  };
};
