import React, { FC, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ReactComponent as ExpandMoreIcon } from 'applicaiton/assets/expand-more.svg';
import { ReactComponent as ExpandLessIcon } from 'applicaiton/assets/expand-less.svg';
import { ReactComponent as PersonIcon } from 'applicaiton/assets/person.svg';
import { AccordionStyled } from './styles';
import { PathNames } from 'applicaiton/routes';
import MenuItem from '../MenuItem';
import { useTranslation } from 'react-i18next';

interface Item {
  path: string;
  name: string;
}

interface AccordionProps {
  options: Item[];
  isExpanded: boolean;
  handleExpandMenu: () => void;
}

const AccordionUsers: FC<AccordionProps> = ({
  options,
  isExpanded,
  handleExpandMenu,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const isUsersActive = location.pathname?.includes(PathNames.users);
  const [showList, setShowList] = useState(isUsersActive);
  const isMenuItemActive =
    (isUsersActive && !showList) || (!isExpanded && isUsersActive);

  return (
    <AccordionStyled>
      <div
        className={`accordion-root ${isMenuItemActive ? 'active' : ''}`}
        onClick={() => {
          setShowList((prev) => (!isExpanded ? true : !prev));
          handleExpandMenu();
        }}
      >
        <div className="accordion-title">
          <PersonIcon />
          <p>{t('clinicsConfiguration.aside_menu.users')}</p>
        </div>
        <div className="accordion-expand">
          {!showList ? <ExpandMoreIcon /> : <ExpandLessIcon />}
        </div>
      </div>
      {showList && isExpanded && (
        <div className="accordion-list">
          {options.map((item, index) => (
            <MenuItem key={index} name={item.name} path={item.path} />
          ))}
        </div>
      )}
    </AccordionStyled>
  );
};
export default AccordionUsers;
