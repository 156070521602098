import { ComponentType } from 'react';
import { styled } from '@mui/system';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { DayPickersProps } from './modules';

export const DayPickers = styled(PickersDay, {
  shouldForwardProp: (prop) =>
    prop !== 'dayIsBetween' &&
    prop !== 'isFirstDay' &&
    prop !== 'isLastDay' &&
    prop !== 'isToday',
})<DayPickersProps>(
  ({ theme, dayIsBetween, isFirstDay, isLastDay, isToday }) => {
    return {
      borderRadius: 4,
      ...(isToday && {
        borderRadius: 4,
        padding: 0,
        backgroundColor: theme.palette.common.green600,
      }),
      ...(dayIsBetween && {
        borderRadius: 0,
        backgroundColor: theme.palette.common.green100,
        '&:hover, &:focus': {
          backgroundColor: theme.palette.common.green600,
        },
      }),

      ...(isFirstDay && {
        borderTopLeftRadius: 4,
        borderBottomLeftRadius: 4,

        padding: 0,
        backgroundColor: theme.palette.common.green600,
      }),
      ...(isLastDay && {
        borderRadius: 0,
        borderTopRightRadius: 4,
        borderBottomRightRadius: 4,
        padding: 0,
        backgroundColor: `${theme.palette.common.green600} !important`,
      }),
    };
  },
) as ComponentType<DayPickersProps>;
