import React, { FC } from 'react';
import { LayoutComponentProps } from './modules';
import { SchedulerSection, Wrapper } from './styles';
import FilterSection from '../FilterSection';
import NavigateBar from '../NavigateBar';

const LayoutComponent: FC<LayoutComponentProps> = ({
  children,
  confusingAgendas,
}) => {
  return (
    <Wrapper>
      <FilterSection />
      <SchedulerSection>
        <NavigateBar confusingAgendas={confusingAgendas} />
        {children}
      </SchedulerSection>
    </Wrapper>
  );
};

export default LayoutComponent;
