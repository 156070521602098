import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs, { Dayjs } from 'dayjs';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import { SearchProfessionalsByFiltersResponseDto } from '@docbay/schemas';
import { searchProfessionalsByFilters } from 'applicaiton/store/reducers/Scheduler/ActionCreators';
import { Format } from 'applicaiton/constants/scheduler';
import { searchAgenda } from 'applicaiton/store/reducers/Agenda/ActionCreators';
import {
  setAgendaProfessionals,
  setDaysCount,
  setSelectedDate,
  setSelectedProfessionals,
  setSlotsTimeView,
} from 'applicaiton/store/reducers/Scheduler/SchedulerSlice';
import { useAppSelector, useAppDispatch } from 'common/hooks/redux';
import { Checkbox, PrimaryButton, SecondaryButton } from 'common/components';
import { useTimeView } from '../../hooks/useTimeView';
import {
  Wrapper,
  ProfessionalSection,
  CheckboxStyled,
  FiltersCount,
  CheckboxList,
} from './styles';
import AccordionSection from '../AccordionSection';
import { resetAgenda } from 'applicaiton/store/reducers/Agenda/AgendaSlice';
import {
  agendaFiltersSessionStorage,
  setAgendaFiltersSessionStorage,
} from 'applicaiton/sessionStorage/scheduler';

dayjs.extend(dayOfYear);

const FilterByAvailability: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const sessionStorageData = agendaFiltersSessionStorage();
  const timeViewList = useTimeView();
  const { viewBy, byAvailability, professionals, refreshAgendaCounter } =
    useAppSelector((state) => state.scheduler);
  const { isCreatedAppointment, isDeleted, isEdited } = useAppSelector(
    (state) => state.appointmentsSlice,
  );
  const [timeView, setTimeView] = useState<string>(
    sessionStorageData.timeView || timeViewList[0].value,
  );
  const [selectedDays, setSelectedDays] = React.useState<Dayjs[]>(
    sessionStorageData.selectedDays?.map((item) => dayjs(item)) || [],
  );
  const [selectedSpecializations, setSelectedSpecializations] = useState<
    string[]
  >(sessionStorageData.selectedSpecializations || []);
  const [showNearest, setShowNearest] = useState(
    sessionStorageData.showNearest || false,
  );
  const selectedDate = byAvailability.selectedDate;
  const clinicId = byAvailability.clinicId;
  const selectedProfessionals = byAvailability.selectedProfessionals;

  const resetFilter = () => {
    setSelectedDays([]);
    setTimeView(timeViewList[0].value);
    setSelectedSpecializations([]);
    setShowNearest(false);
    setAgendaFiltersSessionStorage({
      selectedDays: [],
      timeView: timeViewList[0].value,
      showNearest: false,
      selectedSpecializations: [],
    });
  };

  useEffect(() => {
    if (clinicId) {
      const startDate = selectedDays.length
        ? selectedDays[0].format(Format.dateFormat)
        : dayjs().format(Format.dateFormat);

      const endDate =
        selectedDays.length > 1
          ? selectedDays[1].format(Format.dateFormat)
          : startDate;

      const timeRangeDividerIndex = timeView.indexOf('-');
      const startTime = timeView.slice(0, timeRangeDividerIndex);
      const endTime = timeView.slice(
        timeRangeDividerIndex + 1,
        timeView.length,
      );

      dispatch(
        searchProfessionalsByFilters({
          clinicId,
          onlyAvailable: false,
          onlyActive: true,
          specializations: selectedSpecializations.map((item) => Number(item)),
          //any type used temporary, waiting fix types on backend
          startDate: startDate as any,
          endDate: endDate as any,
          timeRange: {
            startTime,
            endTime,
          },
        }),
      );
    }
  }, [
    clinicId,
    selectedSpecializations,
    selectedDays,
    timeView,
    refreshAgendaCounter,
  ]);

  const changeDaysCount = () => {
    if (selectedDays.length) {
      if (selectedDays.length > 1) {
        const startDay = selectedDays[0].get('day');
        const endDay = selectedDays[1].get('day');
        if (endDay > startDay) {
          const daysSelected = endDay - startDay + 1;
          dispatch(setDaysCount({ daysCount: daysSelected, key: viewBy }));
        } else {
          const daysSelected = 8 - (startDay - endDay);
          dispatch(setDaysCount({ daysCount: daysSelected, key: viewBy }));
        }
      } else {
        dispatch(setDaysCount({ daysCount: 1, key: viewBy }));
      }
    }
  };

  const getSelectedDate = useCallback(() => {
    if (selectedDays.length === 1) {
      return {
        startDate: selectedDays[0].format(Format.dateFormat),
        endDate: selectedDays[0].format(Format.dateFormat),
      };
    }
    if (selectedDays.length > 1) {
      return {
        startDate: selectedDays[0].format(Format.dateFormat),
        endDate: selectedDays[1].format(Format.dateFormat),
      };
    }
    return {
      startDate: selectedDate,
      endDate: selectedDate,
    };
  }, [selectedDays, selectedDate]);

  const fetchAgenda = useCallback(async () => {
    dispatch(
      setAgendaProfessionals({
        professionals: selectedProfessionals,
        key: viewBy,
      }),
    );
    if (clinicId && selectedProfessionals.length) {
      const { startDate, endDate } = getSelectedDate();
      const timeRangeDividerIndex = timeView.indexOf('-');
      const startTime = timeView.slice(0, timeRangeDividerIndex);
      const endTime = timeView.slice(
        timeRangeDividerIndex + 1,
        timeView.length,
      );

      const professionalIds = selectedProfessionals.map((item) => item.id!);
      if (selectedDays.length) {
        changeDaysCount();
        dispatch(
          setSelectedDate({
            date: selectedDays[0].format(Format.dateFormat),
            key: viewBy,
          }),
        );
      }
      dispatch(
        setSlotsTimeView({
          slotsTimeView: timeView,
          key: viewBy,
        }),
      );
      await dispatch(
        searchAgenda({
          startDate: startDate,
          endDate: endDate,
          professionalIds,
          clinicId: clinicId,
          timeRange: {
            startTime: startTime,
            endTime: endTime,
          },
          onlyAvailable: false,
        }),
      );
    }
    if (!selectedProfessionals.length) {
      dispatch(resetAgenda());
    }
  }, [
    selectedProfessionals,
    clinicId,
    timeView,
    selectedDate,
    selectedDays,
    timeView,
  ]);

  useEffect(() => {
    if (isCreatedAppointment || isDeleted || isEdited || refreshAgendaCounter) {
      fetchAgenda();
    }
  }, [isCreatedAppointment, isDeleted, isEdited, refreshAgendaCounter]);

  const handleSelectProfessional = (
    checked: boolean,
    professional: SearchProfessionalsByFiltersResponseDto,
  ) => {
    if (checked) {
      dispatch(
        setSelectedProfessionals({
          professionals: [...selectedProfessionals, professional],
          key: viewBy,
        }),
      );
    } else {
      dispatch(
        setSelectedProfessionals({
          professionals: selectedProfessionals.filter(
            (item) => item.id !== professional.id,
          ),
          key: viewBy,
        }),
      );
    }
  };

  const handleSelectSpecializations = (checked: boolean, value: string) => {
    if (checked) {
      setSelectedSpecializations((prev) => [...prev, value]);
      setAgendaFiltersSessionStorage({
        selectedSpecializations: [...selectedSpecializations, value],
      });
    } else {
      setSelectedSpecializations((prev) =>
        prev.filter((item) => item !== value),
      );
      setAgendaFiltersSessionStorage({
        selectedSpecializations: [
          ...selectedSpecializations.filter((item) => item !== value),
        ],
      });
    }
  };

  const checkProfessionalSelected = (value: string) => {
    return selectedProfessionals.some((item) => item.id === value);
  };

  const filterCount = useMemo(() => {
    const isDaysSelected = !!selectedDays.length;
    const isTimeSelected = !!timeView;

    return (
      selectedSpecializations.length +
      Number(isDaysSelected) +
      Number(isTimeSelected)
    );
  }, [selectedSpecializations, timeView, selectedDays]);

  return (
    <Wrapper>
      <section>
        <FiltersCount>
          <p>
            {t('scheduler.chosen_filters')}: {filterCount}
          </p>
          <SecondaryButton
            type={'button'}
            styleType={'color'}
            onClick={resetFilter}
          >
            {t('scheduler.clear_all')}
          </SecondaryButton>
        </FiltersCount>
        <AccordionSection
          selectedDays={selectedDays}
          setSelectedDays={setSelectedDays}
          timeView={timeView}
          setTimeView={setTimeView}
          handleSelectSpecializations={handleSelectSpecializations}
          selectedSpecializations={selectedSpecializations}
          showNearest={showNearest}
          setShowNearest={setShowNearest}
          clinicId={clinicId}
        />
        <ProfessionalSection>
          <p>
            {t('scheduler.results')}: {professionals.length}
          </p>
          <CheckboxList>
            {professionals?.map((professional) => (
              <CheckboxStyled
                key={professional.id}
                isActive={checkProfessionalSelected(professional.id!)}
              >
                <Checkbox
                  id={`professional ${professional.id}`}
                  checked={checkProfessionalSelected(professional.id!)}
                  onChange={(checked) =>
                    handleSelectProfessional(checked, professional)
                  }
                  label={`${professional.firstName} ${professional.lastName}`}
                />
              </CheckboxStyled>
            ))}
          </CheckboxList>
        </ProfessionalSection>
      </section>
      <PrimaryButton onClick={fetchAgenda}>
        {t('scheduler.show_agendas')} ({selectedProfessionals.length})
      </PrimaryButton>
    </Wrapper>
  );
};

export default FilterByAvailability;
