import React, { FC, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { SupportedCountriesIso3166 } from '@docbay/schemas';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import {
  Input,
  PrimaryButton,
  SecondaryButton,
  InputPhone,
  Loader,
  CountryDropdown,
} from 'common/components';
import { StyledForm } from './styles';
import ButtonGroup from '../ButtonGroup';
import {
  focusOnEmailInput,
  setFocusOnEmailSessionStorage,
  setClinicOwnerRegistrationSessionStorage,
} from 'applicaiton/sessionStorage/clinicRegistration';
import { usePersonalInfo } from 'features/feature-clinic-registration/hooks/usePersonalInfo';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { clinicOwnerSignUp } from 'applicaiton/store/reducers/ClinicRegistration/ActionCreators';
import { setClinicRegistrationError } from 'applicaiton/store/reducers/ClinicRegistration/ClinicRegistrationSlice';
import { usePersonalInfoSchema } from 'features/feature-clinic-registration/hooks/usePersonalInfoScheme';
import { usePrivateDoctorRegistration } from 'features/feature-clinic-registration/hooks/usePrivateDoctorRegistration';
import { SupportedCountriesDto } from 'common/types/countries';

interface PersonalInfoFormProps {
  setStep: (value: number) => void;
}

interface FormData {
  firstName: string;
  lastName: string;
  jobPosition: string;
  email: string;
  phone: number;
  supportedCountry: SupportedCountriesDto;
}

const PersonalInfoForm: FC<PersonalInfoFormProps> = ({ setStep }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { isLoading, error, isSuccessClinicOwnerSignUp } = useAppSelector(
    (state) => state.clinicRegistrationSlice,
  );
  const personalInfoSchema = usePersonalInfoSchema();
  const personalInfo = usePersonalInfo();
  const { isPrivateDoctor } = usePrivateDoctorRegistration();
  const {
    register,
    handleSubmit,
    getValues,
    watch,
    setError,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(personalInfoSchema),
    defaultValues: personalInfo,
    mode: 'onChange',
  });

  useEffect(() => {
    if (isSuccessClinicOwnerSignUp) {
      const timerCountDownInMinutes = 5;
      setClinicOwnerRegistrationSessionStorage('timerEndDate', {
        timerEndDate: dayjs().set(
          'minute',
          dayjs().get('minute') + timerCountDownInMinutes,
        ),
      });
      setStep(3);
      setFocusOnEmailSessionStorage(false);
    }
  }, [isSuccessClinicOwnerSignUp]);

  useEffect(() => {
    const values = watch();
    setClinicOwnerRegistrationSessionStorage('personalInfo', values);
  }, [
    watch('email'),
    watch('firstName'),
    watch('jobPosition'),
    watch('lastName'),
    watch('phone'),
    watch('supportedCountry'),
  ]);

  useEffect(() => {
    if (error) {
      clearErrors('email');
      dispatch(
        setClinicRegistrationError(
          t('personal_information.cannot_register_user_error'),
        ),
      );
    }
  }, [watch('email')]);

  useEffect(() => {
    error &&
      setError('email', {
        message: t('personal_information.cannot_register_user_error') || '',
      });
  }, [error]);

  const onBack = () => {
    setStep(1);
    setFocusOnEmailSessionStorage(false);
  };

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    const { jobPosition, supportedCountry, ...restData } = data;

    await dispatch(
      clinicOwnerSignUp({
        ...restData,
        ...(jobPosition ? { jobPosition } : ''),
        phone: `+${data.phone}`,
        contractualAgreement: true,
        privateDoctor: isPrivateDoctor,
        countryOfPracticeIso:
          supportedCountry.code as SupportedCountriesIso3166,
      }),
    );
  };

  const handleChangeCountry = (country: SupportedCountriesDto) => {
    setValue('supportedCountry', country, {
      shouldValidate: !!errors.supportedCountry,
    });
  };

  const disabledButton = !!Object.keys(errors).length;

  return (
    <>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <div>
          <Input
            id="firstName"
            label={t('personal_information.first_name') || ''}
            type="text"
            placeholder={t('personal_information.first_name_placeholder') || ''}
            errorMessage={errors.firstName?.message}
            register={register}
          />
          <Input
            id="lastName"
            label={t('personal_information.last_name') || ''}
            type="text"
            placeholder={t('personal_information.last_name_placeholder') || ''}
            errorMessage={errors.lastName?.message}
            register={register}
          />
          <Input
            id="jobPosition"
            label={t('personal_information.job_position') || ''}
            type="text"
            placeholder={
              t('personal_information.job_position_placeholder') || ''
            }
            errorMessage={errors.jobPosition?.message}
            register={register}
          />
          <Input
            id="email"
            label={t('email') || ''}
            type="email"
            placeholder={t('email_placeholder') || ''}
            register={register}
            errorMessage={errors.email?.message}
            isFocused={focusOnEmailInput()}
          />
          <InputPhone
            id="phone"
            label={t('phone') || ''}
            register={register}
            errorMessage={errors.phone?.message}
            value={getValues('phone')}
            isValid={true}
          />
          {isPrivateDoctor && (
            <CountryDropdown
              label={t('country_of_practice') || ''}
              placeholder={t('select_country_of_practice')}
              country={watch('supportedCountry')}
              onChange={handleChangeCountry}
            />
          )}
        </div>
        <ButtonGroup>
          <PrimaryButton disabled={disabledButton} type="submit">
            {t('clinicRegistration.continue')}
          </PrimaryButton>
          <SecondaryButton
            onClick={() => onBack()}
            disabled={false}
            type="button"
          >
            {t('clinicRegistration.back')}
          </SecondaryButton>
        </ButtonGroup>
      </StyledForm>
      {isLoading && <Loader />}
    </>
  );
};
export default PersonalInfoForm;
