import React, { useMemo } from 'react';
import { ReactComponent as WarningIcon } from 'applicaiton/assets/warning.svg';
import {
  Wrapper,
  DateWrapper,
  Date,
  Name,
  IconsWrapper,
  StyledCalendarIcon,
  StyledDeleteIcon,
} from './styles';
import { AbsenceResponseDto } from '@docbay/schemas';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { TimeZone } from 'applicaiton/constants/timeZone';
import { useLocation } from 'react-router-dom';
import { PathNames } from 'applicaiton/routes';
import { userHasPermission } from 'common/helpers/userHasPermission';
import { UserPermissions } from 'applicaiton/constants/userPermissions';
require('dayjs/locale/pt');
require('dayjs/locale/fr');

interface Props {
  data: AbsenceResponseDto;
  onDelete: (value: AbsenceResponseDto | null) => void;
}

const tz = TimeZone.Lisbon;

const AbsenceItem = ({ data, onDelete }: Props) => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const isMyAgendaPage = location.pathname === PathNames.clinicOwnerSchedule;
  const isCanCancelAgenda = userHasPermission(
    UserPermissions.cancelAgenda,
    Number(data.clinic?.owner?.id),
    isMyAgendaPage,
  );
  const isSame = useMemo(() => {
    const diff = dayjs(dayjs(data?.startDate).format('YYYY-MM-DD')).diff(
      dayjs(dayjs(data?.endDate).format('YYYY-MM-DD')),
    );

    return diff === 0;
  }, [data]);

  const endDateFormatted = isSame
    ? dayjs.tz(data?.endDate || null, tz).format('HH:mm')
    : dayjs
        .tz(data?.endDate || null, tz)
        .locale(i18n.language)
        .format('DD MMMM HH:mm');

  return (
    <Wrapper>
      <WarningIcon />
      <div>
        <DateWrapper>
          <Date>
            <span>
              {dayjs
                .tz(data?.startDate, tz)
                .locale(i18n.language)
                .format('DD MMMM')}
            </span>{' '}
            {t('from')}{' '}
            <span>{dayjs.tz(data?.startDate, tz).format('HH:mm')}</span>{' '}
            {t('to')} <span>{endDateFormatted}</span>
          </Date>
          <IconsWrapper>
            <StyledCalendarIcon onClick={() => {}} />
            {isCanCancelAgenda && (
              <StyledDeleteIcon onClick={() => onDelete(data)} />
            )}
          </IconsWrapper>
        </DateWrapper>
        <Name>Absence Hours</Name>
      </div>
    </Wrapper>
  );
};

export default AbsenceItem;
