import React, { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as EditIcon } from 'applicaiton/assets/edit-square.svg';
import { ProfessionalNameStyled, StyledTr, ActionsStyled } from './styles';
import { Box } from '@mui/system';
import { SubscriberItemProps } from './modules';
import { PathNames } from 'applicaiton/routes';
import { useTranslation } from 'react-i18next';

const SubscriberItem: FC<SubscriberItemProps> = ({ patient }) => {
  const { t } = useTranslation();
  const patientName = `${patient.patient.firstName} ${patient.patient.lastName}`;
  const forWhom = patient.forWhom ? patient.forWhom.relationship : '';
  const durationText = useMemo(() => {
    const duration = Number(patient?.durationInWeeks) || 0;
    const durationNumber = duration > 3 ? duration / 4 : duration;

    const durationName =
      duration > 3
        ? t(
            `professional_profile.subscriptions.${
              duration / 4 === 1 ? 'month' : 'months'
            }`,
          )
        : t(
            `professional_profile.subscriptions.${
              duration === 1 ? 'week' : 'weeks'
            }`,
          );

    return `${durationNumber} ${durationName}`;
  }, [patient]);

  return (
    <StyledTr>
      <td>
        <Box display="flex" gap={1} alignItems="center" flexWrap="wrap">
          <ProfessionalNameStyled>
            <div>
              <p>{patientName}</p>
              <span>{patient.patient.phone}</span>
            </div>
          </ProfessionalNameStyled>
        </Box>
      </td>
      <td>{patient.packageName}</td>
      <td className={'noWrap'}>
        €{`${Number(patient.pricePaid).toFixed(2)} / ${durationText}`}
      </td>
      <td>{forWhom}</td>
      <td>
        <ActionsStyled>
          <Link
            to={`${PathNames.patient}/${patient.patient.id}/subscriptions`}
            target="_blank"
          >
            <EditIcon />
          </Link>
        </ActionsStyled>
      </td>
    </StyledTr>
  );
};
export default SubscriberItem;
