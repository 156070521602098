import React, { FC, useMemo } from 'react';
// This code will be used in future
// import { ReactComponent as ExpandMoreIcon } from 'applicaiton/assets/expand-more.svg';
import { AccordionProfilesStyled } from './styles';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PathNames } from 'applicaiton/routes';
import { UserRoles } from 'applicaiton/constants/userRoles';
import { useAppSelector } from 'common/hooks/redux';
import {
  getProfilesFromSessionStorage,
  getUserId,
  getUserRole,
} from 'applicaiton/sessionStorage/auth';
import { userRoleExactMatch } from 'common/helpers/userRoleExactMatch';

interface AccordionProps {
  showList: boolean;
  setShowList: (value: boolean) => void;
}

const AccordionProfiles: FC<AccordionProps> = ({ showList }) => {
  const { t } = useTranslation();
  const userRole = getUserRole();
  const { currentClinicOwner } = useAppSelector(
    (state) => state.clinicOwnerSlice,
  );
  const admins = useAppSelector((state) => state.admins);
  const secretary = useAppSelector((state) => state.secretaries);
  const { editedProfessional } = useAppSelector(
    (state) => state.professionalProfile,
  );
  const navigate = useNavigate();
  const userId = getUserId();

  const currentProfile = useMemo(() => {
    const profiles = getProfilesFromSessionStorage();
    const isAdmin = userRoleExactMatch(UserRoles.admin);
    const currentProfile = profiles?.length ? profiles[0] : null;

    if (userRole?.includes(UserRoles.clinicOwner)) {
      return {
        id: currentClinicOwner?.id || currentProfile?.id,
        firstName: currentClinicOwner?.firstName || currentProfile?.firstName,
        lastName: currentClinicOwner?.lastName || currentProfile?.lastName,
        photo: currentClinicOwner?.photo || currentProfile?.photo,
      };
    }
    if (isAdmin) {
      return {
        id: admins.currentUser?.id || currentProfile?.id,
        firstName: admins.currentUser?.firstName || currentProfile?.firstName,
        lastName: admins.currentUser?.lastName || currentProfile?.lastName,
        photo: admins.currentUser?.photo || currentProfile?.photo,
      };
    }
    if (userRole === UserRoles.secretary) {
      return {
        id: secretary.currentUser?.id || currentProfile?.id,
        firstName:
          secretary.currentUser?.firstName || currentProfile?.firstName,
        lastName: secretary.currentUser?.lastName || currentProfile?.lastName,
        photo: secretary.currentUser?.photo || currentProfile?.photo,
      };
    }
    if (userRole === UserRoles.professional) {
      return {
        id: editedProfessional?.id || currentProfile?.id,
        firstName: editedProfessional?.firstName || currentProfile?.firstName,
        lastName: editedProfessional?.lastName || currentProfile?.lastName,
        photo: editedProfessional?.mainPhoto || currentProfile?.photo,
      };
    }
  }, [
    currentClinicOwner,
    admins.currentUser,
    secretary.currentUser,
    editedProfessional,
  ]);

  const handleGoToMyProfile = () => {
    navigate(`${PathNames.profile}/${userId}`);
  };

  const currentProfileInitials = currentProfile?.firstName
    ? `${currentProfile?.firstName!.slice(
        0,
        1,
      )}${currentProfile?.lastName!.slice(0, 1)}`
    : '';

  const formattedUserRoles = userRole
    ?.replace(UserRoles.clinicOwner, t('user_roles.clinic_owner'))
    .replace(',', ', ');

  // This code will be used in future
  // ---------------------------------
  // const profilesList = useMemo(() => {
  //   const filteredProfiles = profiles?.filter(
  //     (item) => item.id !== currentProfile?.id,
  //   );
  //   return filteredProfiles;
  // }, [profiles, currentProfile]);
  // ---------------------------------
  return (
    <AccordionProfilesStyled isActive={showList}>
      <div
        className="accordion-root"
        // This code will be used in future
        // onClick={() => setShowList(!showList)}
      >
        <div className="accordion-info" onClick={handleGoToMyProfile}>
          <div className="image">
            {currentProfile?.photo ? (
              <img
                src={currentProfile?.photo?.thumbnailUrl}
                alt={currentProfile?.id}
              />
            ) : (
              <>{currentProfileInitials}</>
            )}
          </div>

          <p>{`${currentProfile?.firstName} ${currentProfile?.lastName}`}</p>
          <span>{formattedUserRoles}</span>
        </div>
        {/* 
        This code will be used in future
         --------------------------------- 
        <div className="accordion-expand">
          <ExpandMoreIcon />
        </div> 
         --------------------------------- 
        */}
      </div>
      {/*  
      This code will be used in future
      --------------------------------- 
      {showList && (
        <div className="accordion-list">
          {profilesList?.map((item) => (
            <div key={item.id} className="accordion-info">
              <div className="image">
                {item?.photo ? (
                  <img
                    // @ts-ignore
                    src={item.photo.thumbnailUrl}
                    // @ts-ignore
                    alt={item.id}
                  />
                ) : (
                  <>{`${item.firstName.slice(0, 1)} ${item.lastName.slice(
                    0,
                    1,
                  )}`}</>
                )}
              </div>
              <p>{`${item.firstName} ${item.lastName}`}</p>
              <span>Clinic Owner</span>
            </div>
          ))}
        </div>
      )} 
      ---------------------------------
      */}
    </AccordionProfilesStyled>
  );
};
export default AccordionProfiles;
