import axiosInstance from './axiosInstance';
import {
  AppointmentDataResponseDto,
  AppointmentDataCreationDto,
  AppointmentHistoryResponseDto,
  AllergyResponseDto,
  DiseaseResponseDto,
  MedicalBackgroundResponseDto,
  FamilyHistoryCreationDto,
  FamilyHistoryResponseDto,
  SurgeryDataResponseDto,
  SurgeryCreationDto,
  SurgeryResponseDto,
  SymptomResponseDto,
  CommonEntitiesListResponse,
} from '@docbay/schemas';
import { PaginationPropsWithSearch } from 'common/types/pagination';

export const ConsultationDataAPI = {
  getConsultationData: (appointmentId: string) =>
    axiosInstance.get<AppointmentDataResponseDto>(
      `/appointment-data/${appointmentId}`,
    ),
  getConsultationDataHistory: (appointmentId: string) =>
    axiosInstance.get<AppointmentHistoryResponseDto[]>(
      `/appointment-data/history/${appointmentId}`,
    ),
  getPatientMedicalBackground: (patientId: string) =>
    axiosInstance.get<MedicalBackgroundResponseDto>(
      `/medical-background/patients/${patientId}`,
    ),
  getAllergies: (params: PaginationPropsWithSearch) =>
    axiosInstance.get<CommonEntitiesListResponse<AllergyResponseDto>>(
      '/medical-background/allergies',
      { params },
    ),
  getDiseases: (params: PaginationPropsWithSearch) =>
    axiosInstance.get<CommonEntitiesListResponse<DiseaseResponseDto>>(
      '/medical-background/diseases',
      { params },
    ),
  getSurgeries: (params: PaginationPropsWithSearch) =>
    axiosInstance.get<CommonEntitiesListResponse<SurgeryResponseDto>>(
      '/medical-background/surgeries',
      { params },
    ),
  getSymptoms: (params: PaginationPropsWithSearch) =>
    axiosInstance.get<CommonEntitiesListResponse<SymptomResponseDto>>(
      '/medical-background/symptoms',
      { params },
    ),
  setPatientAllergy: ({
    patientId,
    allergyId,
  }: {
    patientId: string;
    allergyId: string;
  }) =>
    axiosInstance.post(
      `/medical-background/patients/${patientId}/allergies/${allergyId}`,
    ),
  removePatientAllergy: ({
    patientId,
    allergyId,
  }: {
    patientId: string;
    allergyId: string;
  }) =>
    axiosInstance.delete(
      `/medical-background/patients/${patientId}/allergies/${allergyId}`,
    ),
  setPatientDisease: ({
    patientId,
    diseaseId,
  }: {
    patientId: string;
    diseaseId: string;
  }) =>
    axiosInstance.post(
      `/medical-background/patients/${patientId}/diseases/${diseaseId}`,
    ),
  removePatientDisease: ({
    patientId,
    diseaseId,
  }: {
    patientId: string;
    diseaseId: string;
  }) =>
    axiosInstance.delete(
      `/medical-background/patients/${patientId}/diseases/${diseaseId}`,
    ),
  saveConsultationData: (
    appointmentId: string,
    data: AppointmentDataCreationDto,
  ) =>
    axiosInstance.put<AppointmentDataResponseDto>(
      `/appointment-data/${appointmentId}`,
      data,
    ),
  setPatientFamilyHistoricData: (
    patientId: string,
    data: FamilyHistoryCreationDto,
  ) =>
    axiosInstance.post<FamilyHistoryResponseDto>(
      `/medical-background/patients/${patientId}/family-history`,
      data,
    ),
  updatePatientFamilyHistoricData: (
    patientId: string,
    familyHistoryId: string,
    data: FamilyHistoryCreationDto,
  ) =>
    axiosInstance.patch<FamilyHistoryResponseDto>(
      `/medical-background/patients/${patientId}/family-history/${familyHistoryId}`,
      data,
    ),
  setPatientSurgeryData: (patientId: string, data: SurgeryCreationDto) =>
    axiosInstance.post<SurgeryDataResponseDto>(
      `/medical-background/patients/${patientId}/surgeries`,
      data,
    ),
  updatePatientSurgeryData: (
    patientId: string,
    surgeryDataId: string,
    data: SurgeryCreationDto,
  ) =>
    axiosInstance.patch<SurgeryDataResponseDto>(
      `/medical-background/patients/${patientId}/surgeries/${surgeryDataId}`,
      data,
    ),
  deletePatientFamilyHistoricData: (
    patientId: string,
    familyHistoryId: string,
  ) =>
    axiosInstance.delete<FamilyHistoryResponseDto>(
      `/medical-background/patients/${patientId}/family-history/${familyHistoryId}`,
    ),
  deleteSurgeryData: (patientId: string, surgeryDataId: string) =>
    axiosInstance.delete<FamilyHistoryResponseDto>(
      `/medical-background/patients/${patientId}/surgeries/${surgeryDataId}`,
    ),
};
