import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ProfessionalUpdateDto } from '@docbay/schemas';
import { EditSubscription, ProfessionalProfileState } from './models';
import {
  fetchProfessionalById,
  resendProfessionalAuthorizationData,
  updateProfessional,
  updateProfessionalEmail,
  professionalVerifyCode,
  professionalSetNewEmail,
  professionalVerifyOldEmail,
  professionalVerifyPhone,
  professionalSetNewPhone,
  fetchProfessionalsSubscribers,
  fetchSubscriptions,
  fetchSubscriptionStatistic,
} from './ActionCreators';
import {
  AuthLocalStorage,
  setUsersRefreshTokenSessionStorage,
} from 'applicaiton/sessionStorage/auth';

const initialState: ProfessionalProfileState = {
  profileChanges: null,
  editedProfessional: null,
  editProfessionalWarnings: [],
  isLoading: false,
  isVerifiedOldEmail: false,
  isVerifiedOldEmailCode: false,
  isWrongVerificationCode: false,
  isResentActive: true,
  isVerifiedOldPhone: false,
  isVerifiedOldPhoneCode: false,
  error: '',
  subscriptions: [],
  patientsSubscribers: [],
  subscribersTotalPages: 0,
  subscribersTotal: 0,
  subscribersPage: 0,
  clinicOwnerIdsWithNotConfiguredPayment: [],
  subscriptionsPackages: [],
  subscriptionsStatistic: null,
};

const professionalProfileSlice = createSlice({
  name: 'professionalProfile',
  initialState,
  reducers: {
    setProfileChanges: (
      state,
      actions: PayloadAction<ProfessionalUpdateDto | null>,
    ) => {
      state.profileChanges = actions.payload!;
    },
    resetProfessionalProfile: (state) => {
      state.profileChanges = null;
      state.editedProfessional = null;
      state.isLoading = false;
      state.isVerifiedOldEmail = false;
      state.error = '';
    },
    resetEmailData: (state) => {
      state.isVerifiedOldEmail = false;
      state.isVerifiedOldEmailCode = false;
      state.isWrongVerificationCode = false;
    },
    resetVerifiedOldEmailCode: (state) => {
      state.isVerifiedOldEmailCode = false;
    },
    setIsResentActive: (state, actions) => {
      state.isResentActive = actions.payload;
    },
    setIsWrongVerificationCode: (state, actions) => {
      state.isWrongVerificationCode = actions.payload;
    },
    setEditedProfessional: (state, actions) => {
      state.editedProfessional = actions.payload;
    },
    rersetProfessionalError: (state) => {
      state.error = '';
    },
    resetVerifiedOldPhoneCode: (state) => {
      state.isVerifiedOldPhoneCode = false;
    },
    resetPhoneData: (state) => {
      state.isVerifiedOldPhone = false;
      state.isVerifiedOldPhoneCode = false;
      state.isWrongVerificationCode = false;
      state.error = '';
    },
    setProfileSubscriptions: (
      state,
      actions: PayloadAction<EditSubscription[]>,
    ) => {
      state.subscriptions = actions.payload!;
    },
    setClinicOwnerWithoutAccount: (state, actions) => {
      state.clinicOwnerIdsWithNotConfiguredPayment = actions.payload!;
    },
    resetUpdateProfessionalWarnings: (state) => {
      state.editProfessionalWarnings = [];
    },
  },
  extraReducers: (builder) => {
    //get professional
    builder.addCase(fetchProfessionalById.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchProfessionalById.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(fetchProfessionalById.fulfilled, (state, action) => {
      state.editedProfessional = action.payload;
      state.isLoading = false;
    });
    //professionals update
    builder.addCase(updateProfessional.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateProfessional.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(updateProfessional.fulfilled, (state, action) => {
      state.editedProfessional = action.payload;
      state.editProfessionalWarnings = action.payload?.warnings ?? [];
      state.isLoading = false;
    });
    //resend link
    builder.addCase(resendProfessionalAuthorizationData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      resendProfessionalAuthorizationData.rejected,
      (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      },
    );
    builder.addCase(resendProfessionalAuthorizationData.fulfilled, (state) => {
      state.isLoading = false;
    });
    //update email
    builder.addCase(updateProfessionalEmail.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateProfessionalEmail.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(updateProfessionalEmail.fulfilled, (state) => {
      state.isLoading = false;
    });

    //verify old email
    builder.addCase(professionalVerifyOldEmail.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(professionalVerifyOldEmail.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(professionalVerifyOldEmail.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isVerifiedOldEmail = true;
    });

    //verify code
    builder.addCase(professionalVerifyCode.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(professionalVerifyCode.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
      state.isWrongVerificationCode = true;
    });
    builder.addCase(professionalVerifyCode.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isVerifiedOldEmailCode = !!action.payload;
    });

    //set new email
    builder.addCase(professionalSetNewEmail.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(professionalSetNewEmail.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
      state.isWrongVerificationCode = true;
    });
    builder.addCase(professionalSetNewEmail.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.token) {
        setUsersRefreshTokenSessionStorage(action.payload);

        state.editedProfessional = {
          ...state.editedProfessional!,
          user: {
            ...state.editedProfessional!.user!,
            email: String(sessionStorage.getItem('newEmail'))!,
          },
        };

        const profiles = JSON.parse(
          localStorage.getItem(AuthLocalStorage.profiles) as string,
        );

        const newProfile = {
          ...profiles[0],
          email: String(sessionStorage.getItem('newEmail')),
          token: action.payload.token,
        };

        localStorage.setItem(
          AuthLocalStorage.profiles,
          JSON.stringify([newProfile]),
        );
        sessionStorage.removeItem('oldEmail');
        sessionStorage.removeItem('newEmail');
      }
    });

    //verify old phone
    builder.addCase(professionalVerifyPhone.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(professionalVerifyPhone.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(professionalVerifyPhone.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isVerifiedOldPhone = true;
    });
    //set new email
    builder.addCase(professionalSetNewPhone.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(professionalSetNewPhone.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
      state.isWrongVerificationCode = true;
    });
    builder.addCase(professionalSetNewPhone.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = '';

      if (action.payload.token) {
        setUsersRefreshTokenSessionStorage(action.payload);

        state.editedProfessional = {
          ...state.editedProfessional!,
          phone: String(sessionStorage.getItem('newPhone')),
        };

        const profiles = JSON.parse(
          localStorage.getItem(AuthLocalStorage.profiles) as string,
        );

        const newProfile = {
          ...profiles[0],
          phone: String(sessionStorage.getItem('newPhone')),
          token: action.payload.token,
        };

        localStorage.setItem(
          AuthLocalStorage.profiles,
          JSON.stringify([newProfile]),
        );
        sessionStorage.removeItem('oldPhone');
        sessionStorage.removeItem('newPhone');
      }
    });
    //fetch all subscribers
    builder.addCase(fetchProfessionalsSubscribers.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchProfessionalsSubscribers.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
      state.isWrongVerificationCode = true;
    });
    builder.addCase(
      fetchProfessionalsSubscribers.fulfilled,
      (state, action) => {
        state.patientsSubscribers = action.payload.entities;
        state.subscribersTotalPages = Number(action.payload.totalPages);
        state.subscribersTotal = Number(action.payload.total);
        state.subscribersPage = Number(action.payload.page);
        state.isLoading = false;
        state.error = '';
      },
    );
    //fetch all subscriptions
    builder.addCase(fetchSubscriptions.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchSubscriptions.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(fetchSubscriptions.fulfilled, (state, action) => {
      state.subscriptionsPackages = action.payload;
      state.isLoading = false;
      state.error = '';
    });
    //fetch all subscriptions
    builder.addCase(fetchSubscriptionStatistic.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchSubscriptionStatistic.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(fetchSubscriptionStatistic.fulfilled, (state, action) => {
      state.subscriptionsStatistic = action.payload;
      state.isLoading = false;
      state.error = '';
    });
  },
});

export const professionalProfileActions = professionalProfileSlice.actions;

export default professionalProfileSlice.reducer;
