import React, { FC, useMemo, useState } from 'react';
import { Wrapper, Subscription } from './styles';
import { ReactComponent as CloseIcon } from 'applicaiton/assets/close.svg';
import { useTranslation } from 'react-i18next';
import { CreateSubscription } from 'applicaiton/store/reducers/Professionals/models';
import AddSubscriptionModal from '../AddSubscriptionModal';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { ClinicProfessionalUpdateDto } from '@docbay/schemas';
import { setProfileChanges } from 'applicaiton/store/reducers/Professionals/CreateProfessionalProfileSlice';

interface Props {
  data: CreateSubscription;
  onDelete: (data: CreateSubscription) => void;
}
const SubscriptionItem: FC<Props> = ({ data, onDelete }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);

  const { profileChanges, subscriptions } = useAppSelector(
    (state) => state.createProfessionalProfile,
  );
  const { clinics } = useAppSelector((state) => state.clinics);

  const currentClinic = useMemo(() => {
    return clinics?.find((item) => item.id === data.clinicId);
  }, [clinics]);

  const handleCloseEditModal = () => {
    setIsOpenEditModal(false);
  };

  const handleOpenEditModal = () => {
    setIsOpenEditModal(true);
  };

  const handleEditSubscription = (data: CreateSubscription) => {
    const indexOfObject = subscriptions!.findIndex(
      (object) => object.id === data.id,
    );

    const newSubscriptions = [...subscriptions];
    const newClinicsRelations: ClinicProfessionalUpdateDto[] = [];

    newSubscriptions!.splice(indexOfObject, 1, data);

    profileChanges?.clinicsRelations?.map((clinic) => {
      if (clinic.clinicId === data.clinicId) {
        const subs = newSubscriptions.filter(
          (item) => item.clinicId === data.clinicId,
        );

        newClinicsRelations.push({
          ...clinic,
          subscriptionsToAdd: subs,
        });
      } else {
        newClinicsRelations.push(clinic);
      }
    });

    const prof = {
      ...profileChanges!,
      clinicsRelations: newClinicsRelations,
    };

    dispatch(setProfileChanges(prof!));
    handleCloseEditModal();
  };

  const durationText = useMemo(() => {
    const duration = Number(data?.durationInWeeks) || 0;
    const durationNumber = duration > 3 ? duration / 4 : duration;

    const durationName =
      duration > 3
        ? t(
            `professional_profile.subscriptions.${
              duration / 4 === 1 ? 'month' : 'months'
            }`,
          )
        : t(
            `professional_profile.subscriptions.${
              duration === 1 ? 'week' : 'weeks'
            }`,
          );

    return `${durationNumber} ${durationName}`;
  }, [data]);

  const handleDelete = () => {
    onDelete(data);
  };
  return (
    <Subscription>
      <Wrapper onClick={handleOpenEditModal}>
        <p>{data.title}</p>
        <p>
          {t('clinic')}:<span>{currentClinic?.name}</span>
        </p>
        <p>
          {t('price')}:<span>€{data.price}</span>
        </p>
        <p>
          {t('professional_profile.subscriptions.duration')}:
          <span>{durationText}</span>
        </p>
        <p>
          {t('professional_profile.subscriptions.teleconsultation')}:
          <span>
            {data.teleconsultationMinutes} {t('mins')}
          </span>
        </p>
      </Wrapper>
      <CloseIcon onClick={handleDelete} />
      <AddSubscriptionModal
        key={'edit'}
        isOpen={isOpenEditModal}
        onClose={handleCloseEditModal}
        onAdd={handleEditSubscription}
        data={data}
      />
    </Subscription>
  );
};

export default SubscriptionItem;
