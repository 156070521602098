import React, { FC } from 'react';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';

import { MarkdownTextProps } from './models';
import { Wrapper } from './styles';

const breaksMarkdownText = (text: string) => {
  return text.replace(/\\n/gi, '\n').replace(/\n/gi, '<br/>');
};

const MarkdownText: FC<MarkdownTextProps> = ({ text }) => {
  const markdownText = breaksMarkdownText(text);
  return (
    <Wrapper>
      <Markdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}>
        {markdownText}
      </Markdown>
    </Wrapper>
  );
};

export default MarkdownText;
