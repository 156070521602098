import { useMemo, useRef, useState } from 'react';

export const useTimer = () => {
  const [startTime, setStartTime] = useState(new Date());
  const [triggerUpdate, setTriggerUpdate] = useState(0);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const startTimer = () => {
    setStartTime(new Date());
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    if (intervalRef) {
      intervalRef.current = setInterval(() => {
        setTriggerUpdate((prev) => prev + 1);
      }, 100);
    }
  };
  const resetTimer = () => {
    setStartTime(new Date());
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
  };

  const seconds = useMemo(() => {
    return Math.round((new Date().valueOf() - startTime.valueOf()) / 1000);
  }, [startTime, triggerUpdate]);

  return {
    startTimer,
    seconds,
    resetTimer,
  };
};
