import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { StyledModal } from './styles';
import { ReactComponent as CloseIcon } from 'applicaiton/assets/close.svg';
import { ReactComponent as SuccessIcon } from 'applicaiton/assets/success-icon.svg';
import { SecondaryButton } from 'common/components';
import { useAppSelector } from 'common/hooks/redux';
import { TimeZone } from 'applicaiton/constants/timeZone';

require('dayjs/locale/pt');
require('dayjs/locale/fr');

interface SuccessModalProps {
  onClose: () => void;
}

const tz = TimeZone.Lisbon;

const SuccessModal: FC<SuccessModalProps> = ({ onClose }) => {
  const { t, i18n } = useTranslation();

  const { absence } = useAppSelector((state) => state.absence);
  const isSame = useMemo(() => {
    const diff = dayjs(dayjs(absence?.startDate).format('YYYY-MM-DD')).diff(
      dayjs(dayjs(absence?.endDate).format('YYYY-MM-DD')),
    );

    return diff === 0;
  }, [absence?.endDate, absence?.startDate]);

  const getTimeString = (date?: Date | null) => {
    const dateInTz = dayjs(date).tz(tz);
    return dateInTz.format('HH:mm');
  };

  return (
    <StyledModal>
      <CloseIcon onClick={onClose} />
      <div>
        <SuccessIcon />
        <p>
          {t('absenceModal.createdText')}{' '}
          <span>
            {absence?.professional?.firstName} {absence?.professional?.lastName}
          </span>
          <br />
          {t('on')}{' '}
          <span>
            {dayjs
              .tz(absence?.startDate, tz)
              .locale(i18n.language)
              .format('DD MMMM')}
          </span>{' '}
          {t('from')} <span>{getTimeString(absence?.startDate)}</span> {t('to')}{' '}
          <span>
            {isSame
              ? getTimeString(absence?.endDate)
              : dayjs(absence?.endDate)
                  .tz(tz)
                  .locale(i18n.language)
                  .format('DD MMMM HH:mm')}
          </span>
        </p>
        <SecondaryButton onClick={onClose}>
          {t('clinicsConfiguration.delete_profile.success.ok')}
        </SecondaryButton>
      </div>
    </StyledModal>
  );
};

export default SuccessModal;
