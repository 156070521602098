import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { ForgotPasswordDataT } from '../types';

export const useForgotPasswordForm = ({
  emailError,
  requiredError,
}: {
  emailError: string;
  requiredError: string;
}) => {
  const createPasswordSchema = yup
    .object({
      email: yup.string().email(emailError!).required(requiredError!),
    })
    .required();

  const {
    register,
    watch,
    getValues,
    setValue,
    handleSubmit,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm<ForgotPasswordDataT>({
    resolver: yupResolver(createPasswordSchema),
    mode: 'onChange',
  });

  return {
    register,
    watch,
    getValues,
    setValue,
    errors,
    handleSubmit,
    clearErrors,
    setError,
  };
};
