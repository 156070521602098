import React, { ComponentType, FC, useMemo } from 'react';
import { PickersDayProps } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';
import { AccordionSummary } from '@mui/material';
import { ReactComponent as ExpandMoreIcon } from 'applicaiton/assets/expand-more.svg';
import { ReactComponent as CloseIcon } from 'applicaiton/assets/close.svg';
import { Checkbox } from 'common/components';
import { getDateValidation } from 'features/feature-scheduler/helpers/getDateValidation';
import { AccordionState } from '../AccordionSection/modules';
import { CheckboxStyled } from '../FilterByAvailability/styles';
import { DateCalendarStyled } from '../FilterSection/styles';
import CustomPickersDay from '../CustomPickerDays';
import { AccordionDatePickerProps } from './modules';
import {
  AccordionDetailsStyled,
  AccordionStyled,
  PillsList,
} from '../AccordionSection/styles';

const AccordionDatePicker: FC<AccordionDatePickerProps> = ({
  selectedDays,
  setSelectedDays,
  isExpanded,
  handleOpenAccordion,
  showNearest,
  handleSetNearest,
}) => {
  const { t, i18n } = useTranslation();

  const selectedDaysRange = useMemo(() => {
    if (selectedDays.length > 1) {
      const firstDay = dayjs(selectedDays[0])
        .locale(i18n.language)
        .format('DD MMM');
      const lastDay = dayjs(selectedDays[1])
        .locale(i18n.language)
        .format('DD MMM');
      return `${firstDay} - ${lastDay}`;
    }
    return dayjs(selectedDays[0]).locale(i18n.language).format('dddd DD/MM');
  }, [selectedDays]);

  const handlePickDay = (date: Dayjs) => {
    handleSetNearest(false);
    const isSameDate = selectedDays.some((item) => {
      const sameDayOfYear = dayjs(item).dayOfYear() === dayjs(date).dayOfYear();
      const sameYear = dayjs(item).year() === dayjs(date).year();

      if (sameYear && sameDayOfYear) return true;
      return false;
    });
    if (isSameDate || !selectedDays.length) {
      return setSelectedDays([date]);
    }

    const isRangePicked = selectedDays.length === 2;

    const { isBefore: isBeforeStartDay, isAfter: isAfterStartDay } =
      getDateValidation(selectedDays[0], date);

    if (!isRangePicked) {
      if (isBeforeStartDay) return setSelectedDays([date, ...selectedDays]);
      if (isAfterStartDay) return setSelectedDays([...selectedDays, date]);
    }

    if (isRangePicked) {
      const { isBefore: isBeforeEndDay, isAfter: isAfterEndDay } =
        getDateValidation(selectedDays[1], date);
      const dayRangeDifference =
        6 - (selectedDays[1].dayOfYear() - selectedDays[0].dayOfYear());

      const isValidStartRange =
        dayRangeDifference >= selectedDays[0].dayOfYear() - date.dayOfYear();
      const isValidEndRange =
        dayRangeDifference >= date.dayOfYear() - selectedDays[1].dayOfYear();

      const isValidStartDate = isBeforeStartDay && isValidStartRange;
      const isValidEndDate = isAfterEndDay && isValidEndRange;

      if (isBeforeEndDay) {
        return setSelectedDays(
          selectedDays.map((item, index) => (index === 0 ? date : item)),
        );
      }
      if (isValidStartDate) {
        return setSelectedDays(
          selectedDays.map((item, index) => (index === 0 ? date : item)),
        );
      }
      if (isValidEndDate) {
        return setSelectedDays(
          selectedDays.map((item, index) => (index === 1 ? date : item)),
        );
      }
    }

    return setSelectedDays([date]);
  };

  const startDay = selectedDays.length ? selectedDays[0] : '';
  const endDay = selectedDays.length > 1 ? selectedDays[1] : '';

  return (
    <AccordionStyled
      id="dayPicker"
      onClick={(e) =>
        handleOpenAccordion(((e.target as Element).id as AccordionState) || '')
      }
      expanded={isExpanded}
    >
      <AccordionSummary aria-controls="panel1a-content" id="dayPicker">
        <div id="dayPicker">
          <h2 id="dayPicker">{t('scheduler.date_with_free_slots')}</h2>{' '}
          <ExpandMoreIcon id="dayPicker" />
        </div>
        {selectedDays.length ? (
          <PillsList className="pillsList">
            <div>
              {selectedDaysRange}
              <CloseIcon onClick={() => setSelectedDays([])} />
            </div>
          </PillsList>
        ) : (
          ''
        )}
      </AccordionSummary>
      <AccordionDetailsStyled>
        <CheckboxStyled isActive={showNearest}>
          <Checkbox
            id={'nearest'}
            checked={showNearest}
            onChange={(checked) => handleSetNearest(checked)}
            label={t('scheduler.nearest')!}
          />
        </CheckboxStyled>
        <DateCalendarStyled
          value={startDay || null}
          onChange={(newValue) => handlePickDay(newValue as Dayjs)}
          views={['day']}
          showDaysOutsideCurrentMonth={true}
          slots={{
            day: CustomPickersDay as
              | ComponentType<PickersDayProps<unknown>>
              | undefined,
          }}
          slotProps={{
            day: {
              startDay,
              endDay,
            } as any,
          }}
        />
      </AccordionDetailsStyled>
    </AccordionStyled>
  );
};

export default AccordionDatePicker;
