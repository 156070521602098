import styled from 'styled-components';

export const StatisticsWrapper = styled.section`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0 32px 20px 32px;
  background: ${({ theme }) => theme.color.background};
  overflow-y: auto;
  height: 100%;

  > div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > h1 {
      color: ${({ theme }) => theme.color.grey900};
      font-size: 28px;
      line-height: 22px;
      letter-spacing: 0.28px;
      text-transform: capitalize;
      margin: 32px 0 20px;
    }
  }
`;
