import React, { FC } from 'react';
import { Wrapper } from './styles';
import {
  SideMenu,
  ClinicsList,
  DeleteProfileModal,
  ClinicSuccessCreatedModal,
} from 'features';

const ClinicsPage: FC = () => {
  return (
    <Wrapper>
      <SideMenu />
      <ClinicsList />
      <DeleteProfileModal />
      <ClinicSuccessCreatedModal />
    </Wrapper>
  );
};

export default ClinicsPage;
