import { styled } from '@mui/system';
import { commonColors } from 'applicaiton/theme';

interface Props {
  disabled: boolean;
}

export const MessageFormStyled = styled('form')<Props>`
  display: flex;
  align-items: center;
  padding-right: 4px;
  margin: 20px;
  justify-content: space-between;
  background: ${commonColors.grey100};
  border-radius: 4px;
  opacity: ${({ disabled }) => (disabled ? '0.6' : '1')};
`;

export const InputStyled = styled('textarea')`
  width: 100%;
  border: none;
  background: transparent;
  color: ${commonColors.grey500};
  font-size: 16px;
  height: 44px;
  resize: none;
  padding: 12px;

  &:focus-visible {
    border: none;
    outline: none;
  }
`;

export const SendButtonStyled = styled('button')`
  all: unset;
  width: 36px;
  min-width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: ${commonColors.blue300};
  border-radius: 4px;

  &:disabled {
    pointer-events: none;
  }

  svg {
    width: 24px;
    height: 24px;
  }

  &:hover {
    svg {
      path {
        fill: ${commonColors.grey600};
      }
    }
  }
`;

export const DocumentButtonStyled = styled('button')`
  all: unset;
  width: 44px;
  min-width: 44px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: ${commonColors.grey200};
  border-radius: 4px 0 0 4px;
  border-right: 1px solid ${commonColors.grey200};

  &:disabled {
    pointer-events: none;
  }

  svg {
    width: 24px;
    height: 24px;
  }

  &:hover {
    svg {
      path {
        fill: ${commonColors.grey600};
      }
    }
  }
`;
