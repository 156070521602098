import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { socialMediaValidation } from 'common/helpers/socialMediaValidation';
import { ProfessionalGeneralInfoT } from '../components/ProfessionalAddForm/types';
import dayjs from 'dayjs';

export const useProfessionalGeneralForm = ({
  requiredError,
}: {
  requiredError: string;
}) => {
  const { t } = useTranslation();
  const urlFiledError = t('errors.must_be_url');
  const formGeneralValues = {
    spokenLanguageIds: [],
    // professionalActivityStartDate: undefined, --- Will be use in future
    dateOfBirth: undefined,
    gender: '',
    description: '',
    photo: undefined,
    socialMedia: [],
  };

  const profileGeneralSchema = yup.object({
    spokenLanguageIds: yup.array().of(yup.string()).min(1, requiredError!),
    // --- Will be use in future ---
    // professionalActivityStartDate: yup
    // .string()
    // .test('matchDate', requiredError, function (value, context) {
    //   if (!value) return true;
    //   const isValid = dayjs(value).isValid();
    //   return isValid;
    // })
    // .required(requiredError!),
    // ----------------------------
    dateOfBirth: yup
      .string()
      .test('matchSocialMedia', urlFiledError, function (value, context) {
        if (!value) return true;
        const isValid = dayjs(value).isValid();
        return isValid;
      })
      .required(requiredError!),
    gender: yup.string().required(requiredError!),
    description: yup.string().required(requiredError!),
    photo: yup.object().shape({
      key: yup.string(),
      photoUrl: yup.string(),
      thumbnailUrl: yup.string(),
    }),
    socialMedia: yup.array().of(
      yup.object().shape({
        url: yup
          .string()
          .test('matchSocialMedia', urlFiledError, function (value, context) {
            if (!value) return true;
            const isValid = socialMediaValidation(
              String(value),
              context.parent.name,
            );
            return isValid;
          }),
      }),
    ),
  });

  const {
    control,
    watch,
    getValues,
    setValue,
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm<ProfessionalGeneralInfoT>({
    resolver: yupResolver(profileGeneralSchema),
    defaultValues: formGeneralValues,
  });

  return {
    control,
    watch,
    getValues,
    setValue,
    errors,
    register,
    handleSubmit,
    clearErrors,
  };
};
