import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UsersListStyled } from './styles';
import { PrimaryButton } from 'common/components';
import { UsersTable, Header, InfoSection } from './components';
import { ReactComponent as AddIcon } from 'applicaiton/assets/add.svg';
import AddUserModal from './components/AddUserModal';

const UsersList: FC = () => {
  const { t } = useTranslation();
  const [showAddModal, setShowAddModal] = useState(false);

  const handleShowModal = () => {
    setShowAddModal(true);
  };

  return (
    <UsersListStyled>
      <Header />
      <div>
        <InfoSection />
        <div>
          <PrimaryButton onClick={handleShowModal}>
            <AddIcon />
            {t('adminsListConfiguration.add_new_user')}
          </PrimaryButton>
        </div>
      </div>

      <UsersTable />

      <AddUserModal
        isOpen={showAddModal}
        onClose={() => setShowAddModal(false)}
      />
    </UsersListStyled>
  );
};

export default UsersList;
