import React, { useEffect, useMemo, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { PathNames } from 'applicaiton/routes';
import {
  Checkbox,
  DatePicker,
  Dropdown,
  Input,
  Loader,
  PrimaryButton,
  SecondaryButton,
} from 'common/components';
import {
  ButtonsWrapper,
  CheckboxWrapper,
  Content,
  TimePickerWrapper,
  SwitchStyled,
  PatientSection,
  StyledPlusIcon,
  CreateNewPatientLabel,
  CreateNewPatient,
  StyledDivider,
  Confusing,
  DateSection,
  Tabs,
  Tab,
  BottomActions,
  CheckboxLabel,
} from './styles';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import dayjs from 'dayjs';
import TimePicker from 'common/components/TimePicker';
import { Option } from 'common/components/DropdownProfessionals/models';
import DropdownAppointmentTypes from 'common/components/DropdownAppointmentTypes';
import Switch from 'common/components/Switch';
import { InputWrapper } from '../CreateAbsence/styles';
import {
  createAppointment,
  editAppointment,
  fetchAppointmentById,
} from 'applicaiton/store/reducers/Appointments/ActionCreators';
import PatientSearch from '../PatientSearch';
import CreatePatient from '../CreatePatient';
import SelectedPatientInfo from '../SelectedPatientInfo';
import { useCreateAppointment } from '../../hooks/useCreateAppointment';
import { useCreatePatient } from '../../hooks/useCreatePatient';
import {
  createFamilyMember,
  createPatient,
} from 'applicaiton/store/reducers/Patients/ActionCreators';
import { ReactComponent as WarningIcon } from 'applicaiton/assets/warning.svg';
import { fetchConfusingAgenda } from 'applicaiton/store/reducers/ConfusingAgenda/ActionCreators';
import LeavePageModal from 'features/feature-edit-profile-settings/component/LeavePageModal';
import {
  setCurrentFamilyMemberSearch,
  setCurrentPatient,
  setIsHiddenShowProfile,
} from 'applicaiton/store/reducers/Appointments/AppoinmentsSlice';
import AppointmentDocuments from '../AppointmentDocuments';
import { getUserId, getUserRole } from 'applicaiton/sessionStorage/auth';
import { UserRoles } from 'applicaiton/constants/userRoles';
import { ReactComponent as HomeIcon } from 'applicaiton/assets/home.svg';
import { setIsAddNewEvent } from 'applicaiton/store/reducers/Scheduler/SchedulerSlice';
import {
  fetchProfessionalById,
  fetchProfessionals,
} from 'applicaiton/store/reducers/Professionals/ActionCreators';
import { setHasUnsavedChanges } from 'applicaiton/store/reducers/DetectChangesSaved/DetectChangesSavedSlice';
import VerifyEditingAppointmentModal from '../VerifyEditingAppointmentModal';
import { AppointmentTypeOption } from 'common/components/DropdownAppointmentTypes/models';
import { userHasPermission } from 'common/helpers/userHasPermission';
import { UserPermissions } from 'applicaiton/constants/userPermissions';
import ErrorConfusingModal from 'features/feature-scheduler/components/ErrorConfusingModal';
import {
  setConfused,
  setShowConfusingAgendaErrorModal,
} from 'applicaiton/store/reducers/ConfusingAgenda/ConfusingAgendaSlice';
import { setCurrentAppointment } from 'applicaiton/store/reducers/Appointments/PatientAppoinmentsSlice';
import {
  AppointmentUpdateResponseDto,
  CommonEntitiesListResponse,
  PatientBriefCreationResponseDto,
  PatientResponseDto,
  ProfessionalResponseDto,
} from '@docbay/schemas';
import { ReserveSlotsMode } from 'applicaiton/constants/reserveSlots';
import PatientAlreadyExistModal from '../PatientAlreadyExistModal';
import AppointmentTypeDeletedBanner from '../AppointmentTypeDeletedBanner';
import { dateWithoutTimezone } from 'common/helpers/dateWithoutTimezone';
import { TimeZone } from 'applicaiton/constants/timeZone';
import { checkValidDate } from 'features/feature-reserve-slots-modal/helpers/checkValidDate';
import {
  addMinutes,
  getDateInDefaultTimezone,
  getTimeWithRoundedMinutes,
  updateDateAndTime,
} from 'common/helpers/dateTimeHelper';
import SelectedMemberInfo from '../SelectedMemberInfo';
import CreateFamilyMember from '../CreateFamilyMember';
import { useCreateFamilyMember } from '../../hooks/useCreateFamilyMember';

const tz = TimeZone.Lisbon;
interface Props {
  onClose: () => void;
  setIsContinueSettings: (value: boolean) => void;
}

export enum ViewBy {
  byProfessionals = 'byProfessionals',
  byAvailability = 'byAvailability',
}

const CreateAppointment = ({ onClose, setIsContinueSettings }: Props) => {
  const userRole = getUserRole();
  const userId = getUserId();
  const location = useLocation();
  const isWaitingListPage = location.pathname.includes('waiting-list');
  const isMyAgendaPage = location.pathname === PathNames.clinicOwnerSchedule;
  const isUserRoleProfessional = userRole === UserRoles.professional;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { viewBy, byAvailability, byProfessionals, professionals } =
    useAppSelector((state) => state.scheduler);

  const isViewByProfessionals = viewBy === ViewBy.byProfessionals;
  const blockSaveRef = useRef(false);
  const clinicId = isViewByProfessionals
    ? byProfessionals.clinicId
    : byAvailability.clinicId;

  const {
    isLoading: isLoadingAppointment,
    currentPatient,
    currentAppointment,
    appointmentId,
    startDate,
    professionalId,
    mode,
    waitingStartDate,
    currentFamilyMemberSearch,
  } = useAppSelector((state) => state.appointmentsSlice);

  const { confusingAgendas, showConfusingAgendaErrorModal } = useAppSelector(
    (state) => state.confusingAgendasSlice,
  );
  const { appointmentDocuments, isLoading: documentLoading } = useAppSelector(
    (state) => state.appointmentDocumentsSlice,
  );
  const { editedProfessional } = useAppSelector(
    (state) => state.professionalProfile,
  );
  const { isLoading, professionals: professionalsForCurrentAppointment } =
    useAppSelector((state) => state.professionals);

  const [showModal, setShowModal] = useState(false);
  const [showVerifyModal, setShowVerifyModal] = useState(false);
  const [comment, setComment] = useState<string>('');
  const [appointmentType, setAppointmentType] =
    useState<AppointmentTypeOption | null>(null);
  const [chosenProfessionals, setChosenProfessionals] = useState<Option>();
  const [addToWaitingList, setAddToWaitingList] = useState(false);
  const [appointmentStartDate, setAppointmentStartDate] = useState<Date | null>(
    null,
  );
  const [appointmentEndDate, setAppointmentEndDate] = useState<Date | null>(
    null,
  );
  const [openCreatePatient, setOpenCreatePatient] = useState(false);
  const [clinicOwnerIdBySelectedClinic, setClinicOwnerIdBySelectedClinic] =
    useState<string | null>(null);
  const [currentClinicId, setCurrentClinicId] = useState<string>('');
  const [showPatientModal, setShowPatientModal] = useState(false);
  const [appointmentTypeDeleted, setAppointmentTypeDeleted] = useState(false);
  const [activeCreationTab, setActiveCreationTab] = useState('patient');
  const [openCreateFamilyMember, setOpenCreateFamilyMember] = useState(false);
  const [isCreateAppointmentForFM, setIsCreateAppointmentForFM] =
    useState(false);

  const isWaitingListEnabled = useMemo(() => {
    if (isWaitingListPage)
      return currentAppointment?.clinic?.isWaitingListEnabled;

    const currentProfessionalData = professionalsForCurrentAppointment.find(
      (professional) => professional.id === chosenProfessionals?.value,
    );

    const currentClinicData = currentProfessionalData?.clinicsRelations?.find(
      (clinic) => clinic.clinic?.id === clinicId,
    );

    return currentClinicData?.clinic?.isWaitingListEnabled;
  }, [clinicId, professionalsForCurrentAppointment, chosenProfessionals]);

  const isBookAgainMode = mode === ReserveSlotsMode.BookAgain;
  const isEditMode = mode === ReserveSlotsMode.EditAppointment;
  const isCreateMode =
    mode === ReserveSlotsMode.CreateAppointment ||
    mode === ReserveSlotsMode.BookAgain;

  const currentViewData = useMemo(() => {
    return viewBy === ViewBy.byProfessionals ? byProfessionals : byAvailability;
  }, [viewBy, byAvailability, byProfessionals]);

  const canCreateAppointments = userHasPermission(
    UserPermissions.createAppointment,
    Number(clinicOwnerIdBySelectedClinic),
    isMyAgendaPage,
  );

  const {
    setValue,
    errors,
    isValid: isCreateAppointmentValid,
    handleSubmit,
    clearErrors,
    getValues,
    trigger,
    setError,
  } = useCreateAppointment({
    requiredError: t('errors.required'),
  });

  useEffect(() => {
    return () => {
      dispatch(setHasUnsavedChanges(false));
    };
  }, []);

  useEffect(() => {
    if (appointmentId) {
      dispatch(fetchAppointmentById(String(appointmentId)));
    }
  }, [appointmentId]);

  useEffect(() => {
    if ((isUserRoleProfessional || isMyAgendaPage) && professionalId) {
      dispatch(fetchProfessionalById(String(professionalId)));
    }
  }, [professionalId]);

  useEffect(() => {
    if (editedProfessional) {
      setChosenProfessionals({
        label: editedProfessional.firstName + ' ' + editedProfessional.lastName,
        value: editedProfessional.id,
        image: editedProfessional.mainPhoto?.thumbnailUrl,
        specializations: editedProfessional.specializations || [],
      });
    }
  }, [editedProfessional]);

  const {
    watchPatient,
    getValuesPatient,
    setValuePatient,
    errorsPatient,
    registerPatient,
    handleSubmitPatient,
    clearErrorsPatient,
    isDirtyPatient,
    resetPatient,
  } = useCreatePatient({
    requiredError: t('errors.required'),
    emailError: t('errors.emailFormat'),
    numberError: t('errors.number_error'),
    lengthFinError: t('errors.lengthFinError'),
    lengthSsnError: t('errors.lengthSsnError'),
    phoneError: t('errors.phone_invalid'),
  });

  const {
    watchFM,
    getValuesFM,
    setValueFM,
    errorsFM,
    registerFM,
    // handleSubmitFM,
    clearErrorsFM,
    resetFm,
    // isDirtyFM,
  } = useCreateFamilyMember({
    requiredError: t('errors.required'),
    emailError: t('errors.emailFormat'),
    numberError: t('errors.number_error'),
    lengthFinError: t('errors.lengthFinError'),
    lengthSsnError: t('errors.lengthSsnError'),
  });

  const getProfessionalsList = async (clinicIds?: string) => {
    const response = await dispatch(
      fetchProfessionals({
        ...(clinicIds ? { clinicIds } : {}),
        onlyActive: true,
      }),
    );
    if (response.meta.requestStatus === 'fulfilled') {
      const professionalsList =
        response.payload as CommonEntitiesListResponse<ProfessionalResponseDto>;

      const currentProfessional = professionalsList.entities.find(
        (professional) =>
          professional.id === currentAppointment?.professional?.id ||
          professional.id === professionalId,
      );
      if (currentProfessional) {
        setChosenProfessionals({
          label:
            currentProfessional.firstName + ' ' + currentProfessional.lastName,
          value: currentProfessional.id,
          image: currentProfessional.mainPhoto?.thumbnailUrl,
          specializations: currentProfessional.specializations || [],
        });
      }
    }
  };

  useEffect(() => {
    if (currentAppointment) {
      getProfessionalsList(currentAppointment?.clinic?.id ?? '');
      const appointmentTypeOption = {
        label: currentAppointment.appointmentType.title,
        value: currentAppointment.appointmentType.id,
        colorCode: currentAppointment.appointmentType.colorCode,
        duration: currentAppointment.appointmentType.durationInMinutes,
        specializationIds: currentSpecializationIds,
      };
      let startDateAppointment = isBookAgainMode
        ? (dayjs
            .tz(getTimeWithRoundedMinutes(dayjs().toDate()), tz)
            .format() as any)
        : currentAppointment.startDate;
      startDateAppointment = waitingStartDate ?? startDateAppointment;
      setAppointmentType(appointmentTypeOption);
      setValue('appointmentTypeId', currentAppointment.appointmentType.id);
      setValue('patientId', currentAppointment.patient?.id ?? '');
      setAppointmentStartDate(startDateAppointment);
      if (isBookAgainMode || waitingStartDate) {
        const calcEndDate = addMinutes(
          startDateAppointment,
          currentAppointment?.appointmentType?.durationInMinutes || 0,
        );
        setAppointmentEndDate(dayjs.tz(calcEndDate).format() as any);
      } else {
        setAppointmentEndDate(currentAppointment.endDate);
      }
      setCurrentClinicId(currentAppointment?.clinic?.id ?? '');
      currentAppointment.comment && setComment(currentAppointment.comment);
      setAddToWaitingList(!!currentAppointment?.addedToWaitingListDate);
    }
  }, [currentAppointment]);

  const clinicsOptions = useMemo(() => {
    if (!editedProfessional?.clinicsRelations?.length) return [];
    return editedProfessional?.clinicsRelations
      .filter((item) => item.clinic?.status === 'Active')
      .map((item) => ({
        label: String(item?.clinic?.name),
        value: String(item?.clinic?.id),
      }));
  }, [editedProfessional]);

  const handleSelectClinic = (clinicId: string) => {
    const selectedClinic = editedProfessional?.clinicsRelations?.find(
      (relation) => relation.clinic?.id === clinicId,
    );
    setClinicOwnerIdBySelectedClinic(selectedClinic?.clinic?.owner?.id || null);
    setCurrentClinicId(clinicId);
    setAppointmentType(null);
    setValue('appointmentTypeId', '');
  };

  const currentSpecializationIds = useMemo(() => {
    const specializationIds = chosenProfessionals?.specializations
      ?.map((item) => item.id)
      .join();
    return specializationIds || '';
  }, [chosenProfessionals]);

  const hasConfusingAgendas = useMemo(() => {
    const confusingEvents = [
      ...(confusingAgendas?.absences || []),
      ...(confusingAgendas?.appointments?.filter(
        (appointment) => appointment.id !== currentAppointment?.id,
      ) || []),
    ];
    return !!confusingEvents.length;
  }, [confusingAgendas]);

  const professionalsOptions = useMemo(() => {
    return professionalsForCurrentAppointment.map((item) => ({
      value: String(item.id),
      label: item.firstName + ' ' + item.lastName,
      image: item.mainPhoto?.thumbnailUrl,
      specializations: item.specializations || [],
    }));
  }, [professionals, professionalsForCurrentAppointment]);

  useEffect(() => {
    if (appointmentStartDate && chosenProfessionals) {
      if (currentClinicId || currentViewData?.clinicId) {
        dispatch(
          fetchConfusingAgenda({
            data: {
              startDate: dayjs(appointmentStartDate).toDate(),
              endDate: dayjs(appointmentEndDate).toDate(),
              professionalId: isUserRoleProfessional
                ? String(userId)
                : String(chosenProfessionals?.value)!,
              ...(isMyAgendaPage
                ? {}
                : { clinicId: currentClinicId || currentViewData?.clinicId }),
            },
            isOnSave: false,
          }),
        );
      }
    }
  }, [
    currentClinicId,
    appointmentStartDate,
    chosenProfessionals,
    currentViewData,
    appointmentEndDate,
  ]);

  useEffect(() => {
    if (currentViewData.clinicId && !isEditMode) {
      getProfessionalsList(!isMyAgendaPage ? currentViewData.clinicId : '');
      if (!isUserRoleProfessional && !isMyAgendaPage) {
        setCurrentClinicId(currentViewData.clinicId);
      }
    }
  }, [currentViewData]);

  useEffect(() => {
    if (startDate && !currentAppointment) {
      const startDateAppointment = getTimeWithRoundedMinutes(startDate);
      setAppointmentStartDate(
        dayjs.tz(dateWithoutTimezone(startDateAppointment), tz).format() as any,
      );
      setAppointmentEndDate(
        dayjs.tz(dateWithoutTimezone(startDateAppointment), tz).format() as any,
      );
    }
  }, [startDate]);

  useEffect(() => {
    if (waitingStartDate) {
      const startDateAppointment = getTimeWithRoundedMinutes(waitingStartDate);

      setAppointmentStartDate(
        dayjs.tz(dateWithoutTimezone(startDateAppointment), tz).format() as any,
      );
    }
  }, [waitingStartDate]);

  const onSave = async ({ patientId }: { patientId?: string | null }) => {
    if (!currentClinicId) return;
    const data = await dispatch(
      fetchConfusingAgenda({
        data: {
          startDate: dayjs(appointmentStartDate).toDate(),
          endDate: dayjs(appointmentEndDate).toDate(),
          professionalId: isUserRoleProfessional
            ? String(userId)
            : String(chosenProfessionals?.value)!,
          ...(isMyAgendaPage
            ? {}
            : { clinicId: currentClinicId || currentViewData?.clinicId }),
        },
        isOnSave: true,
      }),
    );
    const currentAppointment = data?.payload?.appointments?.length
      ? data.payload.appointments[0]
      : null;
    dispatch(setCurrentAppointment(currentAppointment));

    if (
      data.payload?.appointments?.length ===
      confusingAgendas?.appointments?.length
    ) {
      await saveAppointment(patientId);
    }
  };

  //method for blocking multiple calls of save, isLoading not working properly because it has a delay
  const onSaveWithBlock = ({ patientId }: { patientId?: string | null }) => {
    if (blockSaveRef.current) {
      return;
    }
    blockSaveRef.current = true;
    onSave({ patientId }).finally(() => {
      blockSaveRef.current = false;
    });
  };

  const continueBooking = () => {
    dispatch(setShowConfusingAgendaErrorModal(false));
  };

  const saveAppointment = async (patientId?: string | null) => {
    await dispatch(
      createAppointment({
        startDate: dayjs(appointmentStartDate).toDate(),
        endDate: dayjs(appointmentEndDate).toDate(),
        professionalId: String(chosenProfessionals?.value)!,
        appointmentTypeId: String(appointmentType?.value),
        patientId: patientId || String(currentPatient?.id)!,
        clinicId: currentClinicId,
        comment: comment,
        ...(appointmentDocuments.length && { documents: appointmentDocuments }),
        addToWaitingList: addToWaitingList ? 'true' : 'false',
        ...(!currentFamilyMemberSearch?.isPatient
          ? { bookedForMemberId: currentFamilyMemberSearch?.id }
          : {}),
      }),
    ).then((data) => {
      if (data.meta.requestStatus === 'fulfilled') {
        dispatch(setCurrentPatient(null));
        dispatch(setCurrentFamilyMemberSearch(null));
        dispatch(setIsAddNewEvent(true));
        dispatch(setShowConfusingAgendaErrorModal(false));
        if (hasConfusingAgendas) {
          setIsContinueSettings(true);
        }
      }
    });
  };

  const handleCreatePatient = async () => {
    const res = getValuesPatient();

    const patientResponse = await dispatch(
      createPatient({
        firstName: res.firstName as string,
        lastName: res.lastName as string,
        gender: res.gender,
        phone: `+${res.phone}` as string,
        dateOfBirth: dayjs(res.dateOfBirth).utc(true).format(),
        ...(res.email.length ? { email: res.email } : {}),
        pronunciation: res.gender,
        ...(res.fin ? { nif: res.fin } : {}),
        ...(res.ssn ? { socialSecurityNumber: res.ssn } : {}),
      }),
    );
    if (patientResponse.meta.requestStatus === 'fulfilled') {
      const response =
        patientResponse.payload as PatientBriefCreationResponseDto;
      dispatch(setCurrentPatient(response.patient));
      setValue('patientId', response.patient.id);
      if (response.existed) {
        setShowPatientModal(true);
        setOpenCreatePatient(false);
        resetPatient();
        dispatch(setIsHiddenShowProfile(response.isCurrentUserHasAccess));
        return dispatch(setCurrentPatient(response.patient));
      }
      await trigger().then((isValid) => {
        if (isValid) {
          onSave({ patientId: response.patient.id });
        }
        setOpenCreatePatient(false);
      });
    }
  };

  const handleCreateFamilyMember = async () => {
    const res = getValuesPatient();

    const patientResponse = await dispatch(
      createPatient({
        firstName: res.firstName as string,
        lastName: res.lastName as string,
        gender: res.gender,
        phone: `+${res.phone}` as string,
        dateOfBirth: dayjs(res.dateOfBirth).utc(true).format(),
        ...(res.email.length ? { email: res.email } : {}),
        pronunciation: res.gender,
        ...(res.fin ? { nif: res.fin } : {}),
        ...(res.ssn ? { socialSecurityNumber: res.ssn } : {}),
      }),
    );
    if (patientResponse.meta.requestStatus === 'fulfilled') {
      const response =
        patientResponse.payload as PatientBriefCreationResponseDto;
      const {
        firstName,
        lastName,
        gender,
        relationship,
        dateOfBirth,
        ssn,
        fin,
      } = getValuesFM();
      dispatch(
        createFamilyMember({
          patientId: response.patient!.id!,
          data: {
            firstName: String(firstName),
            lastName: String(lastName),
            relationship: String(relationship),
            dateOfBirth: dayjs(dateOfBirth).utc(true).format(),
            pronunciation: gender,
            ...(fin ? { nif: String(fin) } : {}),
            ...(ssn ? { socialSecurityNumber: String(ssn) } : {}),
          }!,
        }),
      ).then((data) => {
        if (data.meta.requestStatus === 'fulfilled') {
          const fmResponse = data.payload as PatientResponseDto;

          dispatch(
            createAppointment({
              startDate: dayjs(appointmentStartDate).toDate(),
              endDate: dayjs(appointmentEndDate).toDate(),
              professionalId: String(chosenProfessionals?.value)!,
              appointmentTypeId: String(appointmentType?.value),
              patientId: response.patient!.id! || String(currentPatient?.id)!,
              clinicId: currentClinicId,
              comment: comment,
              ...(appointmentDocuments.length && {
                documents: appointmentDocuments,
              }),
              addToWaitingList: addToWaitingList ? 'true' : 'false',
              ...(isCreateAppointmentForFM
                ? { bookedForMemberId: fmResponse?.id }
                : {}),
            }),
          ).then((data) => {
            if (data.meta.requestStatus === 'fulfilled') {
              dispatch(setCurrentPatient(null));
              dispatch(setCurrentFamilyMemberSearch(null));
              dispatch(setIsAddNewEvent(true));
              dispatch(setShowConfusingAgendaErrorModal(false));
              if (hasConfusingAgendas) {
                setIsContinueSettings(true);
              }
            }
          });
        }
      });
    }
  };

  const isChanged = useMemo(() => {
    if (!currentAppointment) return false;

    const hasChanges =
      currentClinicId !== currentAppointment.clinic!.id ||
      appointmentType?.value !== currentAppointment.appointmentType.id ||
      !dayjs(currentAppointment.startDate).isSame(appointmentStartDate) ||
      !dayjs(currentAppointment.endDate).isSame(appointmentEndDate) ||
      comment !== currentAppointment.comment ||
      addToWaitingList !== !!currentAppointment.addedToWaitingListDate;

    dispatch(setHasUnsavedChanges(hasChanges));

    return hasChanges;
  }, [
    comment,
    appointmentStartDate,
    appointmentType,
    appointmentEndDate,
    addToWaitingList,
    currentFamilyMemberSearch,
    currentAppointment,
  ]);

  const handleClose = () => {
    const { patientId } = getValues();
    if (patientId && (isDirtyPatient || isChanged)) {
      setShowModal(true);
    } else {
      onClose();
    }
  };

  const handleSaveAfterEdit = () => {
    const editedData = {
      clinicId: currentClinicId,
      appointmentTypeId: String(appointmentType?.value),
      startDate: dayjs(appointmentStartDate).toDate(),
      endDate: dayjs(appointmentEndDate).toDate(),
      ...(comment !== currentAppointment?.comment ? { comment } : {}),
      addToWaitingList: addToWaitingList ? 'true' : 'false',
    };

    dispatch(
      editAppointment({
        id: String(currentAppointment?.id),
        data: editedData,
      }),
    ).then((data) => {
      dispatch(setIsAddNewEvent(true));
      setShowVerifyModal(false);
      if (hasConfusingAgendas) {
        const confusingAgendaData =
          data.payload as AppointmentUpdateResponseDto;

        dispatch(setConfused(confusingAgendaData.confusingAgenda!));
        setIsContinueSettings(true);
      }
    });
  };
  const formatDateStringInDefaultTimezone = (date: string) => {
    return dayjs.tz(date, tz).format();
  };

  const handleChangeDate = (value: string) => {
    const isValidDate = dayjs(value).isValid();
    if (isValidDate) {
      setAppointmentStartDate(formatDateStringInDefaultTimezone(value) as any);
      if (appointmentType) {
        const duration: number = appointmentType?.duration
          ? +appointmentType?.duration
          : 0;
        const endDateAppointment = addMinutes(
          new Date(formatDateStringInDefaultTimezone(value)),
          duration,
        );
        setAppointmentEndDate(dayjs.tz(endDateAppointment).format() as any);
      }
    }
  };

  const checkValidDateString = (dateString: string) => {
    const dateToCheck = dayjs(dateString);
    if (!dateToCheck.isValid()) {
      return false;
    }
    return dateToCheck.year() >= 1900;
  };

  const handleChangeStartDate = (value: string) => {
    if (!checkValidDateString(value)) {
      return;
    }
    const newDate = formatDateStringInDefaultTimezone(value).split('T')[0];
    if (appointmentStartDate) {
      const updateDateTime = updateDateAndTime(appointmentStartDate, newDate);
      setAppointmentStartDate(updateDateTime as any);
    } else {
      setAppointmentStartDate(dayjs.tz(value, tz).format() as any);
    }
    if (appointmentEndDate) {
      const updateDateTime = updateDateAndTime(appointmentEndDate, newDate);
      setAppointmentEndDate(updateDateTime as any);
    }
  };

  const handleChangeEndDate = (value: string) => {
    const isValidDate = dayjs(value).isValid();
    if (isValidDate) {
      setAppointmentEndDate(dayjs.tz(value, tz).format() as any);
    }
  };

  const handleChangeAppointmentType = (currentValue: AppointmentTypeOption) => {
    setAppointmentType(currentValue);
    setValue('appointmentTypeId', String(currentValue.value));
    clearErrors('appointmentTypeId');
    appointmentTypeDeleted && setAppointmentTypeDeleted(false);
    if (appointmentStartDate) {
      const duration: number = currentValue?.duration
        ? +currentValue?.duration
        : 0;
      const endDateAppointment = addMinutes(appointmentStartDate, duration);
      setAppointmentEndDate(dayjs.tz(endDateAppointment).format() as any);
    }
  };

  const deleteAppointmentType = () => {
    setAppointmentType(null);
    setValue('appointmentTypeId', '');
    setAppointmentTypeDeleted(!!currentAppointment?.appointmentType?.id);
  };

  const isDateValid = checkValidDate(
    dayjs(new Date()).toDate(),
    dayjs(appointmentStartDate).toDate(),
  );

  const minAppointmentEndTime = useMemo(() => {
    const startDateInTz = getDateInDefaultTimezone(appointmentStartDate);
    return startDateInTz.add(5, 'minute');
  }, [appointmentStartDate]);

  const isCreateButtonDisabled = useMemo(() => {
    return (
      !!Object.keys(errorsPatient).length || !isDateValid || documentLoading
    );
  }, [
    watchPatient(),
    isDateValid,
    documentLoading,
    currentFamilyMemberSearch,
    appointmentType,
  ]);

  const isFamilyMemberCreateButtonDisabled = useMemo(() => {
    return (
      !!Object.keys(errorsPatient).length || !isDateValid || documentLoading
    );
  }, [errorsFM]);

  const isSaveButtonDisabled = useMemo(() => {
    return !!Object.keys(errors).length || !isDateValid || documentLoading;
  }, [
    isDateValid,
    documentLoading,
    isCreateAppointmentValid,
    appointmentType,
    errors,
  ]);

  const isEditButtonDisabled = useMemo(() => {
    return !isChanged || !isDateValid || documentLoading;
  }, [isChanged, isDateValid, documentLoading]);

  const getDayjsFromDateForTimePicker = (date: Date | null) => {
    const dayInTz = dayjs.tz(date, tz);
    return dayInTz.isUTC() ? dayInTz : dayjs(date).tz(tz);
  };

  const handleAddFamilyMember = () => {
    if (!appointmentType) {
      setError('appointmentTypeId', { message: t('errors.required') || '' });
    } else {
      setOpenCreateFamilyMember(true);
      setActiveCreationTab('familyMember');
    }
  };

  const handleResetPatient = () => {
    resetPatient();
    resetFm();
    setActiveCreationTab('patient');
    setOpenCreateFamilyMember(false);
    openCreatePatient && setOpenCreatePatient(false);
  };

  return (
    <div className={`${isLoading || isLoadingAppointment ? 'no-scroll' : ''}`}>
      {(isLoading || isLoadingAppointment) && <Loader />}
      <LeavePageModal
        showModal={showModal}
        onClose={() => {
          setShowModal(false);
          dispatch(setCurrentPatient(null));
        }}
        onSubmit={onClose}
      />
      <Content>
        {!isUserRoleProfessional && !isMyAgendaPage && (
          <div>
            <Dropdown
              id={'professionals'}
              label={'Professionals'}
              placeholder={'Select Professional'}
              value={(chosenProfessionals?.value as string) || ''}
              onChange={(value) => {
                setChosenProfessionals(value as Option);
              }}
              options={professionalsOptions}
              disabled={isEditMode}
            />
          </div>
        )}
        {(isUserRoleProfessional || isMyAgendaPage) && (
          <Dropdown
            id={'hospital'}
            label={t('clinic') || ''}
            value={currentClinicId}
            onChange={(value) =>
              !Array.isArray(value) && handleSelectClinic(String(value.value))
            }
            placeholder={t('all_clinics')}
            options={clinicsOptions}
            LeftIcon={HomeIcon}
          />
        )}

        <DateSection>
          <DatePicker
            id={'dateStart'}
            label={t('date') || ''}
            format={'YYYY-MM-DDTHH:mm'}
            value={dayjs
              .tz(appointmentStartDate, tz)
              .format('YYYY-MM-DDTHH:mm')}
            onChange={(value) => {
              handleChangeStartDate(value);
            }}
            maxDate={new Date(
              new Date().setFullYear(new Date().getFullYear() + 3),
            ).getTime()}
            minDate={dayjs(startDate).valueOf()}
            isError={!isDateValid}
          />
          <TimePickerWrapper>
            <label>{t('time_start')}</label>
            <TimePicker
              key="startDate"
              defaultValue={dayjs()}
              value={getDayjsFromDateForTimePicker(appointmentStartDate)}
              onChange={(value) => {
                handleChangeDate(value || '');
              }}
              minTime={
                dayjs(appointmentStartDate).format('DD') ===
                dayjs().format('DD')
                  ? dayjs.tz(dayjs().toDate(), tz)
                  : null
              }
              disableIgnoringDatePartForTimeValidation={false}
              isError={false}
            />
          </TimePickerWrapper>
          <TimePickerWrapper>
            <label>{t('time_end')}</label>
            <TimePicker
              key="endDate"
              defaultValue={dayjs()}
              value={getDayjsFromDateForTimePicker(appointmentEndDate)}
              onChange={(value) => {
                handleChangeEndDate(value || '');
              }}
              minTime={minAppointmentEndTime}
              disableIgnoringDatePartForTimeValidation={false}
              disabled={!appointmentType?.value}
              isError={false}
            />
          </TimePickerWrapper>
        </DateSection>
        {appointmentTypeDeleted && <AppointmentTypeDeletedBanner />}
        <div>
          <DropdownAppointmentTypes
            label={t('appointment_types.appointment_type')!}
            value={appointmentType as AppointmentTypeOption}
            specializationIds={currentSpecializationIds}
            onChange={(value) => {
              const currentValue = value as AppointmentTypeOption;
              clearErrors('appointmentTypeId');
              handleChangeAppointmentType(currentValue);
            }}
            clinicId={currentClinicId || currentViewData.clinicId}
            professionalId={
              chosenProfessionals?.value
                ? String(chosenProfessionals?.value)
                : null
            }
            withSearch
            isError={!!errors?.appointmentTypeId?.message}
            errorMessage={errors?.appointmentTypeId?.message}
            disabled={!currentClinicId}
            setIsAppointmentDeleted={deleteAppointmentType}
          />
        </div>
        {hasConfusingAgendas && (
          <Confusing>
            <WarningIcon />
            {t('patient.confusing')}
          </Confusing>
        )}

        {(isEditMode || isBookAgainMode) && !!currentPatient && (
          <SelectedPatientInfo />
        )}
        {!isEditMode && !isBookAgainMode && (
          <>
            <PatientSection>
              <PatientSearch
                errors={errors}
                setValue={setValue}
                handleResetPatient={handleResetPatient}
              />
            </PatientSection>
            {!!currentFamilyMemberSearch && <SelectedMemberInfo />}
            {!!currentPatient && !currentFamilyMemberSearch && (
              <SelectedPatientInfo />
            )}
            {!openCreatePatient && (
              <CreateNewPatientLabel disable={!!currentFamilyMemberSearch}>
                <span
                  onClick={() =>
                    !currentFamilyMemberSearch && setOpenCreatePatient(true)
                  }
                >
                  <StyledPlusIcon /> {t('patient.create_patient')}
                </span>
              </CreateNewPatientLabel>
            )}

            {openCreatePatient && (
              <>
                <Tabs>
                  <Tab
                    isActive={activeCreationTab === 'patient'}
                    onClick={() => setActiveCreationTab('patient')}
                  >
                    {t('patient.new_patient')}
                  </Tab>
                  {openCreateFamilyMember && (
                    <Tab
                      isActive={activeCreationTab === 'familyMember'}
                      onClick={() => setActiveCreationTab('familyMember')}
                    >
                      {t('patient.new_family_member')}
                    </Tab>
                  )}
                </Tabs>
                {activeCreationTab === 'patient' && (
                  <CreateNewPatient>
                    <CreatePatient
                      setValuePatient={setValuePatient}
                      errorsPatient={errorsPatient}
                      getValuesPatient={getValuesPatient}
                      registerPatient={registerPatient}
                      clearErrorsPatient={clearErrorsPatient}
                      watchPatient={watchPatient}
                    />
                    <BottomActions>
                      <CreateNewPatientLabel>
                        <span
                          onClick={() => {
                            resetPatient();
                            setOpenCreatePatient(false);
                          }}
                        >
                          {t('patient.cancel_create_patient')}
                        </span>
                      </CreateNewPatientLabel>
                      <CreateNewPatientLabel>
                        <span
                          onClick={handleSubmitPatient(handleAddFamilyMember)}
                        >
                          <StyledPlusIcon /> {t('patient.create_family_member')}
                        </span>
                      </CreateNewPatientLabel>
                    </BottomActions>
                    <StyledDivider />
                  </CreateNewPatient>
                )}
                {activeCreationTab === 'familyMember' && (
                  <CreateNewPatient>
                    <StyledDivider />
                    <CreateFamilyMember
                      setValuePatient={setValueFM}
                      errorsPatient={errorsFM}
                      getValuesPatient={getValuesFM}
                      registerPatient={registerFM}
                      clearErrorsPatient={clearErrorsFM}
                      watchPatient={watchFM}
                    />
                    <CreateNewPatientLabel>
                      <span
                        onClick={() => {
                          resetFm();
                          setIsCreateAppointmentForFM(false);
                          setActiveCreationTab('patient');
                          setOpenCreateFamilyMember(false);
                        }}
                      >
                        {t('patient.cancel_create_family_member') || ''}
                      </span>
                    </CreateNewPatientLabel>
                    <CheckboxLabel>
                      <Checkbox
                        checked={isCreateAppointmentForFM!}
                        id={'isCreateAppointmentForFM'}
                        onChange={() => {
                          setIsCreateAppointmentForFM((prev) => !prev);
                        }}
                        label={t('patient.create_appointment_for_FM')!}
                      />
                    </CheckboxLabel>
                    <StyledDivider />
                  </CreateNewPatient>
                )}
              </>
            )}
          </>
        )}

        {isWaitingListEnabled && (
          <CheckboxWrapper>
            <label>{t('appointment_types.waiting_list')}</label>
            <SwitchStyled>
              <Switch
                checked={addToWaitingList}
                onChange={(value) => {
                  setAddToWaitingList(value);
                }}
              />
              <p>
                {t('appointment_types.add_to_waiting_list')}{' '}
                {/* will uncommented later */}
                {/*<span>More information</span>*/}
              </p>
            </SwitchStyled>
          </CheckboxWrapper>
        )}

        <div>
          <InputWrapper>
            <Input
              id="comments"
              label={t('comments') || ''}
              type="text"
              value={comment}
              placeholder={t('comments_placeholder') || ''}
              onChange={(value) => {
                setComment(value.target.value);
              }}
            />
          </InputWrapper>
        </div>
        {!isEditMode && <AppointmentDocuments isEditMode={isEditMode} />}
      </Content>

      <ButtonsWrapper>
        <SecondaryButton onClick={handleClose} disabled={documentLoading}>
          {t('cancel')}
        </SecondaryButton>
        {openCreatePatient &&
          !isEditMode &&
          activeCreationTab !== 'familyMember' && (
            <PrimaryButton
              onClick={handleSubmitPatient(handleCreatePatient)}
              disabled={isCreateButtonDisabled}
            >
              {t('save')}
            </PrimaryButton>
          )}
        {openCreatePatient &&
          activeCreationTab === 'familyMember' &&
          !isEditMode && (
            <PrimaryButton
              onClick={handleSubmitPatient(handleCreateFamilyMember)}
              disabled={isFamilyMemberCreateButtonDisabled}
            >
              {t('save')}
            </PrimaryButton>
          )}
        {isCreateMode && canCreateAppointments && !openCreatePatient && (
          <PrimaryButton
            onClick={handleSubmit(onSaveWithBlock)}
            disabled={isSaveButtonDisabled}
          >
            {t('save')}
          </PrimaryButton>
        )}
        {isEditMode && (
          <PrimaryButton
            onClick={handleSubmit(() => setShowVerifyModal(true))}
            disabled={isEditButtonDisabled}
          >
            {t('save')}
          </PrimaryButton>
        )}
      </ButtonsWrapper>
      <VerifyEditingAppointmentModal
        isOpen={showVerifyModal}
        patientName={`${currentAppointment?.patient?.firstName} ${currentAppointment?.patient?.lastName}`}
        startDate={dayjs(appointmentStartDate).toDate()}
        oldDate={dayjs(currentAppointment?.startDate).toDate()}
        onClose={() => setShowVerifyModal(false)}
        onSubmit={handleSaveAfterEdit}
        isLoading={isLoadingAppointment}
      />
      {showConfusingAgendaErrorModal && (
        <ErrorConfusingModal
          onClose={() => setShowConfusingAgendaErrorModal(false)}
          continueBooking={continueBooking}
          closeAppointmentModal={onClose}
        />
      )}
      <PatientAlreadyExistModal
        opened={showPatientModal}
        onClose={() => setShowPatientModal(false)}
      />
    </div>
  );
};

export default CreateAppointment;
