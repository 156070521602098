import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { PathNames } from 'applicaiton/routes';
import { setViewBy } from 'applicaiton/store/reducers/Scheduler/SchedulerSlice';
import { ViewBy } from 'applicaiton/constants/scheduler';
import { resetAgenda } from 'applicaiton/store/reducers/Agenda/AgendaSlice';
import { useAppSelector, useAppDispatch } from 'common/hooks/redux';
import FilterByDoctor from '../FilterByDoctor';
import FilterByAvailability from '../FilterByAvailability';
import { Wrapper, ButtonGroup, ButtonStyled } from './styles';
import { getUserRole } from 'applicaiton/sessionStorage/auth';
import { UserRoles } from 'applicaiton/constants/userRoles';

const FilterSection: FC = () => {
  const userRole = getUserRole();
  const location = useLocation();
  const isUserRoleProfessional = userRole === UserRoles.professional;
  const isMyAgendaPage = location.pathname === PathNames.clinicOwnerSchedule;

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { viewBy } = useAppSelector((state) => state.scheduler);

  const { currentAppointment } = useAppSelector(
    (state) => state.patientAppointments,
  );

  useEffect(() => {
    handleChangeView(ViewBy.byProfessionals);
  }, [currentAppointment]);

  const handleChangeView = async (view: ViewBy) => {
    if (view === viewBy) return;
    dispatch(setViewBy(view));
    dispatch(resetAgenda());
  };

  if (isUserRoleProfessional || isMyAgendaPage) {
    return (
      <Wrapper>
        <FilterByDoctor />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <ButtonGroup>
        <ButtonStyled
          isActive={viewBy === ViewBy.byProfessionals}
          onClick={() => {
            handleChangeView(ViewBy.byProfessionals);
          }}
        >
          {t('scheduler.by_professionals')}
        </ButtonStyled>
        <ButtonStyled
          isActive={viewBy === ViewBy.byAvailability}
          onClick={() => {
            handleChangeView(ViewBy.byAvailability);
          }}
        >
          {t('scheduler.by_availability')}
        </ButtonStyled>
      </ButtonGroup>
      {viewBy === ViewBy.byProfessionals && <FilterByDoctor />}
      {viewBy === ViewBy.byAvailability && <FilterByAvailability />}
    </Wrapper>
  );
};

export default FilterSection;
