import { Accordion, AccordionDetails } from '@mui/material';
import styled from 'styled-components';

interface StyledProps {
  isActive: boolean;
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 281px;

  .MuiDateCalendar-root {
    width: 255px;
  }

  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
    &:first-child {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }
    &:last-child {
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    }

    margin: 0;
  }
  .MuiAccordion-root {
    box-shadow: none;
    border-radius: 0px;
    border: 1px solid ${({ theme }) => theme.color.grey300};
    border-bottom: 0;
    background: ${({ theme }) => theme.color.grey100};
    &.Mui-expanded {
      margin: 0;
    }
    &:last-child {
      border-bottom: 1px solid ${({ theme }) => theme.color.grey300};
    }
    &:before {
      display: none;
    }
    .MuiButtonBase-root.MuiAccordionSummary-root {
      min-height: 56px;
      padding: 12px;
      .MuiAccordionSummary-content {
        display: flex;
        flex-direction: column;
        margin: 0;

        > div:first-child {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 32px;
          > h2 {
            color: ${({ theme }) => theme.color.grey700};
            font-size: 16px;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0.1px;
          }
        }

        &.Mui-expanded {
          .pillsList {
            display: none;
          }
        }
      }
    }
    .MuiAccordionDetails-root {
      padding: 12px;
      padding-top: 0;
    }
  }
`;

export const AccordionStyled = styled(Accordion)`
  .MuiPickersFadeTransitionGroup-root {
    .MuiDayCalendar-header.MuiDayCalendar-header {
      width: 255px;
    }
    .MuiDayCalendar-weekContainer {
      width: 255px;
      > button {
        width: 38px;
        height: 38px;
      }
    }
  }
`;
export const AccordionDetailsStyled = styled(AccordionDetails)`
  > div:first-child {
    margin-bottom: 12px;
    > div {
      margin-top: 0;
    }
  }
`;

export const RadioButtonStyled = styled.div<StyledProps>`
  margin-top: 8px;
  > div {
    input[type='radio'] {
      height: 16px;
      width: 16px;
    }
    > label {
      color: ${({ theme }) => theme.color.grey800};
      font-size: 14px;
      line-height: 19.6px;
      font-weight: ${({ isActive }) => (isActive ? '700' : '400')};
      > span {
        color: ${({ theme }) => theme.color.grey500};
        font-size: 14px;
        font-weight: 400;
        line-height: 19.6px;
        letter-spacing: 0.035px;
      }
    }
  }
  &:first-child {
    margin-top: 0;
  }
`;

export const SpecializationsSection = styled.div`
  > div {
    margin-top: 0;
    > div:first-child {
      > div {
        margin-top: 0;
      }
    }
  }
`;

export const PillsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 12px;
  max-width: 255px;
  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2px 8px 2px 10px;
    border-radius: 16px;
    background: ${({ theme }) => theme.color.green100};
    margin: 0 6px 6px 0;
    color: var(--grey-900, #101828);
    font-size: 14px;
    font-weight: 500;
    line-height: 19.6px;
    letter-spacing: 0.25px;

    > svg {
      width: 12px;
      height: 12px;
      margin-left: 4px;
      g {
        path {
          fill: ${({ theme }) => theme.color.grey600};
        }
      }
    }
  }
`;
