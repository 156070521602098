import React, { FC } from 'react';

import Switch from 'common/components/Switch';

import { SettingItemProps } from './modules';
import { StyledTr } from './styles';
import { ViewBy } from 'features/feature-notifications-settings/constants';

const SettingItem: FC<SettingItemProps> = ({
  index,
  item,
  handleChangeNotifications,
  viewBy,
}) => {
  return (
    <StyledTr isColored={index % 2 === 0}>
      <td>
        <div>
          <p>{item.notificationType}</p>
        </div>
      </td>
      {viewBy !== ViewBy.Doctor && (
        <td>
          <div>
            <Switch
              checked={item.smsEnabled}
              onChange={() =>
                handleChangeNotifications({
                  ...item,
                  smsEnabled: !item.smsEnabled,
                })
              }
            />
          </div>
        </td>
      )}
      <td>
        <div>
          <Switch
            checked={item.pushEnabled}
            onChange={() =>
              handleChangeNotifications({
                ...item,
                pushEnabled: !item.pushEnabled,
              })
            }
          />
        </div>
      </td>
      <td>
        <div>
          <Switch
            checked={item.emailEnabled}
            onChange={() =>
              handleChangeNotifications({
                ...item,
                emailEnabled: !item.emailEnabled,
              })
            }
          />
        </div>
      </td>
    </StyledTr>
  );
};
export default SettingItem;
