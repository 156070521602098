import styled from 'styled-components';
import { ButtonGroupProps } from './modules';

export const FilterSectionStyled = styled.div`
  display: grid;
  gap: 4px;
  grid-template-rows: max-content;
  grid-template-columns: 220px;
  margin: 12px 64px 0 0;
  background: ${({ theme }) => theme.color.white};
`;

export const ButtonStyled = styled.button<ButtonGroupProps>`
  display: flex;
  height: 38px;
  padding: 8px 12px;
  border: none;
  border-radius: 6px;
  background: ${({ theme, isActive }) =>
    isActive ? theme.color.green100 : theme.color.white};
  cursor: pointer;

  color: ${({ isActive, theme }) =>
    isActive ? theme.color.grey800 : theme.color.grey700};
  font-size: 16px;
  font-style: normal;
  font-weight: ${({ isActive }) => (isActive ? '700' : '500')};
  line-height: 22.4px;

  &:hover {
    background: ${({ theme, isActive }) =>
      isActive ? theme.color.green100 : theme.color.grey100};
  }
`;
