import { isValidPhoneNumber } from 'libphonenumber-js';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { nifValidate } from 'common/helpers/nifValidate';

export const usePatientForm = () => {
  const { t } = useTranslation();
  const requiredError = t('errors.required') || '';
  const emailError = t('errors.email_must_be_email') || '';
  const phoneError = t('errors.phone_invalid') || '';
  const numberError = t('errors.number_error') || '';
  const lengthFinError = t('errors.lengthFinError') || '';
  const lengthSsnError = t('errors.lengthSsnError') || '';

  return yup.object({
    pronunciation: yup.string().required(requiredError!),
    firstName: yup.string().required(requiredError!),
    lastName: yup.string().required(requiredError!),
    phone: yup
      .string()
      .test({
        name: 'is-valid',
        test(value, ctx) {
          const isValidNumber = isValidPhoneNumber(`+${String(value)}`);
          if (!isValidNumber) return ctx.createError({ message: phoneError });
          return isValidNumber;
        },
      })
      .required(),
    dateOfBirth: yup.string().required(requiredError),
    email: yup.string().email(emailError),
    nif: yup.string().test({
      name: 'nif',
      test(value, ctx) {
        if (!value || value?.length === 0) return true;

        const isNum = nifValidate(value);

        if (value?.length !== 9) {
          return ctx.createError({ message: lengthFinError });
        } else if (!isNum) {
          return ctx.createError({ message: numberError });
        }
        return isNum;
      },
    }),
    socialSecurityNumber: yup.string().test({
      name: 'socialSecurityNumber',
      test(value, ctx) {
        if (!value || value?.length === 0) return true;

        const isNum = /^\d+$/.test(value!);

        if (!isNum) {
          return ctx.createError({ message: numberError });
        } else if (value?.length !== 11) {
          return ctx.createError({ message: lengthSsnError });
        }

        return isNum;
      },
    }),
    clinicName: yup.array(yup.string()).required(requiredError!),
  });
};
