import styled from 'styled-components';

export const Subscription = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  cursor: pointer;

  svg {
    margin-left: 8px;
    cursor: pointer;

    path {
      fill: ${({ theme }) => theme.color.grey500};
    }
  }
`;

export const Wrapper = styled.div`
  display: grid;
  gap: 8px;
  width: 580px;
  padding: 10px 14px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.color.grey300};

  > p {
    display: flex;
    gap: 4px;
    font-family: Mulish;
    font-size: 14px;
    font-weight: 700;
    line-height: 19.6px;
    color: ${({ theme }) => theme.color.grey900};

    > span {
      font-weight: 400;
      color: ${({ theme }) => theme.color.grey600};
    }
  }
`;
