import styled from 'styled-components';
import { ReactComponent as CloseIcon } from 'applicaiton/assets/close.svg';
import { ReactComponent as DeleteIcon } from 'applicaiton/assets/delete.svg';
import { ReactComponent as EditIcon } from 'applicaiton/assets/edit-pencil.svg';

interface Props {
  isActive: boolean;
}

export const StyledModal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
  display: flex;
  width: 664px;
  flex-direction: column;
  border-radius: 8px;
  background: ${({ theme }) => theme.color.white};
  box-shadow: 0 10px 45px 0 rgba(14, 40, 83, 0.07);
  max-height: 930px;
  overflow-y: auto;
  height: 85vh;
`;

export const Head = styled.div`
  color: ${({ theme }) => theme.color.grey900};
  font-family: AcidGrotesk-Medium;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: 0.2px;
  padding: 32px 32px 0;
  border-bottom: 1px solid ${({ theme }) => theme.color.grey200};
`;

export const StyledCloseIcon = styled(CloseIcon)`
  width: 24px;
  height: 24px;
  cursor: pointer;

  path {
    fill: ${({ theme }) => theme.color.grey700};
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 32px;
  border-top: 1px solid ${({ theme }) => theme.color.grey200};
`;

export const BookAgain = styled.div`
  display: flex;
  align-items: center;

  button {
    width: max-content;

    &:last-child {
      margin-left: 20px;
    }
  }
`;
export const RightButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    width: 150px;
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: 0.08px;

    &:last-child {
      border: 1px solid ${({ theme }) => theme.color.blue800};
      color: ${({ theme }) => theme.color.blue800};
      margin-left: 20px;
    }
  }
`;

export const StyledDeleteIcon = styled(DeleteIcon)`
  cursor: pointer;
  width: 24px;
  height: 24px;

  path {
    fill: ${({ theme }) => theme.color.grey600};
  }
`;

export const StyledEditIcon = styled(EditIcon)`
  cursor: pointer;
  width: 24px;
  height: 24px;

  path {
    fill: ${({ theme }) => theme.color.blue800};
  }
`;

export const Tabs = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 16px;
`;

export const Tab = styled.div<Props>`
  display: flex;
  align-items: center;
  padding: 8px 4px;
  margin-right: 20px;
  color: ${({ theme, isActive }) =>
    isActive ? theme.color.blue700 : theme.color.grey600};
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: ${({ isActive }) => (isActive ? 700 : 500)};
  line-height: 140%;
  cursor: pointer;
  border-bottom: ${({ theme, isActive }) =>
    isActive ? `2px solid ${theme.color.blue700}` : 'none'};

  > svg {
    width: 20px;
    height: 20px;

    path {
      fill: ${({ theme, isActive }) =>
        isActive ? theme.color.blue700 : theme.color.grey600};
    }
  }
`;

export const TeleconsultationButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;

  > a {
    text-decoration: none;
    > button {
      width: fit-content;
    }
  }
`;
