import React, { ChangeEvent, FC, useState } from 'react';
import {
  ButtonWrapper,
  DocumentActions,
  DocumentItem,
  EditWrapper,
  InputWrapper,
} from './styles';
import {
  EditPencilIcon,
  DeleteIcon,
  DownloadIcon,
  ImageIcon,
  PdfIcon,
  DocumentIcon,
} from 'applicaiton/assets/index';
import { S3BucketType } from '@docbay/schemas';
import {
  ExpandedActions,
  PrimaryButton,
  SecondaryButton,
} from 'common/components';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { editDocuments } from 'applicaiton/store/reducers/AppointmentDocuments/ActionCreators';
import {
  setAppointmentDocuments,
  setDeletedDocument,
} from 'applicaiton/store/reducers/AppointmentDocuments/AppoinmentsDocumentsSlice';
import { useTranslation } from 'react-i18next';
import { fetchAppointmentById } from 'applicaiton/store/reducers/Appointments/ActionCreators';
import { AppointmentDocumentsItemProps } from './models';
import { useDocumentDownload } from 'common/hooks/useDownloadDocument';
import { useInvoiceDocumentDownload } from 'common/hooks/useDownloadInvoiceDocument';

export const S3BucketTypes = {
  TMP: 'TMP',
  DEFAULT: 'DEFAULT',
};

const AppointmentDocumentsItem: FC<AppointmentDocumentsItemProps> = ({
  item,
  onDelete,
  editMode = false,
  currentAppointmentId,
  isTeleconsultationDocuments = false,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const downloadDocument = useDocumentDownload();
  const downloadInvoiceDocument = useInvoiceDocumentDownload();
  const [isEdit, setIsEdit] = useState(false);
  const [nameValue, setNameValue] = useState('');

  const { appointmentDocuments } = useAppSelector(
    (state) => state.appointmentDocumentsSlice,
  );
  const imageExtensions = ['jpg', 'jpeg', 'png'];

  const getCurrentIcon = (extension: string) => {
    if (imageExtensions.includes(extension)) {
      return <ImageIcon />;
    } else if (extension === 'pdf') {
      return <PdfIcon />;
    } else {
      return <DocumentIcon />;
    }
  };

  const handleCancel = () => {
    setNameValue('');
    setIsEdit(false);
  };

  const handleEdit = () => {
    setIsEdit(true);
    setNameValue(item.originalName);
  };

  const handleSave = async () => {
    await dispatch(
      editDocuments({
        key: item.key,
        bucketType: (editMode
          ? S3BucketTypes.DEFAULT
          : S3BucketTypes.TMP) as S3BucketType,
        metadata: {
          'original-name': nameValue,
        },
        ...(editMode ? { relatedEntityId: currentAppointmentId } : {}),
      }),
    ).then((data) => {
      const index = appointmentDocuments.indexOf(item);
      const newAppointmentDocuments = [...appointmentDocuments];

      newAppointmentDocuments.splice(index, 1, data.payload);

      if (editMode) {
        dispatch(fetchAppointmentById(String(currentAppointmentId)));
      } else {
        dispatch(setAppointmentDocuments(newAppointmentDocuments));
      }

      setIsEdit(false);
    });
  };

  const handleDownloadDocument = () => {
    if (item.type === 'APPOINTMENT_INVOICE') {
      downloadInvoiceDocument(item.id);
    } else {
      downloadDocument({
        relatedEntityId: currentAppointmentId,
        key: item.key,
        bucketType: (editMode
          ? S3BucketTypes.DEFAULT
          : S3BucketTypes.TMP) as S3BucketType,
      });
    }
  };

  const handleDeleteDocument = () => {
    if (editMode) {
      dispatch(setDeletedDocument(item));
      onDelete(item.key);
    } else {
      onDelete(item.key);
    }
  };

  return (
    <>
      {isEdit && (
        <EditWrapper>
          <InputWrapper>
            {getCurrentIcon(item.extension)}
            <input
              value={nameValue}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setNameValue(e.target.value)
              }
            />
          </InputWrapper>

          <ButtonWrapper>
            <SecondaryButton type="button" onClick={handleCancel}>
              {t('cancel')}
            </SecondaryButton>
            <PrimaryButton type="button" onClick={handleSave}>
              {t('save')}
            </PrimaryButton>
          </ButtonWrapper>
        </EditWrapper>
      )}
      {!isEdit && (
        <DocumentItem key={item.key}>
          <div>
            {getCurrentIcon(item.extension)}
            <span>
              {item.originalName}.{item.extension}
            </span>
          </div>
          {isTeleconsultationDocuments ? (
            <ExpandedActions
              handleEdit={handleEdit}
              handleDeleteDocument={handleDeleteDocument}
              handleDownloadDocument={handleDownloadDocument}
            />
          ) : (
            <DocumentActions>
              <div>
                <EditPencilIcon onClick={handleEdit} />
              </div>
              <div onClick={handleDownloadDocument}>
                <DownloadIcon />
              </div>
              <div>
                <DeleteIcon onClick={handleDeleteDocument} />
              </div>
            </DocumentActions>
          )}
        </DocumentItem>
      )}
    </>
  );
};

export default AppointmentDocumentsItem;
