import React, { FC, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import type { ForgotPasswordDataT } from './types';
import { useForgotPasswordForm } from './hooks/useForgotPasswordForm';
import { Input, PrimaryButton } from 'common/components';

import { FormGroupStyled, ForgotPasswordWrapper, StyledForm } from './styles';
import Header from './components/Header';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { usersForgotPassword } from 'applicaiton/store/reducers/Users/ActionCreators';
import { useNavigate } from 'react-router-dom';
import { PathNames } from 'applicaiton/routes';
import {
  setAuthError,
  setIsSuccessResetPassword,
} from 'applicaiton/store/reducers/Users/UsersSlice';
import BackButton from './components/BackButton';
import { authService } from 'common';

const ForgotPasswordPage: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { error, isSuccessResetPassword } = useAppSelector(
    (state) => state.users,
  );
  const { register, errors, handleSubmit, watch, setError, clearErrors } =
    useForgotPasswordForm({
      emailError: t('errors.emailFormat'),
      requiredError: t('errors.required'),
    });

  useEffect(() => {
    if (error) {
      setError('email', { message: t('something_went_wrong') || '' });
    } else {
      clearErrors();
    }
  }, [error]);

  useEffect(() => {
    if (isSuccessResetPassword) {
      navigate(PathNames.confirmChange);
    }
    return () => {
      dispatch(setIsSuccessResetPassword(false));
      dispatch(setAuthError(false));
    };
  }, [isSuccessResetPassword]);

  useEffect(() => {
    if (error) {
      dispatch(setAuthError(false));
    }
  }, [watch('email')]);

  const onSubmit = async (data: ForgotPasswordDataT) => {
    authService.saveEmail(data.email);
    dispatch(usersForgotPassword({ email: data.email }));
  };

  return (
    <>
      <BackButton />
      <ForgotPasswordWrapper>
        <Header
          title={t('auth.forgot_password.title')}
          subtitle={t('auth.forgot_password.sub_title') || ''}
        />

        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          <FormGroupStyled>
            <Input
              id="email"
              label={t('auth.email') || ''}
              type="email"
              placeholder={t('email_placeholder') || ''}
              register={register}
              errorMessage={errors.email?.message}
            />
          </FormGroupStyled>

          <PrimaryButton
            onClick={handleSubmit(onSubmit)}
            type="submit"
            disabled={!!Object.keys(errors).length}
          >
            {t('auth.continue')}
          </PrimaryButton>
        </StyledForm>
      </ForgotPasswordWrapper>
    </>
  );
};

export default ForgotPasswordPage;
