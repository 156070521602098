import React, { lazy, Suspense } from 'react';
import { Loader } from 'common/loader/Loader';
import { WithHeader } from 'common/layout/WithHeader';
import { PathNames } from './constants';
import EditProfileSettings from '../pages/EditProfileSettings';
import ProfessionalEditPage from 'applicaiton/pages/ProfessionalEditPage';
import ProfessionalAddPage from 'applicaiton/pages/ProfessionalAddPage';
import HomePage from '../pages/HomePage';
import AppointmentTypeAddPage from 'applicaiton/pages/AppointmentTypeAddPage';
import AppointmentTypeEditPage from 'applicaiton/pages/AppointmentTypeEditPage';
import ProfilePage from 'applicaiton/pages/ProfilePage';
import UsersPage from 'applicaiton/pages/UsersPage';
import SecretaryAddPage from 'applicaiton/pages/SecretaryAddPage';
import UserAddPage from 'applicaiton/pages/UserAddPage';
import AdminEditPage from 'applicaiton/pages/AdminEditPage';
import SecretaryEditPage from 'applicaiton/pages/SecretaryEditPage';
import PatientPage from 'applicaiton/pages/PatientPage';
import UserPermissionsPage from 'applicaiton/pages/UserPermissionsPage';
import MobilePage from 'applicaiton/pages/MobilePage';
import {
  isShowClinicsForProfessional,
  isUserCanEditClinic,
} from 'common/helpers/permissions';
import ErrorPage from 'applicaiton/pages/ErrorPage/ErrorPage';
import { getUserRole } from 'applicaiton/sessionStorage/auth';
import { UserRoles } from 'applicaiton/constants/userRoles';
import TeleconsultationPage from 'applicaiton/pages/TeleconsultationPage';
import ClinicBillingPage from 'applicaiton/pages/ClinicBillingPage';
import { Navigate } from 'react-router-dom';
import { userHasPermission } from 'common/helpers/userHasPermission';
import { UserPermissions } from 'applicaiton/constants/userPermissions';
import LoginAsPage from 'applicaiton/pages/LoginAsPage';
import WaitingListPage from 'applicaiton/pages/WaitingListPage';
import MySubscribersPage from 'applicaiton/pages/MySubscribersPage';
import ChatsPage from 'applicaiton/pages/ChatsPage';
import PatientDataPage from 'applicaiton/pages/PatientDataPage';

const AuthPage = lazy(() => import('../pages/AuthPage'));
const ClinicRegistrationPage = lazy(() =>
  import('applicaiton/pages/ClinicRegistrationPage'),
);
const ClinicsPage = lazy(() => import('applicaiton/pages/ClinicsPage'));
const ProfessionalsPage = lazy(() =>
  import('applicaiton/pages/ProfessionalsPage'),
);
const SchedulerPage = lazy(() => import('applicaiton/pages/SchedulerPage'));
const ProfessionalSchedulePage = lazy(() =>
  import('applicaiton/pages/ProfessionalSchedulePage'),
);
const AppointmentTypesPage = lazy(() =>
  import('applicaiton/pages/AppointmentTypesPage'),
);
const TopicsOfChatsPage = lazy(() =>
  import('applicaiton/pages/TopicsOfChatsPage'),
);
const SubscriptionSettingsPage = lazy(() =>
  import('applicaiton/pages/SubscriptionSettingsPage'),
);
const MyPatientsPage = lazy(() => import('applicaiton/pages/MyPatientsPage'));
const SubscriptionsStatisticsPage = lazy(() =>
  import('applicaiton/pages/SubscriptionsStatisticsPage'),
);
const NotificationsPage = lazy(() =>
  import('applicaiton/pages/NotificationsPage'),
);
const NotificationsSettingsPage = lazy(() =>
  import('applicaiton/pages/NotificationsSettingsPage'),
);

export const publicRouts = [
  {
    path: PathNames.login,
    title: 'Login',
    component: (
      <Suspense fallback={<Loader />}>
        <AuthPage />
      </Suspense>
    ),
  },
  {
    path: PathNames.finishRegistration,
    title: 'Finish registration',
    component: (
      <Suspense fallback={<Loader />}>
        <AuthPage />
      </Suspense>
    ),
  },
  {
    path: PathNames.expiredVerificationCode,
    title: 'Expired verification code',
    component: (
      <Suspense fallback={<Loader />}>
        <AuthPage />
      </Suspense>
    ),
  },
  {
    path: PathNames.forgotPassword,
    title: 'Forgot Password',
    component: (
      <Suspense fallback={<Loader />}>
        <AuthPage />
      </Suspense>
    ),
  },
  {
    path: PathNames.confirmChange,
    title: 'Confirm Change',
    component: (
      <Suspense fallback={<Loader />}>
        <AuthPage />
      </Suspense>
    ),
  },
  {
    path: PathNames.createNewPassword,
    title: 'Create New Password',
    component: (
      <Suspense fallback={<Loader />}>
        <AuthPage />
      </Suspense>
    ),
  },
  {
    path: PathNames.clinicRegistration,
    title: 'Clinic Registration',
    component: (
      <Suspense fallback={<Loader />}>
        <ClinicRegistrationPage />
      </Suspense>
    ),
  },
  {
    path: `${PathNames.loginAs}/:email/:token/:refreshToken/:expiresAt/:data`,
    title: 'Clinic Registration',
    component: (
      <Suspense fallback={<Loader />}>
        <LoginAsPage />
      </Suspense>
    ),
  },
];

export const getCurrentRoutes = () => {
  const showClinicsForProfessional = isShowClinicsForProfessional();
  const isCanEditClinics = isUserCanEditClinic();
  const isCanManageProfessional = userHasPermission(
    UserPermissions.clinicProfessionalsManagement,
  );
  const isCanManageAppointmentTypes = userHasPermission(
    UserPermissions.clinicAppointmentTypesConfiguration,
  );
  const doShowAgenda = userHasPermission(UserPermissions.accessToOtherAgendas);
  const userRole = getUserRole();
  const isClinicOwnerRole = userRole?.includes(UserRoles.clinicOwner);
  const isShowSubscriptions = userRole?.includes(UserRoles.professional);
  const isProfessionalRole = userRole === UserRoles.professional;
  const showAgendaOfOthers =
    (doShowAgenda && !isProfessionalRole) || isProfessionalRole;

  const privateRoutes = [
    {
      path: PathNames.home,
      title: '/',
      component: (
        <Suspense fallback={<Loader />}>
          <Navigate
            to={
              showAgendaOfOthers
                ? PathNames.schedule
                : `${PathNames.profile}/:id`
            }
          />
        </Suspense>
      ),
    },
    {
      path: PathNames.mobileInfo,
      title: '/info',
      component: (
        <Suspense fallback={<Loader />}>
          <MobilePage />
        </Suspense>
      ),
    },
    {
      path: PathNames.support,
      title: '/support',
      component: (
        <Suspense fallback={<Loader />}>
          <WithHeader>
            <HomePage />
          </WithHeader>
        </Suspense>
      ),
    },
    ...(showAgendaOfOthers
      ? [
          {
            path: PathNames.schedule,
            title: '/schedule',
            component: (
              <Suspense fallback={<Loader />}>
                <SchedulerPage />
              </Suspense>
            ),
          },
        ]
      : []),
    {
      path: `${PathNames.profile}/:id`,
      title: '/profile',
      component: (
        <Suspense fallback={<Loader />}>
          <ProfilePage />
        </Suspense>
      ),
    },
    {
      path: `${PathNames.teleconsultation}/:id`,
      title: 'Teleconsultation',
      component: <TeleconsultationPage />,
    },
    {
      path: `${PathNames.teleconsultation}`,
      title: 'Teleconsultation',
      component: <TeleconsultationPage />,
    },
    {
      path: `${PathNames.patient}/:id/:tab?`,
      title: '/patient',
      component: <PatientPage />,
    },
    {
      path: `${PathNames.myPatients}`,
      title: 'My Patient',
      component: (
        <Suspense fallback={<Loader />}>
          <MyPatientsPage />
        </Suspense>
      ),
    },
    ...(showClinicsForProfessional
      ? [
          {
            path: PathNames.myClinics,
            title: 'Clinics',
            component: (
              <Suspense fallback={<Loader />}>
                <ClinicsPage />
              </Suspense>
            ),
          },
        ]
      : []),
    ...(isCanManageAppointmentTypes
      ? [
          {
            path: PathNames.typesAppointments,
            title: 'Appointments Types',
            component: (
              <Suspense fallback={<Loader />}>
                <AppointmentTypesPage />
              </Suspense>
            ),
          },
          {
            path: `${PathNames.addAppointmentType}`,
            title: 'Add new Appointment Type',
            component: (
              <Suspense fallback={<Loader />}>
                <AppointmentTypeAddPage />
              </Suspense>
            ),
          },
          {
            path: `${PathNames.editAppointmentType}/:id`,
            title: 'Edit Appointment Type',
            component: (
              <Suspense fallback={<Loader />}>
                <AppointmentTypeEditPage />
              </Suspense>
            ),
          },
        ]
      : []),
    ...(isClinicOwnerRole
      ? [
          {
            path: `${PathNames.waitingList}`,
            title: 'Waiting list',
            component: (
              <Suspense fallback={<Loader />}>
                <WaitingListPage />
              </Suspense>
            ),
          },
          {
            path: `${PathNames.createClinic}`,
            title: 'Create Profile',
            component: (
              <Suspense fallback={<Loader />}>
                <EditProfileSettings />
              </Suspense>
            ),
          },
          {
            path: PathNames.clinicOwnerSchedule,
            title: '/professional schedule',
            component: (
              <Suspense fallback={<Loader />}>
                <ProfessionalSchedulePage />
              </Suspense>
            ),
          },
          {
            path: PathNames.settings,
            title: '/settings',
            component: (
              <Suspense fallback={<Loader />}>
                <WithHeader>
                  <HomePage />
                </WithHeader>
              </Suspense>
            ),
          },
          {
            path: PathNames.myClinics,
            title: 'Clinics',
            component: (
              <Suspense fallback={<Loader />}>
                <ClinicsPage />
              </Suspense>
            ),
          },
          {
            path: PathNames.userPermissions,
            title: 'User Permissions',
            component: <UserPermissionsPage />,
          },
          {
            path: PathNames.clinicBilling,
            title: 'Clinic Billing',
            component: (
              <Suspense fallback={<Loader />}>
                <ClinicBillingPage />
              </Suspense>
            ),
          },
          {
            path: PathNames.notifications,
            title: 'Notifications',
            component: (
              <Suspense fallback={<Loader />}>
                <NotificationsPage />
              </Suspense>
            ),
          },
        ]
      : []),
    ...(isCanEditClinics || isClinicOwnerRole
      ? [
          {
            path: `${PathNames.editClinic}/:id`,
            title: 'Edit Profile Settings',
            component: (
              <Suspense fallback={<Loader />}>
                <EditProfileSettings />
              </Suspense>
            ),
          },
        ]
      : []),
    ...(isCanManageProfessional
      ? [
          {
            path: `${PathNames.editProfessional}/:id`,
            title: 'Edit Professional Info',
            component: (
              <Suspense fallback={<Loader />}>
                <ProfessionalEditPage />
              </Suspense>
            ),
          },
          {
            path: `${PathNames.myUsers}`,
            title: 'My Users',
            component: (
              <Suspense fallback={<Loader />}>
                <UsersPage />
              </Suspense>
            ),
          },
          {
            path: PathNames.professionals,
            title: '/professionals',
            component: (
              <Suspense fallback={<Loader />}>
                <ProfessionalsPage />
              </Suspense>
            ),
          },
          {
            path: `${PathNames.editProfessional}/:id`,
            title: 'Edit Professional Info',
            component: (
              <Suspense fallback={<Loader />}>
                <ProfessionalEditPage />
              </Suspense>
            ),
          },
          {
            path: `${PathNames.addProfessional}`,
            title: 'Add new Professional',
            component: (
              <Suspense fallback={<Loader />}>
                <ProfessionalAddPage />
              </Suspense>
            ),
          },
          {
            path: `${PathNames.editAdmin}/:id`,
            title: 'Edit admin',
            component: (
              <Suspense fallback={<Loader />}>
                <AdminEditPage />
              </Suspense>
            ),
          },
          {
            path: `${PathNames.editSecretary}/:id`,
            title: 'Edit secretary',
            component: (
              <Suspense fallback={<Loader />}>
                <SecretaryEditPage />
              </Suspense>
            ),
          },
          {
            path: `${PathNames.addSecretary}`,
            title: 'Add new secretary',
            component: (
              <Suspense fallback={<Loader />}>
                <SecretaryAddPage />
              </Suspense>
            ),
          },
          {
            path: `${PathNames.addAdmin}`,
            title: 'Add new user',
            component: (
              <Suspense fallback={<Loader />}>
                <UserAddPage />
              </Suspense>
            ),
          },
        ]
      : []),
    ...(isShowSubscriptions
      ? [
          {
            path: `${PathNames.mySubscribers}`,
            title: 'My Subscribers',
            component: (
              <Suspense fallback={<Loader />}>
                <MySubscribersPage />
              </Suspense>
            ),
          },
          {
            path: `${PathNames.chats}`,
            title: 'Chats',
            component: (
              <Suspense fallback={<Loader />}>
                <ChatsPage />
              </Suspense>
            ),
          },
          {
            path: `${PathNames.topicsOfChats}`,
            title: 'Topics',
            component: (
              <Suspense fallback={<Loader />}>
                <TopicsOfChatsPage />
              </Suspense>
            ),
          },
          {
            path: `${PathNames.subscriptionSettings}`,
            title: 'Subscription Settings',
            component: (
              <Suspense fallback={<Loader />}>
                <SubscriptionSettingsPage />
              </Suspense>
            ),
          },
          {
            path: `${PathNames.subscriptionsStatistics}`,
            title: 'Subscription Statistics',
            component: (
              <Suspense fallback={<Loader />}>
                <SubscriptionsStatisticsPage />
              </Suspense>
            ),
          },
          {
            path: `${PathNames.consultationData}/:appointmentId`,
            title: '/consultation-data',
            component: <PatientDataPage />,
          },
        ]
      : []),
    ...(isProfessionalRole
      ? [
          {
            path: PathNames.notifications,
            title: 'Notifications',
            component: (
              <Suspense fallback={<Loader />}>
                <NotificationsPage />
              </Suspense>
            ),
          },
          {
            path: `${PathNames.consultationData}/:appointmentId`,
            title: '/consultation-data',
            component: <PatientDataPage />,
          },
        ]
      : [
          {
            path: PathNames.notificationsSettings,
            title: 'Notifications Settings',
            component: (
              <Suspense fallback={<Loader />}>
                <NotificationsSettingsPage />
              </Suspense>
            ),
          },
        ]),
    {
      path: '/*',
      title: 'Error',
      component: <ErrorPage />,
    },
  ];

  return privateRoutes;
};
