import styled from 'styled-components';

interface Props {
  colorCode?: string;
}

export const Content = styled.div`
  padding: 32px;
  height: 100%;

  > div {
    display: flex;
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const InfoItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: ${({ theme }) => theme.color.grey800};
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0.08px;

  &:last-child {
    margin-bottom: 0;
  }

  > svg {
    width: 20px;
    height: 20px;
    margin-right: 8px;

    path {
      fill: ${({ theme }) => theme.color.grey600};
    }
  }
`;

export const ColorSquare = styled.div<Props>`
  width: 18px;
  height: 18px;
  padding: 2px;
  border-radius: 4px;
  background: ${({ colorCode }) => colorCode};
  margin-right: 8px;
`;
