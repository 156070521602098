import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import WaitingAppointmentsTable from './components/WaitingAppointmentsTable';
import { Wrapper } from './styles';
import { Header } from 'common/components';

const WaitingList: FC = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Header />
      <h1>{t('waiting_list.title')}</h1>
      <WaitingAppointmentsTable />
    </Wrapper>
  );
};

export default WaitingList;
