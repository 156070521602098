import styled from 'styled-components';
import { ReactComponent as CloseIcon } from 'applicaiton/assets/close.svg';
import { ReactComponent as PlusIcon } from 'applicaiton/assets/plus.svg';

interface Props {
  isActive: boolean;
}

interface TitleProps {
  isEdit: boolean;
}

export const StyledModal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
  display: flex;
  width: 664px;
  flex-direction: column;
  border-radius: 8px;
  background: ${({ theme }) => theme.color.white};
  box-shadow: 0 10px 45px 0 rgba(14, 40, 83, 0.07);
  max-height: 930px;
  overflow-y: auto;
  height: 85vh;
`;

export const Head = styled.div<TitleProps>`
  color: ${({ theme }) => theme.color.grey900};
  font-family: AcidGrotesk-Medium;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: 0.2px;
  padding: ${({ isEdit }) => (isEdit ? '32px' : '32px 32px 0')};
  border-bottom: 1px solid ${({ theme }) => theme.color.grey200};
`;

export const StyledCloseIcon = styled(CloseIcon)`
  width: 24px;
  height: 24px;
  cursor: pointer;

  path {
    fill: ${({ theme }) => theme.color.grey700};
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Tabs = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 16px;
`;

export const Tab = styled.div<Props>`
  display: flex;
  align-items: center;
  padding: 8px 4px;
  margin-right: 20px;
  color: ${({ theme, isActive }) =>
    isActive ? theme.color.blue700 : theme.color.grey600};
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: ${({ isActive }) => (isActive ? 700 : 500)};
  line-height: 140%;
  cursor: pointer;
  border-bottom: ${({ theme, isActive }) =>
    isActive ? `2px solid ${theme.color.blue700}` : 'none'};

  > svg {
    width: 20px;
    height: 20px;

    path {
      fill: ${({ theme, isActive }) =>
        isActive ? theme.color.blue700 : theme.color.grey600};
    }
  }
`;

export const StyledPlusIcon = styled(PlusIcon)<Props>`
  display: flex;
  align-items: center;
  margin-right: 4px;
`;
