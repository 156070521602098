import React from 'react';
import { RadioButtonSection, Row } from './styles';
import { DatePicker, Input, RadioButton } from 'common/components';
import { useTranslation } from 'react-i18next';
import { usePronunciationOptions } from 'common/hooks/usePronunciationOptions';
import {
  FieldErrors,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { FieldError } from 'react-hook-form/dist/types/errors';
import { getRoundedDateInUTC } from 'common/helpers/dateTimeHelper';

export enum Gender {
  Male = 'Male',
  Female = 'Female',
}

interface Props {
  setValuePatient: UseFormSetValue<any>;
  getValuesPatient: UseFormGetValues<any>;
  errorsPatient: {
    firstName?: FieldError;
    lastName?: FieldErrors;
    phone?: FieldErrors;
    dateOfBirth?: FieldErrors;
    email?: FieldErrors;
  };
  registerPatient: UseFormRegister<any>;
  clearErrorsPatient: UseFormClearErrors<any>;
  watchPatient: UseFormWatch<any>;
}

const CreateFamilyMember = ({
  setValuePatient,
  getValuesPatient,
  errorsPatient,
  registerPatient,
  clearErrorsPatient,
  watchPatient,
}: Props) => {
  const { t } = useTranslation();
  const pronunciation = usePronunciationOptions();

  const handleChangeDateOfBirth = (value: string) => {
    setValuePatient(
      'dateOfBirth',
      getRoundedDateInUTC(value).add(1, 'hour').toISOString(), // Add 1 hour to the date to avoid the date being set to the previous day
    );
    clearErrorsPatient('dateOfBirth');
  };

  const handleGenderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.id === 'Male' ? Gender.Male : Gender.Female;

    setValuePatient('gender', value);
  };

  return (
    <>
      <RadioButtonSection>
        <p>{t('pronunciation') || ''}</p>
        <div>
          {pronunciation.map((item) => (
            <RadioButton
              key={item.value}
              id={item.value}
              label={item.label}
              checked={watchPatient('gender') === item.value}
              onChange={handleGenderChange}
            />
          ))}
        </div>
      </RadioButtonSection>
      <Row>
        <Input
          label={t('personal_information.first_name') || ''}
          id={'firstName'}
          type="text"
          register={registerPatient}
          errorMessage={errorsPatient?.firstName?.message}
          placeholder={t('patient.new_patient_name') || ''}
        />
        <Input
          label={t('personal_information.last_name') || ''}
          id={'lastName'}
          type="text"
          register={registerPatient}
          errorMessage={errorsPatient?.lastName?.message}
          placeholder={t('patient.new_patient_lastname') || ''}
        />
      </Row>
      <Row fullWidth>
        <Input
          id="relationship"
          label={t('patient.relationship') || ''}
          type="text"
          placeholder={t('patient.relationship_placeholder') || ''}
          register={registerPatient}
        />
      </Row>
      <Row fullWidth>
        <DatePicker
          id={'dateOfBirth'}
          label={t('patient.date_birth') || ''}
          value={String(getValuesPatient('dateOfBirth'))}
          onChange={handleChangeDateOfBirth}
          maxDate={Date.now()}
          minDate={0}
          errorMessage={errorsPatient?.dateOfBirth?.message}
        />
      </Row>
      <Row fullWidth>
        <Input
          id="fin"
          label={t('patient.fin') || ''}
          type="text"
          placeholder={t('patient.fin_placeholder') || ''}
          register={registerPatient}
        />
      </Row>
      <Row fullWidth>
        <Input
          id="ssn"
          label={t('patient.ssn') || ''}
          type="email"
          placeholder={t('patient.ssn_placeholder') || ''}
          register={registerPatient}
        />
      </Row>
    </>
  );
};

export default CreateFamilyMember;
