import styled from 'styled-components';

interface StyledProps {
  isActive: boolean;
}

export const AccordionProfilesStyled = styled.div<StyledProps>`
  width: 100%;
  position: relative;

  .accordion-root {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    height: 61px;
    width: 100%;
    padding: 8px 16px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.08px;
    border-radius: ${({ isActive }) => (isActive ? '4px 4px 0px 0px' : '4px')};
    border: ${({ theme, isActive }) =>
      isActive ? `1px solid ${theme.color.navigator}` : 'none'};
    border-bottom: 0px;
  }
  .accordion-info {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
    white-space: nowrap;
    color: ${({ theme }) => theme.color.white};
    cursor: pointer;

    .image {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 0;
      left: 2px;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: ${({ theme }) => theme.color.purple100};

      color: ${({ theme }) => theme.color.grey900};
      text-align: center;
      font-size: 18px;
      font-family: Mulish;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
      text-transform: uppercase;

      > img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        transition: all 0.3ms;
      }
    }

    > p,
    span {
      margin-left: 52px;
      position: relative;
      overflow: hidden;
    }

    > span {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.07px;
      white-space: initial;
    }
  }
  .accordion-expand {
    transform: ${({ isActive }) => (isActive ? 'rotate(180deg)' : 'unset')};
    > svg {
      visibility: hidden;
      opacity: 0;
      path {
        fill: ${({ theme }) => theme.color.white};
      }
    }
  }
  .accordion-list {
    position: absolute;
    background: #013a47;
    z-index: 1;
    width: 100%;
    max-height: 244px;
    overflow-y: auto;
    border: 1px solid ${({ theme }) => theme.color.navigator};
    border-top: 0px;
    border-radius: 0px 0px 4px 4px;
    .accordion-info {
      text-decoration: none;
      height: 61px;
      padding: 8px 16px;
      color: ${({ theme }) => theme.color.white};
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0.08px;
      :hover {
        background: ${({ theme }) => theme.color.blue800};
        color: ${({ theme }) => theme.color.white};
      }
    }
    .active {
      border-radius: 4px;
      background: ${({ theme }) => theme.color.green100};
      color: ${({ theme }) => theme.color.menu};
    }
  }
`;
