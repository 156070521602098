export enum PathNames {
  home = '/',
  login = '/login',
  finishRegistration = '/create-password',
  expiredVerificationCode = '/create-password/expired',
  clinicRegistration = '/clinic-registration',
  forgotPassword = '/forgot-password',
  confirmChange = '/confirm-password',
  createNewPassword = '/create-new-password',
  clinics = '/clinics',
  error = '/error',
  schedule = '/schedule',
  clinicOwnerSchedule = '/clinicOwnerSchedule',
  profile = '/profile',
  settings = '/settings',
  support = '/support',
  myClinics = '/settings/my_clinics',
  agenda = '/settings/agenda',
  typesAppointments = '/settings/types_of_appointments',
  internetAppointments = '/settings/internet_appointments',
  patientAppointments = '/settings/patient_appointments',
  clinicBilling = '/settings/clinic_billing',
  editClinic = '/settings/my_clinics/edit',
  profileClinic = '/clinics/profile',
  createClinic = '/clinics/create',
  addAppointmentType = '/appointment_type/add',
  editAppointmentType = '/appointment_type/edit',
  users = '/users',
  professionals = '/users/professionals',
  editProfessional = '/users/professionals/edit',
  addProfessional = '/users/professionals/add',
  addPClinicOwnerProfessional = '/clinicOwner/professionals/add',
  myUsers = '/users/my-users',
  editAdmin = '/users/my-users/admin/edit',
  addAdmin = '/users/my-users/admin/add',
  editSecretary = '/users/my-users/secretary/edit',
  addSecretary = '/users/my-users/secretary/add',
  patient = '/patient',
  userPermissions = '/settings/user-permissions',
  teleconsultation = '/teleconsultation',
  mobileInfo = '/mobile_info',
  loginAs = 'login_as',
  waitingList = '/waiting-list',
  mySubscribers = '/my_subscribers',
  chats = '/chats',
  topicsOfChats = '/topics_of_chats',
  subscriptions = 'subscriptions',
  subscriptionSettings = '/subscriptions/subscription-settings',
  myPatients = '/settings/my-patients',
  subscriptionsStatistics = '/subscriptions/subscriptions-statistics',
  notifications = '/notifications',
  notificationsSettings = '/settings/notifications-settings',
  consultationData = '/consultation-data',
}
