import React, { FC, useEffect, useRef, useState } from 'react';
import { TimerProps } from './modules';
import { TimerStyled } from './styles';

const Timer: FC<TimerProps> = ({ startDate, setIsEventStarted }) => {
  const Ref = useRef<NodeJS.Timeout | null>(null);

  const [timer, setTimer] = useState('00:00');

  const getTimeRemaining = (date: Date) => {
    const total =
      Date.parse(date.toString()) - Date.parse(new Date().toString());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    return {
      total,
      minutes,
      seconds,
    };
  };

  const startTimer = (date: Date) => {
    const { total, minutes, seconds } = getTimeRemaining(date);
    if (total >= 0) {
      setTimer(
        (minutes > 9 ? minutes : '0' + minutes) +
          ':' +
          (seconds > 9 ? seconds : '0' + seconds),
      );
      if (minutes == 0 && seconds === 0) {
        setIsEventStarted(true);
      }
    }
  };

  const clearTimer = (date: Date) => {
    setTimer('00:00');

    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(date);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    const deadline = new Date(startDate?.toString() || '');
    deadline.setSeconds(deadline.getSeconds());
    return deadline;
  };

  useEffect(() => {
    clearTimer(getDeadTime());
  }, [startDate]);

  return (
    <TimerStyled className="timer">
      <h1>{timer}</h1>
    </TimerStyled>
  );
};

export default React.memo(Timer);
