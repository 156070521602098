import styled from 'styled-components';
import { ReactComponent as PlusIcon } from 'applicaiton/assets/plus.svg';

interface Props {
  isActive: boolean;
}
interface CreateNewPatientLabelProps {
  disable?: boolean;
}

export const Content = styled.div`
  padding: 32px;

  > div {
    margin-bottom: 24px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const DateSection = styled.div`
  display: flex;
  margin-bottom: 24px;
`;

export const ButtonsWrapper = styled.div`
  padding: 32px;
  border-top: 1px solid ${({ theme }) => theme.color.grey200};
  display: flex;
  justify-content: flex-end;

  button {
    width: 150px;
    &:last-child {
      margin-left: 20px;
    }
  }
`;

export const SwitchStyled = styled.div`
  display: flex;

  > p {
    color: ${({ theme }) => theme.color.grey500};
    font-family: Mulish;
    font-size: 16px;
    font-weight: 500;
    line-height: 22.4px;
    letter-spacing: 0.08px;
    margin-left: 12px;

    > span {
      margin-left: 8px;
      font-weight: 700;
      color: ${({ theme }) => theme.color.blue800};
      cursor: pointer;
    }
  }
`;

export const CheckboxWrapper = styled.div`
  display: block !important;

  > label {
    display: block;
    color: ${({ theme }) => theme.color.grey600};
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.08px;
    margin-bottom: 8px;
  }
`;

export const TimePickerWrapper = styled.div`
  margin-left: 12px;

  label {
    display: block;
    margin-bottom: 6px;
    color: ${({ theme }) => theme.color.grey600};
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.08px;
  }
`;

export const PatientSection = styled.div`
  flex-direction: column;

  > label {
    display: block;
    color: ${({ theme }) => theme.color.grey600};
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.08px;
    margin-bottom: 6px;
  }

  > div {
    width: 408px;
  }
`;

export const StyledPlusIcon = styled(PlusIcon)`
  display: flex;
  align-items: center;
  margin-right: 4px;
`;

export const CreateNewPatient = styled.div`
  display: block !important;
`;

export const CreateNewPatientLabel = styled.div<CreateNewPatientLabelProps>`
  display: flex;
  align-items: center;
  color: ${({ theme, disable }) =>
    disable ? theme.color.grey400 : theme.color.grey500};
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0.08px;
  margin-top: 12px;

  > span {
    display: flex;
    align-items: center;
    cursor: ${({ disable }) => (disable ? 'not-allowed' : 'pointer')};

    > svg {
      margin-right: 4px;
      path {
        fill: ${({ theme, disable }) =>
          disable ? theme.color.grey400 : theme.color.grey500};
      }
    }
  }
`;

export const StyledDivider = styled.div`
  height: 1px;
  margin: 32px 0;
  background-color: ${({ theme }) => theme.color.grey200};
`;

export const Confusing = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 24px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.color.warning2};
  background: ${({ theme }) => theme.color.warning3};
  padding: 16px;
  color: ${({ theme }) => theme.color.warning};
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;

  > svg {
    margin-right: 12px;

    path: {
      fill: ${({ theme }) => theme.color.warning2};
    }
  }
`;

export const BottomActions = styled.div`
  display: flex;

  > div:last-child {
    color: ${({ theme }) => theme.color.blue800};
    margin-left: 20px;

    svg {
      path {
        fill: ${({ theme }) => theme.color.blue800};
      }
    }
  }
`;

export const CheckboxLabel = styled.div`
  margin-top: 20px;

  label {
    font-family: Mulish;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
  }

  input + label {
    padding-left: 8px;
  }
`;

export const Tabs = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 16px;
`;

export const Tab = styled.div<Props>`
  display: flex;
  align-items: center;
  padding: 8px 4px;
  margin-right: 20px;
  color: ${({ theme, isActive }) =>
    isActive ? theme.color.blue700 : theme.color.grey600};
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: ${({ isActive }) => (isActive ? 700 : 500)};
  line-height: 140%;
  cursor: pointer;
  border-bottom: ${({ theme, isActive }) =>
    isActive ? `2px solid ${theme.color.blue700}` : 'none'};

  > svg {
    width: 20px;
    height: 20px;

    path {
      fill: ${({ theme, isActive }) =>
        isActive ? theme.color.blue700 : theme.color.grey600};
    }
  }
`;
