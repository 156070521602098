import styled from 'styled-components';

export const FooterStyled = styled.footer`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 48px;

  hr {
    border-color: ${({ theme }) => theme.color.grey400};
    height: 11px;
  }
  div {
    display: grid;
    gap: 10px;
    grid-template-columns: auto auto auto;
    align-items: center;
    justify-content: space-between;
  }
  p,
  a {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: ${({ theme }) => theme.color.grey600};
    text-decoration: none;
  }
`;
