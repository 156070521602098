import React, { FormEvent } from 'react';
import { useTranslation } from 'react-i18next';

import {
  MessageFormStyled,
  InputStyled,
  SendButtonStyled,
  DocumentButtonStyled,
} from './styles';

import { AttachFileIcon, SendMessageIcon } from 'applicaiton/assets';
import { MessageInputProps } from './module';
import { useAppSelector } from 'common/hooks/redux';
import { ChatsMode } from 'applicaiton/constants/chats';
import { EmojiPickers } from 'common/components';
import DocumentsUploadModal from 'common/components/DocumentsUploadModal';
import { useConversation } from '../../hooks/useConversation';

export const MessageInput = ({
  onSendMessage,
  isDisabled,
}: MessageInputProps) => {
  const [message, setMessage] = React.useState('');
  const [isOpenAddDocumentModal, setIsOpenAddDocumentModal] =
    React.useState(false);
  const { t } = useTranslation();
  const { handleSaveDocumentMessage } = useConversation();
  const { mode, documentError, isDocumentLoading } = useAppSelector(
    (state) => state.chats,
  );

  const handleSendMessage = (event: FormEvent) => {
    event.preventDefault();
    if (isDisabled || !message.length) {
      return;
    }
    onSendMessage(message);
    setMessage('');
  };

  const handleSelectEmoji = (value: string) => {
    setMessage((prev) => `${prev}${value}`);
  };

  const sendButtonDisabled =
    message.length === 0 || isDisabled || mode === ChatsMode.ArchivedChats;

  const handleAddDocument = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setIsOpenAddDocumentModal(true);
  };

  const handleSave = (data: FormData) => {
    handleSaveDocumentMessage(data).then((data) => {
      if (data.meta.requestStatus === 'fulfilled') {
        setIsOpenAddDocumentModal(false);
      }
    });
  };

  return (
    <MessageFormStyled
      onSubmit={handleSendMessage}
      disabled={mode === ChatsMode.ArchivedChats}
    >
      <DocumentButtonStyled type={'button'} onClick={handleAddDocument}>
        <AttachFileIcon />
      </DocumentButtonStyled>
      <EmojiPickers onSelect={handleSelectEmoji} />
      <InputStyled
        placeholder={t('chats.active_chat.input_placeholder') || ''}
        onChange={(event) => setMessage(event.target.value)}
        value={message}
        disabled={mode === ChatsMode.ArchivedChats}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && !e.shiftKey) {
            handleSendMessage(e);
          }
        }}
      />
      <SendButtonStyled disabled={sendButtonDisabled}>
        <SendMessageIcon />
      </SendButtonStyled>
      {isOpenAddDocumentModal && (
        <DocumentsUploadModal
          isOpen={isOpenAddDocumentModal}
          onClose={() => setIsOpenAddDocumentModal(false)}
          error={documentError}
          isLoading={isDocumentLoading}
          onSave={handleSave}
        />
      )}
    </MessageFormStyled>
  );
};
