import { useTranslation } from 'react-i18next';
import { PathNames } from 'applicaiton/routes/constants';

export const useSubscriptionsMenu = () => {
  const { t } = useTranslation();

  const list = [
    {
      path: PathNames.mySubscribers,
      name: t('clinicsConfiguration.aside_menu.my_subscribers'),
    },
    {
      path: PathNames.chats,
      name: t('clinicsConfiguration.aside_menu.chats'),
    },
    {
      path: PathNames.topicsOfChats,
      name: t('clinicsConfiguration.aside_menu.topics_of_chats'),
    },
    {
      path: PathNames.subscriptionSettings,
      name: t('clinicsConfiguration.aside_menu.subscription_settings'),
    },
    {
      path: PathNames.subscriptionsStatistics,
      name: t('clinicsConfiguration.aside_menu.subscriptions_statistics'),
    },
  ];

  return list;
};
