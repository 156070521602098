import { ClinicBilling, SideMenu } from 'features';
import React, { FC } from 'react';
import { Wrapper } from './styles';

const ClinicBillingPage: FC = () => {
  return (
    <Wrapper>
      <SideMenu />
      <ClinicBilling />
    </Wrapper>
  );
};

export default ClinicBillingPage;
