import React, { FC, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ReactComponent as ExpandMoreIcon } from 'applicaiton/assets/expand-more.svg';
import { ReactComponent as ExpandLessIcon } from 'applicaiton/assets/expand-less.svg';
import { ReactComponent as SubscriptionsIcon } from 'applicaiton/assets/subscriptions.svg';
import { AccordionStyled } from './styles';
import { PathNames } from 'applicaiton/routes';
import MenuItem from '../MenuItem';
import { useTranslation } from 'react-i18next';

interface Item {
  path: string;
  name: string;
}

interface AccordionProps {
  options: Item[];
  isExpanded: boolean;
  handleExpandMenu: () => void;
}

const AccordionSubscriptions: FC<AccordionProps> = ({
  options,
  isExpanded,
  handleExpandMenu,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const isSubscriptionsActive =
    location.pathname?.includes(PathNames.mySubscribers) ||
    location.pathname?.includes(PathNames.chats) ||
    location.pathname?.includes(PathNames.topicsOfChats) ||
    location.pathname?.includes(PathNames.subscriptions);
  const [showList, setShowList] = useState(isSubscriptionsActive);
  const isMenuItemActive =
    (isSubscriptionsActive && !showList) ||
    (!isExpanded && isSubscriptionsActive);

  return (
    <AccordionStyled>
      <div
        className={`accordion-root ${isMenuItemActive ? 'active' : ''}`}
        onClick={() => {
          setShowList((prev) => (!isExpanded ? true : !prev));
          handleExpandMenu();
        }}
      >
        <div className="accordion-title">
          <SubscriptionsIcon />
          <p>{t('clinicsConfiguration.aside_menu.subscriptions')}</p>
        </div>
        <div className="accordion-expand">
          {!showList ? <ExpandMoreIcon /> : <ExpandLessIcon />}
        </div>
      </div>
      {showList && isExpanded && (
        <div className="accordion-list">
          {options.map((item, index) => (
            <MenuItem key={index} name={item.name} path={item.path} />
          ))}
        </div>
      )}
    </AccordionStyled>
  );
};
export default AccordionSubscriptions;
