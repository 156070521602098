import { createSlice } from '@reduxjs/toolkit';
import { getPrivateDoctor, updatePrivateDoctor } from './ActionCreators';
import { InitialState } from './models';

const initialState: InitialState = {
  isLoading: false,
  error: '',
  privateDoctor: null,
};

const privateDoctorSlice = createSlice({
  name: 'privateDoctor',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // get private doctor
    builder.addCase(getPrivateDoctor.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getPrivateDoctor.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(getPrivateDoctor.fulfilled, (state, action) => {
      state.isLoading = false;
      state.privateDoctor = action.payload;
    });
    // update private doctor
    builder.addCase(updatePrivateDoctor.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updatePrivateDoctor.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(updatePrivateDoctor.fulfilled, (state, action) => {
      state.isLoading = false;
      state.privateDoctor = action.payload;
    });
  },
});

// export const {} = privateDoctorSlice.actions;

export default privateDoctorSlice.reducer;
