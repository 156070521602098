import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UseFormSetValue } from 'react-hook-form';
import { Input } from 'common/components';
import { SearchInput } from './styles';
import { fetchAppointmentPatients } from 'applicaiton/store/reducers/Appointments/ActionCreators';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import {
  setPatients,
  setCurrentFamilyMemberSearch,
  setCurrentPatient,
} from 'applicaiton/store/reducers/Appointments/AppoinmentsSlice';
import {
  EmptyResults,
  ResultsItem,
  SearchResults,
  MainPatientInfo,
  PatientInfo,
} from '../PatientSearchList/styles';
import { ReactComponent as SearchIcon } from 'applicaiton/assets/search.svg';
import { ReactComponent as PickIcon } from 'applicaiton/assets/pick.svg';
import useOnClickOutside from 'common/hooks/useClickOutside';
import { AppointmentFieldsT } from 'features/feature-reserve-slots-modal/hooks/useCreateAppointment';
import { useDebounce } from 'common/hooks/useDebounce';
import { PatientSearchResponseDto } from '@docbay/schemas';

const PatientSearch = ({
  errors,
  setValue,
  handleResetPatient,
}: {
  errors: { [x: string]: any };
  setValue: UseFormSetValue<AppointmentFieldsT>;
  handleResetPatient: () => void;
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const refSearchResults = useRef(null);
  const { appointmentPatients, currentPatient } = useAppSelector(
    (state) => state.appointmentsSlice,
  );
  const [patientsInputValue, setPatientsInputValue] = useState('');
  const searchResult = useDebounce(patientsInputValue, 500);

  useOnClickOutside(refSearchResults, () => {
    dispatch(setPatients(null));
  });

  useEffect(() => {
    !!currentPatient &&
      setPatientsInputValue(
        `${currentPatient?.firstName} ${currentPatient?.lastName}`,
      );
  }, [currentPatient]);

  const handlePatientsSearch = (value: string) => {
    setPatientsInputValue(value);
  };

  useEffect(() => {
    if (searchResult.length < 3) {
      dispatch(setPatients(null));
    } else {
      dispatch(fetchAppointmentPatients({ search: searchResult }));
    }
  }, [searchResult]);

  const clearPatients = () => {
    setPatientsInputValue('');
    dispatch(setPatients(null));
    dispatch(setCurrentFamilyMemberSearch(null));
    dispatch(setCurrentPatient(null));
    setValue('patientId', '', { shouldValidate: !!Object.keys(errors).length });
  };

  const handleSelectPatient = (item: PatientSearchResponseDto) => {
    setValue('patientId', item?.isPatient ? item?.id! : item?.patient?.id!, {
      shouldValidate: true,
    });
    dispatch(setCurrentFamilyMemberSearch(item));
    dispatch(setPatients(null));
    handleResetPatient();
  };

  return (
    <SearchInput>
      <Input
        id={'patientId'}
        type="search"
        value={patientsInputValue}
        onClear={clearPatients}
        onChange={(e) => handlePatientsSearch(e.target.value)}
        placeholder={t('scheduler.search_patient_placeholder') || ''}
        errorMessage={errors?.patientId?.message}
        label={t('patient.patient')!}
      />
      <>
        {appointmentPatients && !!appointmentPatients.length && (
          <SearchResults ref={refSearchResults}>
            {appointmentPatients!.map((item) => {
              return (
                <ResultsItem
                  key={item.id}
                  onClick={() => handleSelectPatient(item)}
                >
                  <SearchIcon />
                  <div>
                    <MainPatientInfo>
                      {item.firstName} {item.lastName}{' '}
                      {item.relationship ? `(${item.relationship})` : ''}
                    </MainPatientInfo>
                    <PatientInfo>{item.phone}</PatientInfo>
                    {!!item?.familyMembers?.length &&
                      item?.familyMembers?.map((member) => (
                        <PatientInfo
                          key={member.id}
                        >{`${member.relationship} ${member.firstName} ${member.lastName}`}</PatientInfo>
                      ))}
                  </div>
                </ResultsItem>
              );
            })}
          </SearchResults>
        )}
        {appointmentPatients && !appointmentPatients?.length && (
          <EmptyResults ref={refSearchResults}>
            <PickIcon />
            <p>{t('patient.patient_not_found')}</p>
          </EmptyResults>
        )}
      </>
    </SearchInput>
  );
};

export default PatientSearch;
