import styled from 'styled-components';

export const Content = styled.div`
  padding: 32px;

  > div {
    display: flex;
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const StyledDivider = styled.div`
  width: 100%;
  height: 1px;
  margin: 24px 0;
  background: ${({ theme }) => theme.color.grey200};
`;

export const InputWrapper = styled.div`
  width: 100%;
`;

export const ButtonsWrapper = styled.div`
  width: fit-content;
  display: flex;
  justify-content: flex-end;

  button {
    min-width: 150px;
    width: fit-content;
    &:last-child {
      margin-left: 20px;
    }
  }
`;

export const WarningWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  border-radius: 8px;
  padding: 16px;
  border: 1px solid ${({ theme }) => theme.color.warning2};
  background: ${({ theme }) => theme.color.warning3};

  svg {
    color: ${({ theme }) => theme.color.warning2};
  }
`;

export const WarningInfo = styled.div`
  margin-left: 12px;
`;

export const WarningTitle = styled.p`
  color: ${({ theme }) => theme.color.warning};
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  margin-bottom: 4px;
`;

export const WarningText = styled.p`
  color: ${({ theme }) => theme.color.warning2};
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
`;

export const DropdownClinicsSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  > div {
    width: 100%;
  }
`;

export const ButtonSection = styled.div`
  width: 100%;
  padding: 32px;
  border-top: 1px solid ${({ theme }) => theme.color.grey200};
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;

  > button {
    min-width: 150px;
    width: fit-content;
    svg {
      width: 24px;
      height: 24px;
      path {
        fill: ${({ theme }) => theme.color.grey700};
      }
    }
  }
`;
