import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs, { Dayjs } from 'dayjs';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import { useLocation } from 'react-router-dom';
import { SearchProfessionalsByFiltersResponseDto } from '@docbay/schemas';
import { searchProfessionalsByFilters } from 'applicaiton/store/reducers/Scheduler/ActionCreators';
import { searchAgenda } from 'applicaiton/store/reducers/Agenda/ActionCreators';
import {
  setAgendaProfessionals,
  setClinicId,
  setSelectedDate,
  setSelectedProfessionals,
  setSlotsTimeView,
} from 'applicaiton/store/reducers/Scheduler/SchedulerSlice';
import { CalendarView, ViewBy } from 'applicaiton/constants/scheduler';
import { ReactComponent as ExpandMoreIcon } from 'applicaiton/assets/expand-more.svg';
import { resetAgenda } from 'applicaiton/store/reducers/Agenda/AgendaSlice';
import { agendaFiltersSessionStorage } from 'applicaiton/sessionStorage/scheduler';
import {
  setIsHighlightedAppointment,
  resetPatientAppointmentsData,
  setCurrentAppointment,
} from 'applicaiton/store/reducers/Appointments/PatientAppoinmentsSlice';
import {
  setClearPatientSearch,
  setCurrentPatient,
} from 'applicaiton/store/reducers/Patients/PatientsSlice';
import { getUserId, getUserRole } from 'applicaiton/sessionStorage/auth';
import { UserRoles } from 'applicaiton/constants/userRoles';
import { setIsAppointmentDeleted } from 'applicaiton/store/reducers/Appointments/AppoinmentsSlice';
import { fetchProfessionalById } from 'applicaiton/store/reducers/Professionals/ActionCreators';
import { PathNames } from 'applicaiton/routes';
import { useAppSelector, useAppDispatch } from 'common/hooks/redux';
import {
  Checkbox,
  Input,
  RadioButton,
  TimeZoneDropdown,
} from 'common/components';
import { useDebounce } from 'common/hooks/useDebounce';
import { useUserTimeZone } from 'common/hooks/useUserTimeZone';

import { useTimeView } from 'features/feature-scheduler/hooks/useTimeView';
import { getSelectedDays } from 'features/feature-scheduler/helpers/getSelectedDays';
import { DateCalendarStyled } from '../FilterSection/styles';
import {
  Wrapper,
  ProfessionalSection,
  CheckboxSection,
  TimeViewSection,
  CheckboxStyled,
  TimeViewList,
  RadioButtonStyled,
  TimezoneStyled,
} from './styles';
import Loader from 'common/components/Loader';

dayjs.extend(dayOfYear);

const FilterByDoctor: FC = () => {
  const userRole = getUserRole();
  const userId = getUserId();
  const location = useLocation();
  const isUserRoleProfessional = userRole === UserRoles.professional;
  const isMyAgendaPage = location.pathname === PathNames.clinicOwnerSchedule;
  const showProfessionalsSection = !isUserRoleProfessional && !isMyAgendaPage;

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const sessionStorageData = agendaFiltersSessionStorage();
  const timeViewList = useTimeView();
  const { viewBy, byProfessionals, professionals, refreshAgendaCounter } =
    useAppSelector((state) => state.scheduler);
  const { agenda } = useAppSelector((state) => state.agenda);
  const { tz, setUserTimeZone } = useUserTimeZone();
  const { currentAppointment, isHighlighted } = useAppSelector(
    (state) => state.patientAppointments,
  );
  const { isCreatedAppointment, isDeleted, isEdited } = useAppSelector(
    (state) => state.appointmentsSlice,
  );
  const { isLoading: isTimeZoneLoading } = useAppSelector(
    (state) => state.timeZone,
  );

  const { currentClinicOwner } = useAppSelector(
    (state) => state.clinicOwnerSlice,
  );
  const [showTimeViewList, setShowTimeViewList] = useState(true);
  const [showProfessionalList, setShowProfessionalList] = useState(true);
  const [timeView, setTimeView] = useState<string>(
    sessionStorageData.timeView || timeViewList[0].value,
  );
  const [searchValue, setSearchValue] = useState('');
  const searchItem = useDebounce(searchValue, 300);
  const isWeeklyView = byProfessionals.calendarView === CalendarView.weekly;
  const selectedDate = byProfessionals.selectedDate;
  const clinicId = byProfessionals.clinicId;
  const selectedProfessionals = useMemo(() => {
    return byProfessionals.selectedProfessionals;
  }, [byProfessionals.selectedProfessionals.length]);
  const agendaProfessionals = useMemo(() => {
    return byProfessionals.agendaProfessionals;
  }, [byProfessionals.agendaProfessionals.length]);

  const getProfessionalsByFilters = async () => {
    await dispatch(
      searchProfessionalsByFilters({
        clinicId,
        onlyAvailable: false,
        onlyActive: true,
        text: searchItem,
      }),
    );
  };

  useEffect(() => {
    if (clinicId && !isMyAgendaPage) {
      getProfessionalsByFilters();
    }
  }, [searchItem, clinicId, refreshAgendaCounter]);

  const getProfessionalProfile = async (id?: string) => {
    if (!id) return;

    const response = await dispatch(fetchProfessionalById(String(id)));
    if (response.meta.requestStatus === 'fulfilled') {
      dispatch(
        setSelectedProfessionals({
          professionals: [response.payload as any],
          key: ViewBy.byProfessionals,
        }),
      );
      dispatch(
        setAgendaProfessionals({
          professionals: [response.payload as any],
          key: ViewBy.byProfessionals,
        }),
      );
    }
  };

  useEffect(() => {
    if (isMyAgendaPage && currentClinicOwner?.professional?.id) {
      getProfessionalProfile(currentClinicOwner.professional.id);
    } else if (isUserRoleProfessional) {
      getProfessionalProfile(userId!);
    }
  }, [isUserRoleProfessional, currentClinicOwner?.professional?.id]);

  useEffect(() => {
    if (currentAppointment) {
      dispatch(
        setSelectedDate({
          date: dayjs(currentAppointment.startDate).toString(),
          key: viewBy,
        }),
      );
      if (clinicId !== currentAppointment.clinic?.id) {
        dispatch(
          setClinicId({
            clinicId: String(currentAppointment.clinic!.id || ''),
            key: viewBy,
          }),
        );
        dispatch(
          setSelectedProfessionals({
            professionals: [
              ...selectedProfessionals,
              currentAppointment.professional,
            ] as SearchProfessionalsByFiltersResponseDto[],
            key: ViewBy.byProfessionals,
          }),
        );
        dispatch(
          setAgendaProfessionals({
            professionals: [
              ...agendaProfessionals,
              currentAppointment.professional,
            ] as SearchProfessionalsByFiltersResponseDto[],
            key: ViewBy.byProfessionals,
          }),
        );
      } else {
        const isProfessionalSelected = selectedProfessionals.some(
          (item) => item.id === currentAppointment.professional?.id,
        );
        if (!isProfessionalSelected) {
          dispatch(
            setSelectedProfessionals({
              professionals: [
                ...selectedProfessionals,
                currentAppointment.professional,
              ] as SearchProfessionalsByFiltersResponseDto[],
              key: ViewBy.byProfessionals,
            }),
          );
          dispatch(
            setAgendaProfessionals({
              professionals: [
                ...agendaProfessionals,
                currentAppointment.professional,
              ] as SearchProfessionalsByFiltersResponseDto[],
              key: ViewBy.byProfessionals,
            }),
          );
        }
      }
      dispatch(resetPatientAppointmentsData());
      dispatch(setCurrentPatient(null));
    }
  }, [currentAppointment]);

  useEffect(() => {
    if (selectedProfessionals.length) {
      const { startDate, endDate } = getSelectedDays(
        isWeeklyView,
        selectedDate,
      );

      const timeRangeDividerIndex = timeView.indexOf('-');
      const startTime = timeView.slice(0, timeRangeDividerIndex);
      const endTime = timeView.slice(
        timeRangeDividerIndex + 1,
        timeView.length,
      );

      const professionalIds = selectedProfessionals.map((item) => item.id!);

      dispatch(
        searchAgenda({
          startDate: startDate,
          endDate: endDate,
          professionalIds,
          ...(isMyAgendaPage || isUserRoleProfessional
            ? {}
            : { clinicId: clinicId }),
          timeRange: {
            startTime: startTime,
            endTime: endTime,
          },
          onlyAvailable: false,
        }),
      ).then(() => {
        dispatch(setIsAppointmentDeleted(false));
      });
    }
    if (!selectedProfessionals.length && agenda.length) {
      dispatch(resetAgenda());
    }
  }, [
    clinicId,
    selectedProfessionals.length,
    selectedDate,
    timeView,
    isCreatedAppointment,
    isDeleted,
    isEdited,
    refreshAgendaCounter,
    isWeeklyView,
  ]);
  const clearPatientSearchResult = () => {
    if (isHighlighted) {
      dispatch(setCurrentAppointment(null));
      dispatch(setIsHighlightedAppointment(false));
    }
    dispatch(setClearPatientSearch(true));
  };

  const handleSelectProfessional = (
    checked: boolean,
    professional: SearchProfessionalsByFiltersResponseDto,
  ) => {
    if (checked) {
      dispatch(
        setSelectedProfessionals({
          professionals: [...selectedProfessionals, professional],
          key: viewBy,
        }),
      );
      dispatch(
        setAgendaProfessionals({
          professionals: [...selectedProfessionals, professional],
          key: viewBy,
        }),
      );
    } else {
      const professionalWithHighLightAppointmentUnselected =
        currentAppointment?.professional?.id === professional.id;
      if (professionalWithHighLightAppointmentUnselected) {
        clearPatientSearchResult();
      }
      dispatch(
        setSelectedProfessionals({
          professionals: selectedProfessionals.filter(
            (item) => item.id !== professional.id,
          ),
          key: viewBy,
        }),
      );
      dispatch(
        setAgendaProfessionals({
          professionals: selectedProfessionals.filter(
            (item) => item.id !== professional.id,
          ),
          key: viewBy,
        }),
      );
    }
  };

  useEffect(() => {
    if (selectedProfessionals.length) {
      dispatch(
        setAgendaProfessionals({
          professionals: selectedProfessionals,
          key: viewBy,
        }),
      );
    }
  }, [selectedProfessionals]);

  const checkProfessionalSelected = (value: string) => {
    return selectedProfessionals.some((item) => item.id === value);
  };

  const handleSelectDay = (date: Dayjs) => {
    clearPatientSearchResult();
    dispatch(
      setSelectedDate({
        date: dayjs(date).toString(),
        key: viewBy,
      }),
    );
  };

  const handleSelectTimeView = (time: string) => {
    setTimeView(time);
    dispatch(
      setSlotsTimeView({
        slotsTimeView: time,
        key: viewBy,
      }),
    );
  };

  return (
    <Wrapper>
      {isTimeZoneLoading && <Loader />}
      <DateCalendarStyled
        value={dayjs(selectedDate)}
        onChange={(newValue) => handleSelectDay(newValue as Dayjs)}
        views={['day']}
        showDaysOutsideCurrentMonth={true}
      />
      <TimezoneStyled>
        <TimeZoneDropdown
          id="time-zone"
          value={tz}
          onChange={setUserTimeZone}
        />
      </TimezoneStyled>
      <TimeViewSection isActive={showTimeViewList}>
        <div onClick={() => setShowTimeViewList((prev) => !prev)}>
          <h2>{t('scheduler.time_view')} </h2>
          <ExpandMoreIcon />
        </div>
        {showTimeViewList && (
          <TimeViewList>
            {timeViewList.map((item) => (
              <RadioButtonStyled
                key={item.value}
                isActive={timeView === item.value}
              >
                <RadioButton
                  id={item.value}
                  checked={timeView === item.value}
                  onChange={() => handleSelectTimeView(item.value)}
                  label={item.label}
                  subLabel={item.subLabel}
                />
              </RadioButtonStyled>
            ))}
          </TimeViewList>
        )}
      </TimeViewSection>
      {showProfessionalsSection && (
        <ProfessionalSection isActive={showProfessionalList}>
          <div onClick={() => setShowProfessionalList((prev) => !prev)}>
            <h2>
              {t('scheduler.all_professionals')} ({selectedProfessionals.length}
              )
            </h2>
            <ExpandMoreIcon />
          </div>
          {showProfessionalList && (
            <>
              <Input
                id="professionalSearch"
                type={'search'}
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                onClear={() => setSearchValue('')}
                placeholder={t('scheduler.professionals_search_placeholder')!}
              />
              <CheckboxSection>
                {professionals?.map((professional) => (
                  <CheckboxStyled
                    key={professional.id}
                    isActive={checkProfessionalSelected(professional.id!)}
                  >
                    <Checkbox
                      id={`professional ${String(professional?.id)}`}
                      checked={checkProfessionalSelected(professional.id!)}
                      onChange={(checked) =>
                        handleSelectProfessional(checked, professional)
                      }
                      label={`${professional.firstName} ${professional.lastName}`}
                    />
                  </CheckboxStyled>
                ))}
              </CheckboxSection>
            </>
          )}
        </ProfessionalSection>
      )}
    </Wrapper>
  );
};

export default FilterByDoctor;
