import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { FamilyMemberInfoT } from '../types';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { nifValidate } from 'common/helpers/nifValidate';

export const useFamilyMemberForm = ({
  requiredError,
  numberError,
  lengthFinError,
  lengthSsnError,
}: {
  requiredError: string;
  numberError: string;
  lengthFinError: string;
  lengthSsnError: string;
}) => {
  const formSubscriptionValues = {
    firstName: '',
    lastName: '',
    relationship: '',
    dateOfBirth: '',
    nif: '',
    socialSecurityNumber: '',
  };

  const subscriptionSchema = yup
    .object({
      firstName: yup.string().required(requiredError!),
      lastName: yup.string().required(requiredError!),
      relationship: yup.string().required(requiredError!),
      dateOfBirth: yup.string().required(requiredError),
      nif: yup.string().test({
        name: 'nif',
        test(value, ctx) {
          if (!value || value?.length === 0) return true;

          const isNum = nifValidate(value);

          if (value?.length !== 9) {
            return ctx.createError({ message: lengthFinError });
          } else if (!isNum) {
            return ctx.createError({ message: numberError });
          }
          return isNum;
        },
      }),
      socialSecurityNumber: yup.string().test({
        name: 'socialSecurityNumber',
        test(value, ctx) {
          if (!value || value?.length === 0) return true;

          const isNum = /^\d+$/.test(value!);

          if (!isNum) {
            return ctx.createError({ message: numberError });
          } else if (value?.length !== 11) {
            return ctx.createError({ message: lengthSsnError });
          }

          return isNum;
        },
      }),
    })
    .required();

  const {
    watch,
    getValues,
    setValue,
    handleSubmit,
    clearErrors,
    setError,
    reset,
    register,
    formState: { errors },
  } = useForm<FamilyMemberInfoT>({
    resolver: yupResolver(subscriptionSchema),
    defaultValues: formSubscriptionValues,
  });

  return {
    watch,
    getValues,
    setValue,
    errors,
    handleSubmit,
    clearErrors,
    setError,
    reset,
    register,
  };
};
