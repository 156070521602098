import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';

export interface ConsultationData {
  reason?: string;
  symptoms?: string[];
  respiratoryRate?: number;
  spO2?: number;
  pulse?: number;
  bloodPressureSystolic?: number;
  bloodPressureDiastolic?: number;
  temperature?: number;
  bloodGlucose?: number;
  weight?: number;
  height?: number;
}

export const useConsultationDataForm = ({
  minValue,
  maxValue,
  integer,
}: {
  minValue: string;
  maxValue: string;
  integer: string;
}) => {
  const UserSchema = yup.object({
    reason: yup.string().nullable(),
    symptoms: yup.array().of(yup.string()).nullable(),
    respiratoryRate: yup
      .number()
      .integer(integer)
      .transform((value) => (isNaN(value) ? undefined : value))
      .min(5, `${minValue} 5`)
      .max(100, `${maxValue} 100`)
      .nullable(),
    spO2: yup
      .number()
      .integer(integer)
      .transform((value) => (isNaN(value) ? undefined : value))
      .min(1, `${minValue} 1`)
      .max(100, `${maxValue} 100`)
      .nullable(),
    pulse: yup
      .number()
      .integer(integer)
      .transform((value) => (isNaN(value) ? undefined : value))
      .min(1, `${minValue} 1`)
      .max(200, `${maxValue} 200`)
      .nullable(),
    bloodPressureSystolic: yup
      .number()
      .integer(integer)
      .transform((value) => (isNaN(value) ? undefined : value))
      .min(1, `${minValue} 1`)
      .max(300, `${maxValue} 300`)
      .nullable(),
    bloodPressureDiastolic: yup
      .number()
      .integer(integer)
      .transform((value) => (isNaN(value) ? undefined : value))
      .min(1, `${minValue} 1`)
      .max(200, `${maxValue} 200`)
      .nullable(),
    temperature: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .min(35, `${minValue} 35`)
      .max(44, `${maxValue} 44`)
      .nullable(),
    bloodGlucose: yup
      .number()
      .integer(integer)
      .transform((value) => (isNaN(value) ? undefined : value))
      .min(2, `${minValue} 2`)
      .max(50, `${maxValue} 50`)
      .nullable(),
    weight: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .min(1, `${minValue} 1`)
      .nullable(),
    height: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .min(1, `${minValue} 1`)
      .nullable(),
  });

  const {
    watch,
    getValues,
    setValue,
    register,
    handleSubmit,
    clearErrors,
    trigger,
    setError,
    formState: { errors },
  } = useForm<ConsultationData>({
    resolver: yupResolver(UserSchema),
  });

  return {
    watch,
    getValues,
    setValue,
    errors,
    register,
    handleSubmit,
    clearErrors,
    trigger,
    setError,
  };
};
