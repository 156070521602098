import React, { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Specialization, SupportedCountriesIso3166 } from '@docbay/schemas';

import { AddIcon, CloseIcon } from 'applicaiton/assets';
import { fetchProfessionalsSpokenLanguages } from 'applicaiton/store/reducers/Professionals/ActionCreators';
import { getLanguageFromLocalStorage } from 'applicaiton/sessionStorage/language';
import { fetchSpecializations } from 'applicaiton/store/reducers/Specializations/ActionCreators';

import {
  CountryDropdown,
  DatePicker,
  Dropdown,
  Input,
  InputPhone,
  PhotoUploaderMultiCrop,
  RadioButton,
  TextArea,
} from 'common/components';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { useGenderOptions } from 'common/hooks/useGenderOptions';
import { Photo } from 'common/types/clinics';
import { useLocalizeKey } from 'common/hooks/useLocalizeKey';

import Schedule from '../Schedule';
import SocialMedia from '../SocialMedia';

import { PrivateDoctorProfessionalProfileProps } from './models';
import {
  Wrapper,
  FieldsSection,
  RadioButtonSection,
  StyledDivider,
  Specializations,
  DropdownList,
  ScheduleFieldItem,
  ScheduleWrapper,
} from './styles';
import { getUserCountryIso } from 'applicaiton/sessionStorage/auth';

const PrivateDoctorProfessionalProfile: FC<
  PrivateDoctorProfessionalProfileProps
> = ({ register, errors, setError, setValue, watch, clearErrors }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const { languages } = useAppSelector(
    (state) => state.professionalsSpokenLanguages,
  );
  const { specializations } = useAppSelector((state) => state.specializations);

  const genders = useGenderOptions();
  const { localizeNameKey } = useLocalizeKey();

  const specializationsToAdd = watch(
    'professionalData.clinicsRelation.specializationsToAdd',
  );

  const countryIso = (watch('countryOfPracticeIso') ||
    getUserCountryIso()) as SupportedCountriesIso3166;

  const languagesOptions = useMemo(() => {
    return languages
      ?.map((item) => {
        return { label: item[localizeNameKey], value: item.id };
      })
      .sort((a, b) => (a.label > b.label ? 1 : -1));
  }, [languages, localizeNameKey]);

  const specializationsOptions = useMemo(() => {
    const language = getLanguageFromLocalStorage();
    const specializationLangKey = `name_${language.toUpperCase()}`;

    const options = specializations.map((item) => {
      const specializationName =
        item[specializationLangKey as keyof Specialization];

      return {
        value: item.id,
        label: specializationName as string,
      };
    });

    return options;
  }, [specializations, i18n.language]);

  const canAddMoreSpecializations =
    specializationsToAdd.length < specializationsOptions.length;

  const getUniqSpecializationsList = (specializationId: string) => {
    const uniqOptionsList = specializationsOptions.filter((item) => {
      const specializationSelected = specializationsToAdd.some(
        (id) => id === item.value && id !== specializationId,
      );
      if (!specializationSelected) return item;
    });

    return uniqOptionsList;
  };

  useEffect(() => {
    dispatch(fetchProfessionalsSpokenLanguages());
    dispatch(fetchSpecializations());
  }, []);

  const handleChangePhotos = (photos: Photo[]) => {
    setValue('professionalData.photos', photos as any);
  };

  const handleAddSpecialization = () => {
    const updatedSpecializationsList = [...specializationsToAdd, ''];
    setValue(
      'professionalData.clinicsRelation.specializationsToAdd',
      updatedSpecializationsList,
    );
  };

  const handleEditSpecializations = (index: number, value: string) => {
    const updatedSpecializationsList = specializationsToAdd.map((item, i) =>
      i === index ? value : item,
    );
    setValue(
      'professionalData.clinicsRelation.specializationsToAdd',
      updatedSpecializationsList,
    );
  };

  const handleRemoveSpecialization = (index: number) => {
    const updatedSpecializationsList = specializationsToAdd.filter(
      (_, i) => i !== index,
    );
    setValue(
      'professionalData.clinicsRelation.specializationsToAdd',
      updatedSpecializationsList,
    );
  };

  const clearAgendaHoursErrors = () => {
    clearErrors('professionalData.clinicsRelation.schedule.hours');
  };

  return (
    <Wrapper>
      <h1>{t('Public professional profile')}</h1>
      <FieldsSection>
        <h1>{t('Profile info')}</h1>
        <Input
          id={'professionalData.medicalNumber'}
          type="text"
          label={t('personal_information.medical_number') || ''}
          placeholder={
            t('personal_information.medical_number_placeholder') || ''
          }
          register={register}
          errorMessage={errors.professionalData?.medicalNumber?.message}
        />
        <Dropdown
          id={'professionalData.spokenLanguageIds'}
          value={watch('professionalData.spokenLanguageIds')}
          label={t('personal_information.spoken_languages') || ''}
          placeholder={
            t('personal_information.spoken_languages_placeholder') || ''
          }
          searchPlaceholder={
            t('personal_information.spoken_languages_search') || ''
          }
          onChange={(value) => {
            Array.isArray(value) &&
              setValue('professionalData.spokenLanguageIds', value, {
                shouldValidate: !!errors?.professionalData?.spokenLanguageIds,
              });
          }}
          options={languagesOptions}
          isMulti={true}
          errorMessage={
            (errors?.professionalData?.spokenLanguageIds as any)?.message
          }
          withSearch={true}
        />
        <DatePicker
          id={'dateOfBirth'}
          label={t('personal_information.date_birth') || ''}
          value={watch('professionalData.dateOfBirth') || ''}
          format={'YYYY-MM-DD'}
          onChange={(value) => {
            setValue(
              'professionalData.dateOfBirth',
              dayjs(value).toISOString(),
              {
                shouldValidate: !!errors?.professionalData?.dateOfBirth,
              },
            );
          }}
          maxDate={dayjs(dayjs().subtract(18, 'year')).valueOf()}
          errorMessage={errors?.professionalData?.dateOfBirth?.message}
          views={['year', 'month', 'day']}
          openTo="year"
        />
        <InputPhone
          id="professionalData.phone"
          label={t('phone') || ''}
          register={register}
          errorMessage={errors.professionalData?.phone?.message}
          value={watch('professionalData.phone')}
          isValid={true}
        />
        <RadioButtonSection
          isError={!!errors?.professionalData?.gender?.message}
        >
          <p>{t('personal_information.gender.title') || ''}</p>
          {genders.map((item) => (
            <RadioButton
              key={item.value}
              id={item.value}
              label={item.label}
              checked={watch('professionalData.gender') === item.value}
              onChange={() =>
                setValue('professionalData.gender', item.value, {
                  shouldValidate: !!errors?.professionalData?.gender,
                })
              }
            />
          ))}
          {errors?.professionalData?.gender?.message && (
            <span>{errors?.professionalData?.gender?.message}</span>
          )}
        </RadioButtonSection>

        <TextArea
          id={'professionalData.description'}
          register={register}
          label={t('personal_information.description') || ''}
          textLength={watch('professionalData.description') || ''}
          maxTextLength={2000}
          errorMessage={errors.professionalData?.description?.message}
        />

        <PhotoUploaderMultiCrop
          currentPhotos={watch('professionalData.photos') as Photo[]}
          setCurrentPhotos={handleChangePhotos}
          label={t('upload_photo_optional')!}
          isDeprecateMainPhotoDeletion={false}
        />
        <SocialMedia
          errors={errors}
          register={register}
          watch={watch}
          setValue={setValue}
        />
      </FieldsSection>

      <StyledDivider />

      <FieldsSection>
        <h1>{t('country_of_practice')}</h1>
        <CountryDropdown
          label={t('select_country_of_practice') || ''}
          placeholder={t('select_country_of_practice')}
          country={{ id: '', code: countryIso, name: '' }}
          onChange={(country) =>
            setValue(
              'countryOfPracticeIso',
              country.code as SupportedCountriesIso3166,
            )
          }
        />
      </FieldsSection>

      <StyledDivider />

      <DropdownList>
        <label>{t('professional_profile.specialization')}</label>
        <Specializations>
          {specializationsToAdd.map((item, index) => (
            <div key={index}>
              <Dropdown
                id={'professionalData.clinicsRelation.specializationsToAdd'}
                value={item}
                placeholder={t(
                  'professional_profile.specialization_placeholder',
                )}
                onChange={(value) => {
                  if (!Array.isArray(value)) {
                    handleEditSpecializations(index, String(value.value));
                  }
                }}
                options={getUniqSpecializationsList(item)}
                withSearch={true}
                errorMessage={
                  (errors?.professionalData?.clinicsRelation
                    ?.specializationsToAdd || [])[index]?.message
                }
              />
              {index ? (
                <button
                  onClick={() => {
                    handleRemoveSpecialization(index);
                  }}
                >
                  <CloseIcon />
                </button>
              ) : (
                ''
              )}
            </div>
          ))}
        </Specializations>
        <button
          disabled={!canAddMoreSpecializations}
          type="button"
          onClick={() => {
            handleAddSpecialization();
          }}
        >
          <AddIcon />
          <h2>{t('add_another')}</h2>
        </button>
      </DropdownList>
      <StyledDivider />

      <ScheduleFieldItem
        isError={!!errors?.professionalData?.clinicsRelation?.schedule?.hours}
      >
        <label>{t('professional_profile.agenda_hours')}</label>
        <ScheduleWrapper>
          <Schedule
            watch={watch}
            clearErrors={clearAgendaHoursErrors}
            setValue={setValue}
          />
        </ScheduleWrapper>
      </ScheduleFieldItem>
    </Wrapper>
  );
};

export default PrivateDoctorProfessionalProfile;
