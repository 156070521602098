import React, { useEffect, useState } from 'react';
import {
  Wrapper,
  DataWrapper,
  DataSection,
  Information,
  Metrics,
  MetricsWrapper,
  HistoryList,
  DataItem,
  MainData,
  MainDataText,
  SymptomList,
} from './styles';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { getConsultationDataHistory } from 'applicaiton/store/reducers/Consultation/ActionCreators';
import HistoryListItem from '../HistoryListItem';
import Loader from 'common/components/Loader';
import { AppointmentHistoryResponseDto } from '@docbay/schemas';
import { ReactComponent as EmergencyIcon } from 'applicaiton/assets/emergency.svg';
import { useLocalizeKey } from 'common/hooks/useLocalizeKey';
import { MarkdownText } from 'common/components';

const ConsultationHistory = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { localizeNameKey } = useLocalizeKey();
  const { appointmentId } = useParams();
  const [currentAppointmentData, setCurrentAppointmentData] =
    useState<AppointmentHistoryResponseDto>();

  const { history, isLoading } = useAppSelector(
    (state) => state.consultationData,
  );

  useEffect(() => {
    appointmentId && dispatch(getConsultationDataHistory(appointmentId!));
  }, []);

  useEffect(() => {
    if (history) {
      const currentAppointment = history.find(
        (item) => item.id === appointmentId,
      );
      setCurrentAppointmentData(currentAppointment);
    }
  }, [history]);

  const getCurrentConsultationData = (
    appointmentData: AppointmentHistoryResponseDto,
  ) => {
    setCurrentAppointmentData(appointmentData);
  };

  if (isLoading) {
    return <Loader />;
  }
  return (
    <Wrapper>
      <DataWrapper>
        <DataSection>
          <HistoryList>
            {history?.map((item) => {
              return (
                <HistoryListItem
                  key={item.id}
                  item={item}
                  isActive={item.id === currentAppointmentData?.id!}
                  onClick={getCurrentConsultationData}
                />
              );
            })}
          </HistoryList>
          <div>
            <MainData>
              <Information>
                <h3>{t('consultation_data.consultation_information')}</h3>
                <DataItem>
                  <h6>{t('consultation_data.reasons_for_consultation')}</h6>
                  <div>{currentAppointmentData?.appointmentData?.reason}</div>
                </DataItem>
                <DataItem>
                  <h6>{t('consultation_data.select_symptoms')}</h6>
                  <SymptomList>
                    {currentAppointmentData?.appointmentData?.symptoms?.map(
                      (item) => {
                        return (
                          <li key={`symptom_${item.id}`}>
                            {item[localizeNameKey]}
                          </li>
                        );
                      },
                    )}
                  </SymptomList>
                </DataItem>
              </Information>
              <Metrics>
                <h3>{t('consultation_data.health_metrics')}</h3>
                <MetricsWrapper>
                  <div>
                    <DataItem>
                      <h6>{t('consultation_data.respiratory_rate')}</h6>
                      <div>
                        {!!currentAppointmentData?.appointmentData
                          ?.healthMetrics?.respiratoryRate?.isInAlert && (
                          <EmergencyIcon />
                        )}
                        {currentAppointmentData?.appointmentData?.healthMetrics
                          ?.respiratoryRate?.value
                          ? `${currentAppointmentData?.appointmentData?.healthMetrics?.respiratoryRate?.value?.toFixed()} ${t(
                              'metrics.bpm',
                            )}`
                          : '-'}
                      </div>
                    </DataItem>
                    <DataItem>
                      <h6>{t('consultation_data.pulse')}</h6>
                      <div>
                        {!!currentAppointmentData?.appointmentData
                          ?.healthMetrics?.pulse?.isInAlert && (
                          <EmergencyIcon />
                        )}
                        {currentAppointmentData?.appointmentData?.healthMetrics
                          ?.pulse?.value
                          ? `${
                              currentAppointmentData?.appointmentData
                                ?.healthMetrics?.pulse?.value
                            } ${t('metrics.bpm')}`
                          : '-'}
                      </div>
                    </DataItem>
                    <DataItem>
                      <h6>{t('consultation_data.temperature')}</h6>
                      <div>
                        {!!currentAppointmentData?.appointmentData
                          ?.healthMetrics?.temperature?.isInAlert && (
                          <EmergencyIcon />
                        )}
                        {currentAppointmentData?.appointmentData?.healthMetrics
                          ?.temperature?.value ? (
                          <span>
                            {currentAppointmentData?.appointmentData?.healthMetrics?.temperature?.value.toFixed(
                              1,
                            )}{' '}
                            &#8451;
                          </span>
                        ) : (
                          '-'
                        )}
                      </div>
                    </DataItem>
                    <DataItem>
                      <h6> {t('consultation_data.weight')}</h6>
                      <div>
                        {!!currentAppointmentData?.appointmentData
                          ?.healthMetrics?.weight?.isInAlert && (
                          <EmergencyIcon />
                        )}
                        {currentAppointmentData?.appointmentData?.healthMetrics
                          ?.weight?.value
                          ? `${currentAppointmentData?.appointmentData?.healthMetrics?.weight?.value.toFixed(
                              2,
                            )} ${t('metrics.kg')}`
                          : '-'}
                      </div>
                    </DataItem>
                  </div>
                  <div>
                    <DataItem>
                      <h6>{t('consultation_data.SpO2')}</h6>
                      <div>
                        {!!currentAppointmentData?.appointmentData
                          ?.healthMetrics?.spO2?.isInAlert && <EmergencyIcon />}
                        {currentAppointmentData?.appointmentData?.healthMetrics
                          ?.spO2?.value
                          ? `${currentAppointmentData?.appointmentData?.healthMetrics?.spO2?.value} %`
                          : '-'}
                      </div>
                    </DataItem>
                    <DataItem>
                      <h6>{t('consultation_data.blood_pressure')}</h6>
                      <div>
                        {(!!currentAppointmentData?.appointmentData
                          ?.healthMetrics?.bloodPressureSystolic?.isInAlert ||
                          !!currentAppointmentData?.appointmentData
                            ?.healthMetrics?.bloodPressureDiastolic
                            ?.isInAlert) && <EmergencyIcon />}
                        {`${
                          currentAppointmentData?.appointmentData?.healthMetrics
                            ?.bloodPressureSystolic?.value || '-'
                        }/${
                          currentAppointmentData?.appointmentData?.healthMetrics
                            ?.bloodPressureDiastolic?.value || '-'
                        }`}
                      </div>
                    </DataItem>
                    <DataItem>
                      <h6>{t('consultation_data.blood_glucose')}</h6>
                      <div>
                        {!!currentAppointmentData?.appointmentData
                          ?.healthMetrics?.bloodGlucose?.isInAlert && (
                          <EmergencyIcon />
                        )}
                        {currentAppointmentData?.appointmentData?.healthMetrics
                          ?.bloodGlucose?.value
                          ? `${
                              currentAppointmentData?.appointmentData
                                ?.healthMetrics?.bloodGlucose?.value
                            } ${t('consultation_data.mmol')}`
                          : '-'}
                      </div>
                    </DataItem>
                    <DataItem>
                      <h6>{t('consultation_data.hight')}</h6>
                      <div>
                        {!!currentAppointmentData?.appointmentData
                          ?.healthMetrics?.height?.isInAlert && (
                          <EmergencyIcon />
                        )}
                        {currentAppointmentData?.appointmentData?.healthMetrics
                          ?.height?.value
                          ? `${
                              currentAppointmentData?.appointmentData
                                ?.healthMetrics?.height?.value
                            } ${t('consultation_data.cm')}`
                          : '-'}
                      </div>
                    </DataItem>
                  </div>
                </MetricsWrapper>
              </Metrics>
            </MainData>
            <MainDataText>
              <h6>{t('consultation_data.notes')}</h6>
              <MarkdownText
                text={currentAppointmentData?.appointmentData?.notes || '-'}
              />
            </MainDataText>
            <MainDataText>
              <h6>{t('consultation_data.treatment_plan')}</h6>
              <MarkdownText
                text={
                  currentAppointmentData?.appointmentData?.treatmentPlan || '-'
                }
              />
            </MainDataText>
          </div>
        </DataSection>
      </DataWrapper>
    </Wrapper>
  );
};

export default ConsultationHistory;
