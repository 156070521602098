import React, { FC } from 'react';
import dayjs from 'dayjs';
import { CustomPickerDayProps } from './modules';
import { DayPickers } from './styles';

const CustomPickersDay: FC<CustomPickerDayProps> = (props) => {
  const { day, startDay, endDay, ...other } = props;

  const isFirstDay = startDay
    ? startDay.format('DD/MM/YYYY') === day.format('DD/MM/YYYY')
    : false;
  const isLastDay = endDay
    ? endDay.format('DD/MM/YYYY') === day.format('DD/MM/YYYY')
    : false;

  const sameDayOfYear = dayjs().dayOfYear() === dayjs(day).dayOfYear();
  const sameYear = dayjs().year() === dayjs(day).year();

  const isToday = sameDayOfYear && sameYear;
  const dayIsBetween =
    startDay && endDay ? day.isBetween(startDay, endDay, null, '[]') : false;

  return (
    <DayPickers
      day={day}
      dayIsBetween={dayIsBetween}
      isFirstDay={isFirstDay}
      isLastDay={isLastDay}
      isToday={isToday}
      {...other}
    />
  );
};
export default CustomPickersDay;
