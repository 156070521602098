import { DateCalendar } from '@mui/x-date-pickers';
import styled from 'styled-components';

interface StyledProps {
  isActive: boolean;
}

export const Wrapper = styled.aside`
  display: grid;
  justify-content: center;
  height: 100%;
  min-width: 320px;
  padding: 16px 16px 32px 16px;
  grid-template-rows: max-content 1fr;
  gap: 32px;
  border-top: 1px solid ${({ theme }) => theme.color.grey300};
  border-right: 1px solid ${({ theme }) => theme.color.grey300};
  border-bottom: 1px solid ${({ theme }) => theme.color.grey300};
  background: ${({ theme }) => theme.color.grey50};
  overflow-y: auto;

  .MuiDateCalendar-root {
    width: 277px;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  width: 281px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.color.grey300};
  box-shadow: 1px 1px 2px 0px rgba(54, 54, 54, 0.1);
`;

export const ButtonStyled = styled.button<StyledProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 10px;
  border: none;
  background: ${({ theme, isActive }) =>
    isActive ? theme.color.blue100 : theme.color.white};
  cursor: pointer;

  color: ${({ theme }) => theme.color.grey900};
  font-size: 14px;
  font-weight: ${({ isActive }) => (isActive ? '600' : '500')};
  line-height: 22.4px;

  &:hover {
    background: ${({ theme }) => theme.color.grey100};
  }

  &:first-child {
    min-width: fit-content;
    border-right: 1px solid ${({ theme }) => theme.color.grey300};
    border-radius: 8px 0 0 8px;
  }
  &:last-child {
    min-width: fit-content;
    flex: 1;
    border-radius: 0 8px 8px 0;
  }
`;

export const DateCalendarStyled = styled(DateCalendar)`
  .MuiPickersCalendarHeader-root.MuiPickersCalendarHeader-root {
    padding: 0;
    margin-top: 0;
    .MuiPickersCalendarHeader-label {
      margin-right: 6px;
      color: ${({ theme }) => theme.color.grey900};
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.1px;
    }
    .MuiPickersArrowSwitcher-root {
      .MuiPickersArrowSwitcher-spacer {
        width: 15px;
      }
      > button {
        padding: 0;
        margin: 0;
        height: fit-content;
        svg {
          path {
            fill: ${({ theme }) => theme.color.grey700};
          }
        }
      }
    }
  }

  .MuiPickersFadeTransitionGroup-root {
    .Mui-selected {
      font-size: 12px;
    }
    .MuiDayCalendar-slideTransition {
      min-height: 240px;
    }
    .MuiDayCalendar-header.MuiDayCalendar-header {
      width: 277px;
      justify-content: space-between;
      > span {
        width: 26px;
        height: 26px;
        color: ${({ theme }) => theme.color.grey900};
        text-align: center;
        font-size: 12px;
        font-weight: 500;
        line-height: 16.8px;
        letter-spacing: 0.5px;
      }
    }

    .MuiDayCalendar-weekContainer {
      width: 277px;
      margin: 0;
      align-items: center;
      justify-content: space-between;
      .MuiPickersDay-dayWithMargin.MuiPickersDay-dayOutsideMonth {
        color: ${({ theme }) => theme.color.grey300};
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        line-height: 16.8px;
        letter-spacing: 0.5px;
      }
      .MuiPickersDay-today {
        background-color: ${({ theme }) => theme.color.blue300};
        color: ${({ theme }) => theme.color.grey900};
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        line-height: 16.8px;
        letter-spacing: 0.5px;
      }

      > button {
        width: 40px;
        height: 40px;
        padding: 0;
        margin: 0;
      }
    }
  }
`;
