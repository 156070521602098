import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PathNames } from 'applicaiton/routes/constants';
import { ReactComponent as Logo } from 'features/feature-aside-menu/assets/logo.svg';
import { ReactComponent as LogoText } from 'features/feature-aside-menu/assets/logo-text.svg';
import { ReactComponent as LanguageIcon } from 'applicaiton/assets/language.svg';
import { ReactComponent as LogOutIcon } from 'applicaiton/assets/logout.svg';
import { ReactComponent as SupportIcon } from 'applicaiton/assets/help.svg';
import { ReactComponent as ArrowLeftIcon } from 'applicaiton/assets/arrow-narrow-left.svg';
import { ReactComponent as ArrowRightIcon } from 'applicaiton/assets/arrow-narrow-right.svg';
import { useMenuList } from './hooks/useMenuList';
import {
  AccordionSettings,
  AccordionProfiles,
  MenuItem,
  AccordionUsers,
} from './components';
import { Wrapper, ButtonStyled, Box } from './styles';
import { Divider } from '@mui/material';
import i18next from 'i18next';
import { useSettingsList } from './hooks/useSettingsList';
import {
  Profile,
  getProfilesFromSessionStorage,
  getUserId,
  getUserRole,
} from 'applicaiton/sessionStorage/auth';
import { UserRoles } from 'applicaiton/constants/userRoles';
import { useUsersList } from './hooks/useUsersList';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { getClinicOwnerById } from 'applicaiton/store/reducers/ClinicOwner/ActionCreators';
import { isShowClinicsForProfessional } from 'common/helpers/permissions';
import {
  setUserLanguage,
  signOut,
  usersRefreshToken,
} from 'applicaiton/store/reducers/Users/ActionCreators';
import {
  getMenuState,
  setMenuState,
} from 'applicaiton/sessionStorage/asideMenu';
import { logout } from 'common/auth/authService';
import { UserPermissions } from 'applicaiton/constants/userPermissions';
import { userHasPermission } from 'common/helpers/userHasPermission';
import { useSubscriptionsMenu } from './hooks/useSubscriptionsMenu';
import { useClinicOwner } from 'common/hooks/useClinicOwner';
import AccordionSubscriptions from './components/AccordionSubscriptions';
import { usePushNotifications } from 'features/feature-chats/hooks/usePushNotifications';

const SideMenu: FC = () => {
  const { t, i18n } = useTranslation();
  const usersOptions = useUsersList();
  const subscriptionsOptions = useSubscriptionsMenu();
  const settingsOptions = useSettingsList();
  const [showUsersList, setShowUsersList] = useState(false);
  const userRole = getUserRole();
  const userId = getUserId();
  const isUserRoleClinicOwner = userRole?.includes(UserRoles.clinicOwner);
  const isUserRoleProfessional = userRole?.includes(UserRoles.professional);
  const showClinicsForProfessional = isShowClinicsForProfessional();
  const isMenuOpened = getMenuState();
  const [isExpanded, setIsExpanded] = useState(isMenuOpened);
  const { isPrivateDoctor } = useClinicOwner();
  const canUserManageProfessionals = userHasPermission(
    UserPermissions.clinicProfessionalsManagement,
  );
  const { unregisterPushNotifications } = usePushNotifications();
  const languages = ['PT', 'EN', 'FR'];

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { currentClinicOwner } = useAppSelector(
    (state) => state.clinicOwnerSlice,
  );

  useEffect(() => {
    if (isUserRoleClinicOwner) {
      dispatch(getClinicOwnerById(String(userId)));
    }
  }, []);

  const isClinicOwnerProfessional: boolean = useMemo(() => {
    return !!currentClinicOwner?.professional?.id;
  }, [currentClinicOwner]);

  const menuItems = useMenuList(isClinicOwnerProfessional);

  const supportItem = {
    path: PathNames.support,
    name: t('clinicsConfiguration.aside_menu.support'),
    image: SupportIcon,
  };

  const currentLanguage = useMemo(() => {
    return (
      localStorage.getItem('i18nextLng')?.slice(0, 2).toUpperCase() || 'PT'
    );
  }, [i18n.language]);

  const languagesList = useMemo(() => {
    return languages.filter((language) => language !== currentLanguage);
  }, [languages, currentLanguage]);

  const changeLanguage = async (language: string) => {
    const userUpdate = async () => {
      const profile: Profile[] | null = getProfilesFromSessionStorage();
      i18next.changeLanguage(language.toLowerCase());

      if (profile) {
        await dispatch(
          usersRefreshToken({
            refreshToken: profile[0].refreshToken,
            email: profile[0].email,
          }),
        );
      }
    };

    dispatch(setUserLanguage(language.toLowerCase())).then((data) => {
      if (data.meta.requestStatus === 'fulfilled') {
        userUpdate();
      }
    });
  };

  const handleLogOut = async () => {
    const response = await dispatch(signOut());
    if (response.meta.requestStatus === 'fulfilled') {
      logout();
      await unregisterPushNotifications();
      i18next.changeLanguage(currentLanguage.toLowerCase() || 'pt');
      navigate(PathNames.login);
    }
  };

  const handelChangeMenuState = (open: boolean) => {
    setMenuState(open);
    setIsExpanded(open);
  };

  const displayUsersMenuItem = useMemo(() => {
    return canUserManageProfessionals && !isPrivateDoctor;
  }, [canUserManageProfessionals, isPrivateDoctor]);

  return (
    <Wrapper
      onMouseLeave={() => setShowUsersList(false)}
      className={isExpanded ? 'active' : ''}
    >
      <Box className={isExpanded ? 'expanded' : ''}>
        <Logo className={'logo'} />
        <LogoText className={'logo-text'} />
        {isExpanded && (
          <ButtonStyled
            type="button"
            onClick={() => handelChangeMenuState(false)}
          >
            <ArrowLeftIcon />
          </ButtonStyled>
        )}
      </Box>
      {!isExpanded && (
        <ButtonStyled type="button" onClick={() => handelChangeMenuState(true)}>
          <ArrowRightIcon />
        </ButtonStyled>
      )}
      <AccordionProfiles
        showList={showUsersList}
        setShowList={setShowUsersList}
      />

      <Divider />
      <div className={'item-list'}>
        <div>
          {menuItems.map((item) => (
            <MenuItem
              key={item.name}
              path={item.path}
              name={item.name}
              Image={item.image}
              isNotificationItem={item.path === PathNames.notifications}
            />
          ))}
          {isUserRoleProfessional && (
            <AccordionSubscriptions
              options={subscriptionsOptions}
              isExpanded={isExpanded}
              handleExpandMenu={() => {
                !isExpanded && handelChangeMenuState(true);
              }}
            />
          )}
          {displayUsersMenuItem && (
            <AccordionUsers
              options={usersOptions}
              isExpanded={isExpanded}
              handleExpandMenu={() => {
                !isExpanded && handelChangeMenuState(true);
              }}
            />
          )}
          {(showClinicsForProfessional || isUserRoleClinicOwner) && (
            <AccordionSettings
              options={settingsOptions}
              isExpanded={isExpanded}
              handleExpandMenu={() => {
                !isExpanded && handelChangeMenuState(true);
              }}
            />
          )}
        </div>
        <div>
          <MenuItem
            path={supportItem.path}
            name={supportItem.name}
            Image={supportItem.image}
          />
          <div className={'language'}>
            <LanguageIcon />
            <p
              onClick={() => {
                !isExpanded && handelChangeMenuState(true);
              }}
            >
              {currentLanguage}
            </p>
            <Divider orientation="vertical" />
            {languagesList.map((language) => (
              <span key={language} onClick={() => changeLanguage(language)}>
                {language.toUpperCase()}
              </span>
            ))}
          </div>
        </div>
      </div>
      <Divider />
      <div className={'logout'} onClick={handleLogOut}>
        <span>{t('clinicsConfiguration.aside_menu.log_out')}</span>
        <LogOutIcon />
      </div>
    </Wrapper>
  );
};

export default SideMenu;
